import Papa from "papaparse";
import { useState } from 'react';
import { Button, Upload, notification, Progress , Result, Steps  } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import registerOperations from "../../../actions/operations/register-operations";
import verificarMesEAnoIguais from "../../../lib/verify-data-record";
import formatData from "../../../lib/format-string";
import verifyResult from "../../../actions/result/verify-result";
import MyModal from "../../../components/myModal/MyModal";
import InputReais from "../../../components/inputReais/InputReais";
import getAll from "../../../actions/corretores/getAll-corretores";
import corretoraResult from "../../../use-cases/corretora/corretora_result";
import calcularReceitaCorretores from "../../../use-cases/assessor/assessor_result";
import saveResultCorretora from "../../../actions/result/saveResult_corretora";
import './pageRecord.css';
import saveResultAssessores from "../../../actions/result/saveResult_assessores";
import { useNavigate } from "react-router-dom";
import getSumExpenses from "../../../actions/expenses/getSum-expenses";


export default function PageRecord () {
  const [dataCsv, setDataCsv] = useState([]);
  const [data, setData] = useState([]);
  const [date, setDate] = useState([]);
  const [net, setNet] = useState(false);
  const [netCorretora, setNetCorretora] = useState('');
  const [netAssessores, setNetAssessores] = useState([]);
  const [modal, setModal] = useState(false);
  const [resulteExists, setResulteExists] = useState(false);
  const [record, setRecord] = useState(false);
  const [currentStep,setCurrentStep] = useState(0);
  const [currentPercent,setPercent] = useState(0);
  const [currentTextProgress,SetCurrentTextProgress] = useState('');
  const [api, contextHolder] = notification.useNotification();

  const navigate = useNavigate();


  const openNotificationWithIcon = (type, msg, text) => {
      api[type]({
        message: msg,
        description: text
           
      });
  };

  async function converteCsv() {
      // Verifica se recebeu os arquivos
      if (dataCsv.length === 0) {
        openNotificationWithIcon('error', 'Atenção', 'Você precisa enviar todos os arquivos!');
        return;
      };
      
      //Feedback Usuário
      setRecord(true);
      setCurrentStep(0);
      setPercent(10);
      SetCurrentTextProgress('Convertenado dados para JSON...');

      // converte cada arquivo CSV em um objeto
      const promises = dataCsv.map((current) => {
        return new Promise((resolve, reject) => {
          Papa.parse(current.file, {
            download: true,
            header: true,
            skipEmptyLines: true,
            encoding: 'ISO-8859-1',
            complete: (results) => {
              if (results.errors.length === 0) {
                resolve(results.data);
                //Feedback Usuário
                setPercent(25)
                SetCurrentTextProgress('Padronizando dados...')
                
              } else {
                reject(new Error('Erro ao converter os dados, tente mais tarde!'));
                openNotificationWithIcon('error', 'Atenção', 'Erro ao converter os dados, tente mais tarde!');
                setRecord(false);
                setCurrentStep(0);
                return;
              }
            },
          });
        });
      });
      setPercent(30)
      Promise.all(promises)
        .then(async (objects) => {

          // percorre os objetos e remove caracteres especiais e acentos das propriedades da propriedade "data"
          const newData = await formatData(objects);        

          // Feedback usuário
          setPercent(40);
          SetCurrentTextProgress('Verificando os dados...');
          
          //Verifica se a data informada é igual em todas as planilhas
          const verifyMesAno = await verificarMesEAnoIguais(newData);
          //console.log(verifyMesAno);

         if(!verifyMesAno.success){
            openNotificationWithIcon('error', 'Atenção!', "Erro: as datas das operações enviadas nas planilhas devem pertencer ao mesmo período.");
            setRecord(false);
            setCurrentStep(0);
            return;
         };

          // Feedback usuário
          SetCurrentTextProgress('Consultando se já existe resultado para essa data...');
          setPercent(45);
          setCurrentStep(1);
          
          //Verifica se já existe um relatório salvo para data informada nas planilhas
          const verifyData = await verifyResult(verifyMesAno.data)
          
          // salva a data
          setDate(verifyMesAno.data)
          
          setData(newData);
          if(verifyData?.error){
            setData(newData);
            openNotificationWithIcon('warning', 'Atenção!', 'Já existe um relatório salvo com a data informada');
            setModal(true); //abre o modal
            setResulteExists(true)    
            return;

          };
    
        // Feedback usuário
        SetCurrentTextProgress('Verificando se todos os Assessores estão cadastrados...');
        setPercent(50);
        setResulteExists(false);

      const getAssesores = await getAll();
      if(getAssesores.error){
        openNotificationWithIcon('error', 'Atenção', 'Erro ao carregar a tabela assessores!');
        setRecord(false);
        setCurrentStep(0);
        return;
    
      };

     // criar uma função ( Verifica se todos os corretores estão cadastrados.)
      const cdgAssesores = [];
      
      newData.map((current =>{
        
        if(!cdgAssesores.includes(current.codigoassessor)){
          cdgAssesores.push(current.codigoassessor)
        };

        return cdgAssesores
      }))

      const assessoresData = [];
      cdgAssesores.forEach((codigo_assessor) => {
        const assessor = Object.values(getAssesores).find((assessor) => assessor.codigo_assessor === codigo_assessor);
        if (assessor) {
          //console.log(assessor)
          assessoresData.push({
            id:assessor.id,
            codigo_assessor:assessor.codigo_assessor,
            nome:assessor.nome,
            tipo:assessor.tipo
          });
        } else if(!assessor){
          assessoresData.push(false);

          
        }
      });

      if(assessoresData.length === 0){
        openNotificationWithIcon(
          'error', 'Atenção', "Os assessores desse relatório estão desativados ou não foram cadastrados. Verifique as informações e tente novamente."
        );
        setRecord(false);
        setCurrentStep(0);
        setModal(false)
        return;

      }else if (assessoresData.includes(false)){
        openNotificationWithIcon('error', 'Atenção', 'Você precisa cadastrar todos os assessores!');
        setModal(false);
        setNet(false);
        setRecord(false);
        setCurrentStep(0);

        return;

      };

      // Feedback usuário
      SetCurrentTextProgress('Salvando operações...');
      setPercent(60);
    
      //Salva as oprações no banco de dados
      const registerOp = await registerOperations(newData);
    
      if(registerOp.error){
        openNotificationWithIcon('error', 'Atenção', 'Erro ao salvar as operações!');
        setRecord(false);
        setCurrentStep(0);
        return;
    
      };
      
      // Feedback usuário
      SetCurrentTextProgress('Aguardando valores...');
      setPercent(65);

      // retorna assessoresData, que é um array de objetos contendo informações dos assessores
      setNetAssessores(assessoresData);

      //Abre o modal
      setCurrentStep(2);
      setNet(true)
      setModal(true)
      
      
      // termina a logica aqui e segue no getnet  
    
          
    })
    .catch((error) => {
      openNotificationWithIcon('error', 'Atenção', error.message);
    });

   
    
      
  }

  function notSaveNewInfo () {
    setRecord(false);
    setCurrentStep(0);
    setModal(false);
      
  };

  async function saveNewInfo () {
    try {
      
      // Feedback usuário
      SetCurrentTextProgress('Verificando se todos os Assessores estão cadastrados...');
      setPercent(50);
      setResulteExists(false);

      const getAssesores = await getAll();
      if(getAssesores.error){
        openNotificationWithIcon('error', 'Atenção', 'Erro ao carregar a tabela assessores!');
        setRecord(false);
        setCurrentStep(0);
        return;
    
      };

     // criar uma função ( Verifica se todos os corretores estão cadastrados.)
      const cdgAssesores = [];
 
      data.map((current =>{
        
        if(!cdgAssesores.includes(current.codigoassessor)){
          cdgAssesores.push(current.codigoassessor)
        };

        return cdgAssesores
      }))

      const assessoresData = [];
      cdgAssesores.forEach((codigo_assessor) => {
        const assessor = Object.values(getAssesores).find((assessor) => assessor.codigo_assessor === codigo_assessor);
        if (assessor ) {
          //console.log(assessor)
          assessoresData.push({
            id:assessor.id,
            codigo_assessor:assessor.codigo_assessor,
            nome:assessor.nome,
            tipo:assessor.tipo
          });
        } else if(!assessor){
          assessoresData.push(false);

          
        }
      });

      if(assessoresData.length === 0){
        openNotificationWithIcon(
          'error', 'Atenção', '"Os assessores desse relatório estão desativados ou não foram cadastrados. Verifique as informações e tente novamente."'
        );
        setRecord(false);
        setCurrentStep(0);
        setModal(false)
        return;

      }else if (assessoresData.includes(false)){
        openNotificationWithIcon('error', 'Atenção', 'Você precisa cadastrar todos os corretores!');
        setModal(false);
        setNet(false);
        setRecord(false);
        setCurrentStep(0);

        return;

      };

      // Feedback usuário
      SetCurrentTextProgress('Salvando operações...');
      setPercent(60);
    
      //Salva as oprações no banco de dados
      const registerOp = await registerOperations(data);
    
      if(registerOp.error){
        openNotificationWithIcon('error', 'Atenção', 'Erro ao salvar as operações!');
        setRecord(false);
        setCurrentStep(0);
        return;
    
      };

      // Feedback usuário
      SetCurrentTextProgress('Aguardando valores...');
      setPercent(65);

      // retorna assessoresData, que é um array de objetos contendo informações dos assessores
      setNetAssessores(assessoresData);

      //Abre o modal
      setNet(true);
      setCurrentStep(2);
      // termina a logica aqui e segue no getnet
      
    } catch (error) {
      openNotificationWithIcon('error', 'Atenção', `${error}`);
  
    };

      
        
  };

  function handleChangeCorretoraNet (event) {
    setNetCorretora(event.target.value);
  };

  const handleChange = (index, newValue) => {
    setNetAssessores(prevState => {
      const updatedAssessor = { ...prevState[index], net: newValue };
      const updatedAssessors = [...prevState];
      updatedAssessors[index] = updatedAssessor;
      return updatedAssessors;
    });
  };
   
  async function getNet() {
    try {
      if(!netCorretora){
        openNotificationWithIcon('error', 'Atenção', 'Você precisa preencher o NET da Corretora');
        return;
      };
  
      const allAssessorsHaveNet = netAssessores.every((assessor) => {
        return assessor.hasOwnProperty('net');
      });
    
      if (!allAssessorsHaveNet) {
        openNotificationWithIcon('error', 'Atenção', 'Você precisa preencher o NET de todos os Assessores');
        return;    
      };
  
      // Feedback usuário
      SetCurrentTextProgress('Calculando resultado...');
      setPercent(75);
      setModal(false);
      setNet(false);
      setCurrentStep(3);
      
      // Calcula o resultado dos Assessores
      const assessoresResult = await calcularReceitaCorretores(data, netAssessores);
      
      // Busca a soma de todas as despesas para data
      const despesas = await getSumExpenses(date);
      
      //Calcula o resultado da corretora
      const corretoraResultado = await corretoraResult(data, netCorretora, assessoresResult.result.comissao_total,assessoresResult.result.ajuste_assessores, despesas);

      // Feedback usuário
      SetCurrentTextProgress('Salvando resultado...');
      setPercent(75);

      //Salvar resultados
      const saveCorretoraResult = await saveResultCorretora(corretoraResultado);
      const saveAssessoresResult = await saveResultAssessores({
        data:assessoresResult.data,
        resultado_assessores:assessoresResult.result.resultado_assesores
      });

      // Feedback usuário
      SetCurrentTextProgress('Aguarde você será redirecionado para o DashBoard...');
      setPercent(100);
      setCurrentStep(4);

      setTimeout(function() {
        navigate('/dashboard', {replace:true})
      }, 3000);
      
      
      
    } catch (error) {
      openNotificationWithIcon('error', 'Atenção', `${error}`);
      return;
    };

  };

  function getBackNet () {
    setRecord(false);
    setModal(false);
    setNet(false);
  };
    
    
  return(
    <div className='PageRecord__container'>
      {contextHolder}
      <div className={record ? '--Disable':"PageRecord__containerContent"}>
        <div className="PageRecord__content">
          <Upload.Dragger
            action={"http://localhost:3000"}
            listType="picture"
            maxCount={8}
            multiple
            accept='.csv'
            beforeUpload={(file)=>{                       
              dataCsv.push({file})
                            
              return false
            }}
            onRemove={(event)=>{
              dataCsv.map(current => {
                if(current.file.uid === event.uid){
                  dataCsv.splice(dataCsv.indexOf(current), 1);
                  return false;
                };
                return false
              });
                
                return true
            }}                                        
          >
            <Button icon={<UploadOutlined />}>Subir Planilha</Button>
          </Upload.Dragger>

        </div>
        <Button 
              type="primary"
              className="PageRecord__btn"
              onClick={converteCsv}
            >
              Gerar Relatório
        </Button>
            
      </div>
      <div className={record ? 'PageRecord__steps' : "--Disable"}>
        <Steps
         current={currentStep}
          items={[
            {
              title: 'Preparando os dados',
              description:'Estamos verificando e convertendo todos dados.',
            },
            {
              title: 'Consulta de resultados',
              description:'Verificando se já existe relatório salvo para a data das operações.',
              subTitle: '',
            },
            {
              title: 'Inserir NET ',
              description:'Inserir os valores para o calculo do ROA',
            },
            {
              title: 'Calculando Resultados',
              description:'Estamos Calculando e salvando os resultados.'

            },
            {
              title: 'Concluído!',
              description:'Parabéns seu relatório foi gerado com sucesso.'

            }
          ]}
        />
        <div className="PageRecord__progress">
          <Progress type="circle" percent={currentPercent} />
          <p>{currentTextProgress}</p>     
          < MyModal 
            modalState={modal}
            setModalState={setModal}
            title={''}
            content={
              <div >
                <div className={resulteExists ? '' :'--Disable'}>
                          <Result
                            status="warning"
                            title="Encontramos um registro salvo na data informada."
                            subTitle='Você deseja atualizá-lo com as novas informações? Observe que todos os registros salvos serão perdidos.'
                           
                          />
                          <div className="PageRecord__conteinerBnt">
                            <Button                              
                              key="não"
                              onClick={notSaveNewInfo}
                              ghost
                              type="primary"
                            >
                              Não
                            </Button>
                            <Button 
                              type="primary" 
                              key="sim"                      
                              onClick={saveNewInfo}
                              
                            >
                              SIM
                            </Button>
                            
                          </div>

                </div>         
                <div className={net ? 'PageRecord__net' :'--Disable'}>
                  <div className="PageRecord__netcontent">                        
                    <h2>NET da corretora</h2>
                    <label htmlFor="">
                      Net
                      <InputReais
                        name={''}
                        id={''}
                        value={netCorretora}
                        placeholder={'Digite o valor da Corretora'}
                        onChange={event=>handleChangeCorretoraNet(event)}
                                  
                      />
                    </label>    
                  </div>
                  <div className="PageRecord__netcontent">
                    <h2>NET dos assessores</h2>
                    {
                      netAssessores.map((current, index) => {
                        return (
                          <div key={index} className="PageRecord__netDiv">
                            <p className="PageRecord__netNome">Nome:<span className="PageRecord__netSpan"> {` ${current.nome}`}</span></p>
                            <p>Código: <span className="PageRecord__netSpan"> {` ${current.codigo_assessor}`}</span></p>
                            <label htmlFor="">
                              <p>Net</p>
                              <InputReais
                                name={`net-${index}`}
                                id={`net-${index}`}
                                value={current.net || ''}
                                placeholder={`Digite o valor do Assessor ${current.nome}`}
                                onChange={(e) => handleChange(index, e.target.value)}
                                className='PageRecord__inputReais'
                                
                              />
                            </label>
                          </div>
                        );
                      })
                    }

                  </div>
                  <div className="PageRecord__bntNetContent">
                    <Button 
                      type="primary" 
                      key="nao2"
                      ghost
                      onClick={getBackNet}
                    >
                      Não
                    </Button>
                    <Button 
                      type="primary" 
                      key="sim" 
                      htmlType="submit"
                      onClick={getNet}
                                              
                    >
                      SIM
                    </Button>

                  </div>

                </div>

              </div>

            }
          />

        </div>
            
      </div>
          
    </div>

  );

}