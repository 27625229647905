import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import login from '../../actions/auth/login';
import { Form, Input, notification  } from 'antd';
import  Loading  from '../../components/loading/Loading';
import CustomButton from '../../components/customButton/CustomButton';
import  logo  from "../../assets/logo.png";
import './login.css';
import getCodePass from '../../actions/sendEmail/getCodeNewPass';
import resetPassWord from '../../actions/sendEmail/resetPassWord';



export default function Login () {
    const [forgotPassword, setForgotPassword] = useState (false);
    const [password, setPassword] = useState ('');
    const [email, setEmail] = useState ('');
    const [loading, setLoading] = useState (false);
    const [loadingPass, setLoadingPass] = useState (false);
    const [inserirCodigo, setInserirCodigo] = useState (false);
    const [api, contextHolder] = notification.useNotification();
    const [form, setForm] = useState ({
        email:'',
        senha1:'',
        senha2:'',
        codnewpass:''
    });
    
    
    const navigate = useNavigate();

    const openNotificationWithIcon = (type, msg, text) => {
        api[type]({
          message: msg,
          description: text
           
        });
    };

    async function getLogin(values) {
        if(values?.remember){
            localStorage.setItem('user', JSON.stringify({
                email:values.email,
                senha:values.senha

            }));
        };
        

        if(password === '' || !password || !email || email === ''){
            openNotificationWithIcon('error', 'Atenção', 'Você precisa preecher todos os campos!');
            return;
        };

        setLoading(true);
        const data = await login ({
            email:email,
            senha:password
        });

        if(data.error) {
            setLoading(false);
            openNotificationWithIcon('error', 'Atenção', data?.mensage);
            return;

        }else if(data.token) {
            localStorage.setItem('token', JSON.stringify(data.token));
            setLoading(false);
            navigate('/dashboard',{replace:true});

        };
       
    };

    const onFinish = (values) => {
        console.log('Success:', values);
    };
    const onFinishFailed = () => {
        openNotificationWithIcon('error', 'Atenção!', 'Você precisa preencher todos os campos!');
        return;
    };

    async function getCodeToNewPass () {

        if(!form.email){
            openNotificationWithIcon('error', 'Atenção!', 'Você precisa enviar seu e-mail!');
            return;
        };
        setLoadingPass(true)
        const res = await getCodePass(form.email);
        if(res?.error){
            openNotificationWithIcon('error', 'Atenção!', res.message);
            setLoadingPass(false)
            return;
        };

        if(res?.success){
            openNotificationWithIcon('success', 'Atenção!', 'Você vai receber um e-mail com código para redefinir sua senha!');
            setLoadingPass(false)
            setInserirCodigo(true);
            return;
        };
    }

    async function resetPass () {
        setLoadingPass(true)
      
        if(!form.codnewpass || !form.senha1 || !form.senha2){
            openNotificationWithIcon('error', 'Atenção!', 'Você precisa preencher todos os campos!');
            setLoadingPass(false);
            return;
        };

        if(form.senha1 !== form.senha2){
            openNotificationWithIcon('error', 'Atenção!', '"Por favor, certifique-se de que as senhas digitadas nos campos Senha e Repita sua senha são idênticas!');
            setLoadingPass(false);
            return;
        };

        const res = await resetPassWord({
            senha:form.senha1,
            codepass:form.codnewpass
        })

        if(res?.error){
            openNotificationWithIcon('error', 'Atenção!', res.message);
            setLoadingPass(false);
            return;
        };

        if(res?.success){
            openNotificationWithIcon('success', 'Sucesso!', 'Senha alterada com sucesso!');
            setLoadingPass(false);
            setTimeout(() => {
                setForgotPassword(false);
                setInserirCodigo(false);
    
            }, 2000);
          
        };

       

    }


    return (
        <div className='Login__container'>
           
            <div className={forgotPassword ? '--Disable': 'Login__getloginFirstContent'}>
            {contextHolder}
                <div className='Login__getGreatins'>
                    <img 
                        src={logo} 
                        alt="Logo_Atlanta_Invest.png" 
                        className='Login__logoAtlanta'
                    />
                    <h1 className='Logo__texth1'>
                        Bem vindo <br/>
                        de volta!
                        
                    </h1>

                </div>
                <div className='Login__getCredentials'>
                    <h2>
                        Acessar o sistema
                    </h2>
                    <p>
                        Digite seu e-mail e senha
                    </p>
                    <Form
                        name="login"
                        labelCol={{
                        span: 8,
                        }}
                        wrapperCol={{
                        span: 16,
                        }}
                        style={{
                        maxWidth: 600,
                        }}
                        initialValues={{
                        remember: false,
                        }}
                        onFinish={getLogin}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        className='Login__formLogin'
                    >
                        <Form.Item
                            key={'email'}
                            name="email"
                            rules={[
                                {
                                required: true,
                                message: 'Digite seu e-mail!',
                                },
                            ]}
                            
                            
                        >
                            <Input 
                                placeholder="📧 E-mail"
                                className='Login__inputEmail'
                                onChange={event=>setEmail(event.target.value)}

                            />
                        </Form.Item>
                        <Form.Item
                        key={'senha'}
                        name="senha"
                        rules={[
                            {
                            required: true,
                            message: 'Digite sua senha!',
                            },
                        ]}
                        >
                            <Input.Password 
                                placeholder="🔑 Senha"
                                className='Login__inputPassword'
                                onChange={event=>setPassword(event.target.value)}
                                autoComplete="off"
                            
                            
                            />
               
                        
                        </Form.Item>
                        <p 
                            className='Login_linkToForgotPassword'
                            onClick={()=>setForgotPassword(true)}
                            style={{textAlign:'center',marginBottom:'10px'}}
                        >
                            Esqueci minha senha
                        </p>
                        {
                            loading    
                            ? 
                            <div className='Login_loginLoading'>
                                <Loading 
                                    text={'Aguarde...'}
                                /> 

                            </div>
                           
                            :  
                            <CustomButton
                                text={'Entrar'}
                            />
                        }
                    </Form>
                </div>
            </div>
            <div className={forgotPassword ? 'Login__forgotPassWord' : '--Disable'}>
                <div className='Login__getPassword'>
                    <img 
                        src={logo} 
                        alt="Logo_Atlanta_Invest.png" 
                        className='Login__logoAtlanta'
                    />
                    <h1 className='Logo__texth1'>
                        Recuperar senha
                        
                    </h1>
                </div>
                <div className='Login__getCredentials'>
                        <h2>
                            Recuperar minha senha
                        </h2>
                       { 
                            !inserirCodigo
                            &&
                            <p>
                                Digite seu e-mail
                            </p>
                        }
                       { 
                            inserirCodigo 
                            ?
                            <Form  style={{width:'80%'}} className='Login_formNewPass'
                            name="basic"
                            labelCol={{
                            span: 8,
                            }}
                            wrapperCol={{
                            span: 16,
                            }}                        
                            initialValues={{
                            remember: false,
                            }}
                          
   
                            >
                                <label htmlFor="">
                                    <p className='Login__inputLabel'>Digite o código</p>
                                    <input
                                        type="text" 
                                        className='Login__inputEmail Login__inputCdg'
                                        placeholder={'Digite o código '}
                                        onChange={event=>setForm({...form, codnewpass:event.target.value})}
                                        value={form.codnewpass}
                                    />

                                </label>
                                <label htmlFor="">
                                    <p className='Login__inputLabel'>Digite sua nova senha</p>
                                    <Input.Password 
                                        placeholder="🔑 Senha"
                                        className='Login__inputPassword'
                                        onChange={event=>setForm({...form, senha1:event.target.value})}
                                        autoComplete="off"
                                        value={form.senha1}
                                        
                                    
                        
                                    />
               

                                </label>
                                <label htmlFor="">
                                    <p className='Login__inputLabel'>Repita sua nova senha</p>
                                    <Input.Password 
                                        placeholder="🔑 Senha"
                                        className='Login__inputPassword'
                                        onChange={event=>setForm({...form, senha2:event.target.value})}
                                        autoComplete="off"
                                        value={form.senha2}
                                    
                                    
                                    />
               
                                </label>                            
                                <p 
                                    className='Login_linkToForgotPassword'
                                    onClick={()=>setInserirCodigo(false)}
                                    style={{textAlign:"center", marginBottom:'10px'}}
                                >
                                    Voltar
                                </p>                                                            
                                {
                                    loadingPass
                                    ?
                                    <div className='Login__loginLoading'>
                                        <Loading 
                                            text={'Aguarde...'}
                                        
                                    
                                        />
                                    </div>
                                    :
                                    <CustomButton
                                        text={'Redefinir'}
                                        click={resetPass}
                                            
                                    
                                    />
                                }
                               
                            </Form>
                            :
                            <Form
                                name="basic"
                                labelCol={{
                                span: 8,
                                }}
                                wrapperCol={{
                                span: 16,
                                }}
                                style={{
                                maxWidth: 600,
                                }}
                                initialValues={{
                                remember: true,
                                }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                            <Form.Item
                                label=""
                                name="nome"
                                rules={[
                                    {
                                    required: true,
                                    message: 'Digite seu e-mail!',
                                    },
                                ]}
                                
                            >
                            <Input 
                                placeholder="📧 E-mail"
                                className='Login__inputEmail'
                                onChange={event =>setForm({...form, email:event.target.value})}
                                value={form.email}
                            
                            />
                            </Form.Item> 
                            <p 
                                className='Login_linkToForgotPassword'
                                onClick={()=>setInserirCodigo(true)}
                                style={{textAlign:"center", marginBottom:'10px'}}
                                >
                                Inserir código
                            </p>                    
                            <p 
                                className='Login_linkToForgotPassword'
                                onClick={()=>setForgotPassword(false)}
                                style={{textAlign:"center", marginBottom:'10px'}}
                                >
                                Voltar
                            </p>
                            {
                                !loadingPass
                                ?
                                <CustomButton
                                    text={'Enviar'}
                                    click={getCodeToNewPass}
                                />
                                :
                               <div className='Login__passLoading'>
                                    <Loading 
                                        text={'Aguarde...'}
                                    
                                
                                    />
                               </div>
                            }
                            </Form>
                        }
                </div>
            </div>
        </div>
    );
}