export default async function calculosAssessores(corretoresArray, netAssessores) {
    const resultsArray = [];
    

    for (let i = 0; i < corretoresArray.length; i++) {
        const corretor = corretoresArray[i];
        const codigo_assessor = corretor.codigo_assessor;
       

        // Calcular receita bruta, receita líquida e comissão do escritório para o corretor atual.
        let receita_bruta = corretor.renda_fixa.receita_bruta + corretor.renda_variavel.receita_bruta;
        let receita_liquida = corretor.renda_fixa.receita_liquida + corretor.renda_variavel.receita_liquida;
        let comissao_escritorio = corretor.renda_fixa.comissao_escritorio + corretor.renda_variavel.comissao_escritorio;

        // Encontrar o net do assessor correspondente.
        let net = 0;
        if (corretor.codigo_assessor) {
            const assessor = netAssessores.find(a => a.codigo_assessor === corretor.codigo_assessor);
            if (assessor) {
                net = assessor.net;
                if (typeof net === 'string') {
                    net = net.replace(/[^\d,]/g, ''); // Remover tudo, exceto dígitos e vírgula.
                    net = net.replace(',', '.'); // Trocar vírgula por ponto para padronizar o separador decimal.
                    net = parseFloat(net); // Converter em número.
                }
            }
        }    

        // Calcula a comissao do Assessor
        const comissaoFixa = corretor.renda_fixa.comissao_escritorio * (corretor.tipo === 'associado' ? 0.5 : 0.65);
        const comissaoVariavel = corretor.renda_variavel.comissao_escritorio * (corretor.tipo === 'associado' ? 0.3 : 0.35);
        
        const ajuste = corretor.ajuste * (corretor.tipo === 'associado' ? 0.3 : 0.35);
        
        const impostoAssessor = corretor.impostos * (corretor.tipo === 'associado' ? 0.5 : 0.65)
        
        let comissao_assessor = comissaoFixa + comissaoVariavel;
        comissao_assessor = (comissao_assessor -  impostoAssessor) + ajuste;
    
        // Calcular o lucro gerado para corretora
        const lucro_apos_imposto = comissao_escritorio - corretor.impostos;
        const lucro_liquido_corretora = (lucro_apos_imposto - comissao_assessor) + corretor.ajuste;
       
        //roa do Assessor
        const roa_mensal = (receita_bruta*12) / net;
        

        resultsArray.push({
            codigo_assessor,
            receita_bruta,
            receita_liquida,
            comissao_escritorio,
            net,
            imposto:corretor.impostos,
            comissao_assessor,
            lucro_apos_imposto,
            lucro_liquido_corretora,
            roa_mensal,
            ajuste
        });
    }

    const comissao_total =  resultsArray.reduce((accumulator, currentValue) => {
        return accumulator + parseFloat(currentValue.comissao_assessor);
    }, 0);

    const ajuste_total =  resultsArray.reduce((accumulator, currentValue) => {
        return accumulator + parseFloat(currentValue.ajuste);
    }, 0);

    



    return {
        resultado_assesores:resultsArray,
        comissao_total:comissao_total,
        ajuste_assessores:ajuste_total
    }
        
}
