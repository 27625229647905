export default function verifyFormEditAdm (data) {
    data.nome =  data.nome.trim();
    data.corretor = data.email.trim();

    if(!data.nome || !data.email){
        return {
            error:true,
            msg:'Você precisa preencher todos os campos!'
        };
    };

}