import { useEffect, useState, useRef } from "react";
import { PlusCircleFilled, DeleteOutlined, FormOutlined, DollarCircleOutlined  } from '@ant-design/icons';

import {  
  Select, 
  Space, 
  Table, 
  Tag, 
  Tooltip, 
  Empty,
  Modal, 
  Button, 
  Result,
  notification,
} from 'antd';
import isValidDate from "../../../use-cases/expenses/isValidDate";
import createExpenses from "../../../actions/expenses/create-expenses"
import Inputmask from 'inputmask';
import verifyForm from "../../../use-cases/expenses/verifyForm";
import Loading from "../../../components/loading/Loading";
import './expensesConfig.css';
import getExpenses from "../../../actions/expenses/get-expenses";
import tableFormat from "../../../use-cases/expenses/tableFormat";
import deleteExpense from "../../../actions/expenses/delete-expense";
import updateExpenses from "../../../actions/expenses/update-expense";
import payExpense from "../../../actions/expenses/pay-expense";



export default function ExpensesConfig() {
  const [empty, setEmpty]= useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
  const [isModalOpenPayment, setIsModalOpenPayment] = useState(false);
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [result, setResult] = useState(false);
  const [expensesList, setExpensesList] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const inputRef = useRef(null);
  const [form, setForm]= useState({
    descricao:'',
    valor:'',
    tipo:'',
    data:''
  });
  const [formEdit, setFormEdit]= useState({
    descricao:'',
    valor:'',
    tipo:'',
    data:'',
    id:''
  });
  const [formPayment, setFormPayment]= useState({
    id:'',
    descricao:'',
    valor:'',
    valor_pago:'',
    dataPagamento:'',
    jurosMulta:0,
    
  });
  
  const openNotificationWithIcon = (type,msg,description) => {
    api[type]({
      message: msg,
      description:description
       
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  function modalFalse (){
    setForm({
      descricao:'',
      valor:'',
      tipo:'',
      data:''
    });
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOkEdit = () => {
    setIsModalOpenEdit(false);
  };

  function modalFalseEdit (){
    setFormEdit({
      descricao:'',
      valor:'',
      tipo:'',
      data:''
    });
    setIsModalOpenEdit(false);
  };

  const handleCancelEdit = () => {
    setIsModalOpenEdit(false);
  }

  function modalFalsePayment (){
    setIsModalOpenPayment(false);
    setFormPayment({
      id: '',
      descricao: '',
      valor: '',
      valor_pago: '',
      dataPagamento: '',
      jurosMulta: 0,
    });
    
  };

  const handleCancelPayment = () => {
    setIsModalOpenPayment(false);
  };

  const handleOkPayment = () => {
    setIsModalOpenPayment(false);
  };

  function handleChange(event) {
    // Remove todos os caracteres não numéricos da entrada do usuário
    let inputValue = event.target.value.replace(/\D/g, '');

    // Adiciona a barra separadora entre o mês e o ano
    if (inputValue.length > 2) {
      inputValue = inputValue.slice(0, 2) + '/' + inputValue.slice(2);
    }

    // Limita a entrada a 7 caracteres (mm/yyyy)
    if (inputValue.length > 7) {
      inputValue = inputValue.slice(0, 7);
    }

    setValue(inputValue);
  };

  function handleDate(event) {
    let inputValue = event.target.value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos da entrada do usuário
    let formattedValue = ''; // Valor formatado a ser exibido no input

    // Adiciona os separadores de data conforme o usuário digita
    if (inputValue.length > 0) {
      formattedValue = inputValue.substr(0, 2);
    }
    if (inputValue.length > 2) {
      formattedValue += '/' + inputValue.substr(2, 2);
    }
    if (inputValue.length > 4) {
      formattedValue += '/' + inputValue.substr(4, 4);
    }

    // Define o valor do input
    event.target.value = formattedValue;

    // Salva o valor do input no estado form
    setForm({ ...form, data: formattedValue });

  };

  function handleDateEdit(event) {
    let inputValue = event.target.value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos da entrada do usuário
    let formattedValue = ''; // Valor formatado a ser exibido no input

    // Adiciona os separadores de data conforme o usuário digita
    if (inputValue.length > 0) {
      formattedValue = inputValue.substr(0, 2);
    }
    if (inputValue.length > 2) {
      formattedValue += '/' + inputValue.substr(2, 2);
    }
    if (inputValue.length > 4) {
      formattedValue += '/' + inputValue.substr(4, 4);
    }

    // Define o valor do input
    event.target.value = formattedValue;

    // Salva o valor do input no estado form
    setFormEdit({ ...formEdit, data: formattedValue });

  };

  function handleDatePayment(event) {
    let inputValue = event.target.value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos da entrada do usuário
    let formattedValue = ''; // Valor formatado a ser exibido no input

    // Adiciona os separadores de data conforme o usuário digita
    if (inputValue.length > 0) {
      formattedValue = inputValue.substr(0, 2);
    }
    if (inputValue.length > 2) {
      formattedValue += '/' + inputValue.substr(2, 2);
    }
    if (inputValue.length > 4) {
      formattedValue += '/' + inputValue.substr(4, 4);
    }

    // Define o valor do input
    event.target.value = formattedValue;

    // Salva o valor do input no estado form
    setFormPayment({ ...formPayment, dataPagamento:formattedValue });

  };

  function handleValorChange(event) {
    setForm({ ...form, valor: event.target.value });
  }

  useEffect(() => {
    if (inputRef.current) {
      const mascara = new Inputmask('R$ 999.999,99', {
        numericInput: true,
        placeholder: '0',
        prefix: '',
        radixPoint: ',',
        groupSeparator: '.',
        digits: 2,
        autoGroup: false,
        rightAlign: false,
      });

      mascara.mask(inputRef.current);
    }
  }, [inputRef.current]);

  async function createExpense () {
    setLoading(true);
   
    const verify = verifyForm(form)
    if(verify?.error){
      openNotificationWithIcon('error','Atenção!',verify?.msg);
      setLoading(false);
      return
    };

    const res = await createExpenses(form);
    if(res?.error){
      openNotificationWithIcon('error','Atenção!',"Ocorreu um erro ao cadastrar a despesa. Por favor, tente novamente mais tarde.");
      setLoading(false);
      return;
    };

    setResult(true);
    setLoading(false);
    if(value !== ''){
      searchExpense();
    };
  };

  async function editExpense () {
    
    setLoading(true);
   
    const verify = verifyForm(formEdit)
    if(verify?.error){
      openNotificationWithIcon('error','Atenção!',verify?.msg);
      setLoading(false);
      return
    };

    const res = await updateExpenses(formEdit);
    if(res?.error){
      openNotificationWithIcon('error','Atenção!',"Ocorreu um erro ao editar a despesa. Por favor, tente novamente mais tarde.");
      setLoading(false);
      return;
    };

    setResult(true);
    setLoading(false);
    if(value !== ''){
      searchExpense();
    };
  };

  async function payMyExpense () {
    
    setLoading(true);
   
    const verify = verifyForm(formPayment)
   
    if(verify?.error){
      openNotificationWithIcon('error','Atenção!',verify?.msg);
      setLoading(false);
      return
    };

    const res = await payExpense(formPayment);
    
    if(res?.error){
      openNotificationWithIcon('error','Atenção!',"Ocorreu um erro ao realizar o pagamento. Por favor, tente novamente mais tarde.");
      setLoading(false);
      setFormPayment({
        id:'',
        descricao:'',
        valor:'',
        valor_pago:'',
        dataPagamento:'',
        jurosMulta:0,
      })
      setIsModalOpenPayment(false)
      return;
    };

    setResult(true);
    setLoading(false);
    if(value !== ''){
      searchExpense();
    };

    setFormPayment({
      id:'',
      descricao:'',
      valor:'',
      valor_pago:'',
      dataPagamento:'',
      jurosMulta:0,
    })
  };

  async function searchExpense () {
    setLoading2(true)
    const verifyDate = await isValidDate(value); 
    if(verifyDate === false){
      setError(true);
      setLoading2(false);
      return;
    };
    setError(false);
    
    let formataData = value.split('/');
    const res = await getExpenses(`${formataData[1]}-${formataData[0]}`);
    if(res.error){
      openNotificationWithIcon('error','Atenção!',res?.message);
      setLoading2(false);
      setEmpty(true);
      return;
    };
    const formata = tableFormat(res);
    setExpensesList(formata);
    setLoading2(false);
    setEmpty(false);

  };

  async function expenseDelete (id) {
    const res = await deleteExpense(id)
    if(res?.error){
      openNotificationWithIcon('error','Atenção!',res?.message);
      return;
    };
    searchExpense();

  };

  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
      render: (text) => text,
    },
    {
      title: 'Descrição',
      dataIndex: 'descricao',
      key: 'descricao',
      render: (text) => text.toUpperCase(),
    },
    {
      title: 'Valor',
      dataIndex: 'valor',
      key: 'valor',
    },
    {
      title: 'Data',
      dataIndex: 'data',
      key: 'data',
    },
    {
      title: 'Tipo',
      key: 'tipo',
      dataIndex: 'tipo',
      render: (_, record) => {
        let color = 'orange'
        if (record.tipo === 'custo') {
          color = 'purple';
        }
        return (
          <Tag color={color}>
            {record.tipo.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (_, record) => {
        let color = 'green'
        if (record.status === 'a pagar') {
          color = 'red';
        }
        return (
          <Tag color={color}>
            {record.status.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: 'Data do pagamento',
      dataIndex: 'data_pagamento',
      key: 'data_pagamento',
    },
    {
      title: 'Valor pago',
      dataIndex: 'valor_pago',
      key: 'valor_pago',
    },
    {
      title: 'Ações',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <p 
            onClick={()=>{
              setIsModalOpenEdit(true);
              setFormEdit({
                descricao:record.descricao,
                valor:record.valor,
                tipo:record.tipo,
                data:record.data,
                id:record.id
              })
             
            }} 
            className='TableUsers__actions'
          >
            <Tooltip placement="bottom" title={"Editar Despesa"}>
              <FormOutlined
                className='TableAdm__iconsEdit' 
              
              />
            </Tooltip>
          </p>
                   
          <p 
            onClick={()=>{
              setIsModalOpenPayment(true);
              setFormPayment({ ... formPayment,
                descricao:record.descricao,
                valor:record.valor,
                id:record.id
              })
            }} 
            className='TableUsers__actions'
          >
            <Tooltip placement="bottom" title={"Realizar Pagamento"}>
              <DollarCircleOutlined
                className='TableAdm__iconsAtivar'
              
              />
            </Tooltip>
          </p>

          <p 
            onClick={()=>expenseDelete(record.id)} 
            className='TableUsers__actions'
          >
            <Tooltip placement="bottom" title={"Deletar Despesa"}>
              <DeleteOutlined 
                className='TableAdm__iconsDelete'
              
              />
            </Tooltip>
          </p>
        </Space>
      ),
    },
  ];

  return(
        <div className="ExpensesConfig__container">
          {contextHolder}
            <div className='ExpensesConfig__content'>
              <div className='ExpensesConfig__firstContent'>
                <div className="ExpensesConfig__busca">             
                  <p>Digite a data</p>
                  <input 
                    type="text" 
                    placeholder="Digite a data   --/----"
                    onChange={handleChange}
                    value={value}
                    className={error ? 'ExpensesConfig__buscaError' : ''}

                  />

                  {error && <p className="ExpensesConfig__textError">Por favor, digite uma data válida.</p>}

                  {
                    loading2
                    ?
                    <div style={{marginBottom:'20px'}}>
                        <Loading
                        text={'Aguarde...'}
                      />

                    </div>
                    
                    :
                    <Button 
                      type="primary" 
                      key="Buscar"
                      onClick={searchExpense}
                      ghost={false}
                    >
                      Clique para buscar
                    </Button> 
                  }
                          
                </div>     
                <Tooltip 
                  title='Cadastrar nova despesa'
                  placement="bottom"
                            
                >                      
                  <PlusCircleFilled
                    onClick={showModal}
                    className='ExpensesConfig__bntRegister'     
                      
                  />  
                </Tooltip >

              </div>  
              {
                empty 
                ? 
                <div className="ExpensesConfig__empty">
                  <Empty 
                    description='Não encontramos despesas cadastradas!'
                    
                  />

                </div>
                :
                <div className="Expenses__table">
                  <Table columns={columns} dataSource={expensesList} style={{cursor:'pointer !important'}}/>

                </div>
              }
            </div>
            <Modal  
              open={isModalOpen} 
              onOk={handleOk} 
              onCancel={handleCancel}
              maskClosable={false}
            >
              {
                result
                &&
                <Result
                  status="success"
                  title="Despesa cadastrada com sucesso! "
                  subTitle="Clique em voltar para retornar ao menu de despesas."
                  extra={[
                    <Button 
                      type="primary" 
                      key="voltar"
                      onClick={() => {
                        modalFalse();
                        setResult(false);
                      }}
                    >
                      Voltar
                    </Button>,
                  
                  ]}
                />
              }
              <form action="" className={result ? '--Disable' : "ExpensesConfig__form"}>
                <h2>Cadastrar Despesa</h2>
                <label>
                  <p>Descrição</p>
                  <input 
                    type="text" 
                    onChange={event => setForm({...form, descricao:event.target.value})}
                    value={form.descricao}
                    placeholder="Digite a descrição"
                  />

                </label>
                <label>
                  <p>Valor</p>
                  <input
                    type="text"
                    value={form.valor}              
                    ref={inputRef}
                    onChange={handleValorChange}
                    placeholder="R$ 000.000,00"
                    key={'Valor2'}
                  />
                  
                </label>
                <label>
                  <p>Data</p>
                  <input 
                    type="text" 
                    onChange={event=>handleDate(event)}
                    placeholder="Digite a data  dd/mm/yyyy"
                    value={form.data}
                    
                  />

                </label>
                <label>
                  <p>Tipo</p>
                  <Select  
                    className='' 
                    onChange={event=>setForm({...form, tipo: event})}
                    value={form.tipo}
                    
                  >
                    <Select.Option value="custo">Custo</Select.Option>
                    <Select.Option value="despesa">Despesa</Select.Option>
                          
                  </Select>                
                </label>

              </form>
              <div className={result ? '--Disable' : 'TableUsers__btnModal'} style={{margin:'50px 0px 30px 0px', padding:'20px 0px'}}>                      
                {
                  loading
                  ?
                  <Loading
                    text='Carregando'
                  />
                  :
                  <>
                    <Button 
                      type="primary" 
                      key="não"
                      onClick={modalFalse}
                      ghost
                    >
                      Não
                    </Button>
                    <Button 
                      type="primary" 
                      key="sim"                    
                      htmlType='submit'
                      onClick={createExpense}
                    >
                      Cadastrar
                    </Button>
                  </>
                }
              </div>
            </Modal>
            <Modal  
              open={isModalOpenEdit} 
              onOk={handleOkEdit} 
              onCancel={handleCancelEdit}
              maskClosable={false}
            >
              {
                result
                &&
                <Result
                  status="success"
                  title="Despesa editada com sucesso!"
                  subTitle="Clique em voltar para retornar ao menu de despesas."
                  extra={[
                    <Button 
                      type="primary" 
                      key="voltar"
                      onClick={() => {
                        modalFalseEdit();
                        setResult(false);
                      }}
                    >
                      Voltar
                    </Button>,
                  
                  ]}
                />
              }
              <form action="" className={result ? '--Disable' : "ExpensesConfig__form"}>
                <h2>Editar Despesa</h2>
                <label>
                  <p>Descrição</p>
                  <input 
                    type="text" 
                    onChange={event => setFormEdit({...formEdit, descricao:event.target.value})}
                    value={formEdit.descricao}
                    placeholder="Digite a descrição"
                  />

                </label>
                <label>
                  <p>Valor</p>
                  <input
                    type="text"
                    value={formEdit.valor}              
                    ref={inputRef}
                    onChange={event=>setFormEdit({...formEdit, valor:event.target.value})}
                    placeholder="R$ 000.000,00"
                    key={'Valor'}
                  />
                  
                </label>
                <label>
                  <p>Data</p>
                  <input 
                    type="text" 
                    onChange={event=>handleDateEdit(event)}
                    placeholder="Digite a data  dd/mm/yyyy"
                    value={formEdit.data}
                    
                  />

                </label>
                <label>
                  <p>Tipo</p>
                  <Select  
                    className='' 
                    onChange={event=>setFormEdit({...formEdit, tipo: event})}
                    value={formEdit.tipo}
                    
                  >
                    <Select.Option value="custo">Custo</Select.Option>
                    <Select.Option value="despesa">Despesa</Select.Option>
                          
                  </Select>                
                </label>

              </form>
              <div className={result ? '--Disable' : 'TableUsers__btnModal'} style={{margin:'50px 0px 30px 0px', padding:'20px 0px'}}>                      
                {
                  loading
                  ?
                  <Loading
                    text='Carregando'
                  />
                  :
                  <>
                    <Button 
                      type="primary" 
                      key="não"
                      onClick={modalFalseEdit}
                      ghost
                    >
                      Não
                    </Button>
                    <Button 
                      type="primary" 
                      key="sim"                    
                      htmlType='submit'
                      onClick={editExpense}
                    >
                      Editar
                    </Button>
                  </>
                }
              </div>
            </Modal>
            <Modal  
              open={isModalOpenPayment} 
              onOk={handleOkPayment} 
              onCancel={handleCancelPayment}
              maskClosable={false}
              closable={false}
              key={'modal pagamento'}
            >
              {
                result
                &&
                <Result
                  status="success"
                  title="Pagamento realizado com sucesso!"
                  subTitle="Clique em voltar para retornar ao menu de despesas."
                  extra={[
                    <Button 
                      type="primary" 
                      key="voltar"
                      onClick={() => {
                        modalFalsePayment();
                        setResult(false);
                      }}
                    >
                      Voltar
                    </Button>,
                  
                  ]}
                />
              }
              <form action="" className={result ? '--Disable' : "ExpensesConfig__form"}>
                <h2>Realizar pagamento</h2>
                <label>
                  <p>Descrição</p>
                  <input 
                    type="text" 
                    value={formPayment.descricao}
                    placeholder="Digite a descrição"
                    disabled={true}
                  />

                </label>
                <label>
                  <p>Valor</p>
                  <input
                    type="text"
                    value={formPayment.valor}              
                    ref={inputRef}
                    placeholder="R$ 000.000,00"
                    key={'Valor'}
                    disabled={true}
                  />
                  
                </label>
                <label>
                  <p>Juros/Multa</p>
                  <input
                    type="text"
                    value={formPayment?.jurosMulta ? formPayment.jurosMulta : ''}
                    onChange={event => setFormPayment({...formPayment, jurosMulta:event.target.value})}              
                    ref={inputRef}
                    placeholder="R$ 000.000,00"
                    key={'Valor2'}

                  />
                  
                </label>
                <label>
                  <p>Data do pagamento</p>
                  <input 
                    type="text" 
                    onChange={event=>handleDatePayment(event)}
                    placeholder="Digite a data  dd/mm/yyyy"
                    value={formPayment.dataPagamento}
                    
                  />

                </label>
                

              </form>
              <div className={result ? '--Disable' : 'TableUsers__btnModal'} style={{margin:'50px 0px 30px 0px', padding:'20px 0px'}}>                      
                {
                  loading
                  ?
                  <Loading
                    text='Carregando'
                  />
                  :
                  <>
                    <Button 
                      type="primary" 
                      key="não"
                      onClick={modalFalsePayment}
                      ghost
                    >
                      Não
                    </Button>
                    <Button 
                      type="primary" 
                      key="pagar"                    
                      htmlType='submit'
                      onClick={payMyExpense}
                    >
                      Pagar
                    </Button>
                  </>
                }
              </div>
            </Modal>

        </div>
  );
};

