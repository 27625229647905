export default function verifyForm (form) {
    let contadorError = 0;
    const valueForm = Object.values(form)
    for (let i = 0; i < valueForm.length; i++) {
        if(valueForm[i] === '') {
            contadorError++;
        };
                       
    };

    return contadorError;
};