import { useEffect, useState } from "react";
import { FileDoneOutlined  } from '@ant-design/icons'
import {  
    Select,  
    Button, 
    Result,
    notification,
    Tooltip,
    Empty
  } from 'antd';


import './assessorReportConfig.css'
import Loading from "../../../components/loading/Loading";
import isValidDate from "../../../use-cases/expenses/isValidDate";
import getAll from "../../../actions/corretores/getAll-corretores";
import getResultAssessor from "../../../actions/result/getResult-assessor";
import getOperations from "../../../actions/operations/get-operations";
import calcularAreaAssessor from "../../../use-cases/assessor/area_result";
import sendRelatorioAssessor from "../../../actions/sendEmail/relatorio-corretor";



  
export default function AssessorReportConfig () {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [empty, setEmpty]= useState(true);
  const [assessores, setAssessores]= useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [dataTab1, setDataTab1] = useState(false);
  const [dataTab2, setDataTab2] = useState(false);
  const [dataTabOperacoes, setDataTabOperacoes] = useState(false);
  const [value, setValue] = useState({
    data:'',
    assessor:''
  });

  const openNotificationWithIcon = (type,msg,description) => {
      api[type]({
        message: msg,
        description:description
           
      });
  };

  function handleChange(event) {
        // Remove todos os caracteres não numéricos da entrada do usuário
        let inputValue = event.target.value.replace(/\D/g, '');
    
        // Adiciona a barra separadora entre o mês e o ano
        if (inputValue.length > 2) {
          inputValue = inputValue.slice(0, 2) + '/' + inputValue.slice(2);
        }
    
        // Limita a entrada a 7 caracteres (mm/yyyy)
        if (inputValue.length > 7) {
          inputValue = inputValue.slice(0, 7);
        }
    
        setValue({...value, data:inputValue});
  };  

  useEffect(()=>{
    
        async function getAssessores () {
            const res = await getAll()
            if(res?.error){
                openNotificationWithIcon('error', 'Atenção!', res.mensage);
                return;
            };
            setAssessores(Object.values(res));   
          };
        getAssessores();

  },[])

  async function downloadExcel() {
    const table1 = document.getElementById('my-table');
    const table2 = document.getElementById('my-table2');
    const table3 = document.getElementById('my-table3');
    const headers1 = table1.querySelectorAll('th');
    const headers2 = table2.querySelectorAll('th');
    const headers3 = table3.querySelectorAll('th');
    const rows1 = table1.querySelectorAll('tbody tr');
    const rows2 = table2.querySelectorAll('tbody tr');
    const rows3 = table3.querySelectorAll('tbody tr');
  
    const headerStyles = 'background-color: rgb(28, 54, 88); font-weight: bold; color:white;';
  
    let data = `<table><caption>Resultado do Período</caption><thead><tr>`;
    for (let i = 0; i < headers1.length; i++) {
      data += `<th style="${headerStyles}">${headers1[i].innerText}</th>`;
    }
    data += '</tr></thead><tbody>';
  
    for (let i = 0; i < rows1.length; i++) {
      const cells = rows1[i].querySelectorAll('td');
      data += '<tr>';
      for (let j = 0; j < cells.length; j++) {
        data += `<td style="border: 1px solid #ccc;">${cells[j].innerText}</td>`;
      }
      data += '</tr>';
    }
    data += '</tbody></table>';
  
    data += `<br><table><caption>Resultado por Área</caption><thead><tr>`;
    for (let i = 0; i < headers2.length; i++) {
      data += `<th style="${headerStyles}">${headers2[i].innerText}</th>`;
    }
    data += '</tr></thead><tbody>';
  
    for (let i = 0; i < rows2.length; i++) {
      const cells = rows2[i].querySelectorAll('td');
      data += '<tr>';
      for (let j = 0; j < cells.length; j++) {
        data += `<td style="border: 1px solid #ccc;">${cells[j].innerText}</td>`;
      }
      data += '</tr>';
    }
    data += '</tbody></table>';
  
    data += `<br><table><caption>Relatório de Operações</caption><thead><tr>`;
    for (let i = 0; i < headers3.length; i++) {
      data += `<th style="${headerStyles}">${headers3[i].innerText}</th>`;
    }
    data += '</tr></thead><tbody>';
  
    for (let i = 0; i < rows3.length; i++) {
      const cells = rows3[i].querySelectorAll('td');
      data += '<tr>';
      for (let j = 0; j < cells.length; j++) {
        data += `<td style="border: 1px solid #ccc;">${cells[j].innerText}</td>`;
      }
      data += '</tr>';
    }
    data += '</tbody></table>';

    const link = document.createElement('a');
    link.download = 'relatório.xls';
    link.href = 'data:application/vnd.ms-excel;base64,' + btoa(data);
    link.click();

  };

  async function getExcel() {
    const table1 = document.getElementById('my-table');
    const table2 = document.getElementById('my-table2');
    const table3 = document.getElementById('my-table3');
    const headers1 = table1.querySelectorAll('th');
    const headers2 = table2.querySelectorAll('th');
    const headers3 = table3.querySelectorAll('th');
    const rows1 = table1.querySelectorAll('tbody tr');
    const rows2 = table2.querySelectorAll('tbody tr');
    const rows3 = table3.querySelectorAll('tbody tr');
  
    const headerStyles = 'background-color: rgb(28, 54, 88); font-weight: bold; color:white;';
  
    let data = `<table><caption>Resultado do Período</caption><thead><tr>`;
    for (let i = 0; i < headers1.length; i++) {
      data += `<th style="${headerStyles}">${headers1[i].innerText}</th>`;
    }
    data += '</tr></thead><tbody>';
  
    for (let i = 0; i < rows1.length; i++) {
      const cells = rows1[i].querySelectorAll('td');
      data += '<tr>';
      for (let j = 0; j < cells.length; j++) {
        data += `<td style="border: 1px solid #ccc;">${cells[j].innerText}</td>`;
      }
      data += '</tr>';
    }
    data += '</tbody></table>';
  
    data += `<br><table><caption>Resultado por Área</caption><thead><tr>`;
    for (let i = 0; i < headers2.length; i++) {
      data += `<th style="${headerStyles}">${headers2[i].innerText}</th>`;
    }
    data += '</tr></thead><tbody>';
  
    for (let i = 0; i < rows2.length; i++) {
      const cells = rows2[i].querySelectorAll('td');
      data += '<tr>';
      for (let j = 0; j < cells.length; j++) {
        data += `<td style="border: 1px solid #ccc;">${cells[j].innerText}</td>`;
      }
      data += '</tr>';
    }
    data += '</tbody></table>';
  
    data += `<br><table><caption>Relatório de Operações</caption><thead><tr>`;
    for (let i = 0; i < headers3.length; i++) {
      data += `<th style="${headerStyles}">${headers3[i].innerText}</th>`;
    }
    data += '</tr></thead><tbody>';
  
    for (let i = 0; i < rows3.length; i++) {
      const cells = rows3[i].querySelectorAll('td');
      data += '<tr>';
      for (let j = 0; j < cells.length; j++) {
        data += `<td style="border: 1px solid #ccc;">${cells[j].innerText}</td>`;
      }
      data += '</tr>';
    }
    data += '</tbody></table>';
   
    return btoa(data);
  
  };

  async function searchExpense () {
    setEmpty(true)
    setLoading(true);
    
    // Verifica se a data é valida
    const verifyDate = await isValidDate(value.data); 
    if(verifyDate === false){
      openNotificationWithIcon('error','Atenção!','Você precisa preencher todos os campos!');
      setError(true);
      setLoading(false);
      return;
    };
    if(!value.data || !value.assessor){
      openNotificationWithIcon('error','Atenção!','Você precisa preencher todos os campos!');
      setLoading(false);
      setError(false);
      return;
    };
    setError(false);
      
    const formataForm = {...value}
    
    formataForm.assessor = formataForm.assessor.split('-')[0].trim();
    formataForm.data = formataForm.data.split('/');
    formataForm.data = `${formataForm.data[1]}-${formataForm.data[0]}`;
    
    //Buscar o resultado do período
    const res = await getResultAssessor(formataForm)
    

    if(res.error){
      openNotificationWithIcon('error','Atenção!',res?.message);
      setLoading(false);
      setEmpty(true);
      return;
    };

    // Formata as informações que o Assessor terá acesso
    delete res.lucro_apos_imposto
    delete res.lucro_liquido_corretora
    delete res.comissao_escritorio
    res.receita_bruta = parseFloat(res.receita_bruta).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
    res.receita_liquida = parseFloat(res.receita_liquida).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
    res.comissao_assessor = parseFloat(res.comissao_assessor).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
    res.net = parseFloat( res.net).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
    res.imposto = parseFloat(res.imposto).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
    res.ajuste = parseFloat(res.ajuste).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});

    // Buscar as operações do período
    const resOperacoes = await getOperations(formataForm);
    
    if(resOperacoes.error){
      openNotificationWithIcon('error','Atenção!',res?.message);
      setLoading(false);
      setEmpty(true);
      return;
    };
    // Calcula o resultado por área
    const assessorArea = await calcularAreaAssessor(resOperacoes)
    setDataTabOperacoes(resOperacoes);
    setDataTab2(assessorArea);
    setDataTab1(res);
    setEmpty(false);
    setLoading(false);
  
  };

  async function sendRelatorio () {

    setLoading2(true)

    const planilha = await getExcel();

    const dataFormatada = {...value}
    const assessor = dataFormatada.assessor.split('-')
    const nome = assessor[1].trim();
    const codigo_assessor = assessor[0].trim();
    const email = assessor[2].trim();
    let data = value.data
  
   
    const res = await sendRelatorioAssessor({
      nome,
      codigo_assessor,
      email,
      data,
      planilha

    })

    if(res?.error){
      openNotificationWithIcon('error','Atenção!','Erro ao enviar o relatório, tente novamente mais tarde!');
      setLoading2(false);
      return;
    }

    if(res?.success){
      openNotificationWithIcon('success','Enviado!','Relatório enviado com sucesso!');
      setLoading2(false);
      return;

    }

  };
     

  return(
          <div className='AssessorConfig__container'>
            {contextHolder}
            <div className="AssessorConfig__busca">   
              <h3 className="AssessorConfig__textH3">Buscar relatório</h3>
                   
                <p>Digite a data</p>
                <input 
                    type="text" 
                    placeholder="Digite a data   --/----"
                    onChange={handleChange}
                    value={value.data}
                    className={error ? 'ExpensesConfig__buscaError' : ''}

                />
                
                <p style={{marginTop:'22px'}}>Assessor</p>
                <Select  
                  className='' 
                  onChange={event=>{
                    setValue({...value, assessor:event})
                   
                  }}
                  value={value.assessor}
                    
                >
                  {
                    assessores.map((assessor, index)=>{
                      return(
                                
                      <Select.Option 
                        key={index} 
                        value={`${assessor.codigo_assessor}-${assessor.nome}-${assessor.email}`}
                      >
                        {`${assessor.codigo_assessor} - ${assessor.nome}`}

                      </Select.Option>
                      )
                    })
                  }
                          
                </Select>                
                
                {error && <p className="ExpensesConfig__textError">Digite uma data válida!</p>}

                {
                    loading
                    ?
                    <div style={{margin:'20px 0px'}}>
                      <Loading
                        text={'Aguarde...'}
                      />

                    </div>
                    
                    :
                    <Button 
                      type="primary" 
                      key="Buscar"
                      onClick={searchExpense}
                      ghost={false}
                    >
                      Clique para buscar
                    </Button> 
                }
                          
            </div>
            <div className="AssessorConfig__content">
            {
              empty 
              ? 
              <div className="ExpensesConfig__empty">
                <Empty 
                  description='Para buscar um relatório, digite a data e selecione o Assessor!'
                />

              </div>
                :
                <>
                  <h3>Visualize o relatório antes de enviar baixando-o agora.</h3>
                  <div>
                    <Tooltip 
                      placement="bottom" 
                      title={'Clique para baixar o relatório'} 
                      arrow={true}
                      className="AssessorConfig__tooltip"
                    >
                      
                      <FileDoneOutlined 
                        className="AssessorConfig__fileRecord"
                        title="Download relatório"
                        label="Download relatório"
                        onClick={downloadExcel}
                      
                      />
                      
              
                    </Tooltip>
                    
                    
                  </div>
                  <table id="my-table" style={{ display:'none'}}>
                    
                    <thead >
                      <tr>
                      {
                        dataTab1
                        &&
                        Object.keys(dataTab1).map((current, index)=>{
                          
                          if(current === 'receita_bruta'){
                            current = 'Receita Bruta'
                          }
                          if(current === 'receita_liquida'){
                            current = 'Receita Líquida'
                          }
                          if(current === 'roa_mensal'){
                            current = 'Roa'
                          }
                          if(current === 'data'){
                            current = 'Data'
                          }
                          if(current === 'codigo_assessor'){
                            current = 'Código Assessor'
                          }
                          if(current === 'comissao_assessor'){
                            current = 'Comissão Assessor (Líquido)'
                          }
                          if(current === 'imposto'){
                            current = 'Impostos'
                          }
                          if(current === 'ajuste'){
                            current = 'Ajuste'
                          }
                          if(current === 'net'){
                            current = 'Net'
                          }
                          
                          return <th className="header-cell" key={index}>{current}</th>
                          
                        })
                      }
                                  
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        
                        {
                          dataTab1
                          &&

                          
                          Object.values(dataTab1).map((current, index) =>{
                            
                            return <td key={index}>{current}</td>

                          })
                          

                        }
                        

                      </tr>
                                      
                    </tbody>
                  </table>
                  <table id="my-table2" style={{display:'none'}}>
                  
                    
                <thead >
                  <tr>
                  {
                    dataTab2
                    &&
                    
                      Object.keys(dataTab2.resultado_categoria).map((current, index)=>{           
                      
                        return <th className="header-cell" key={index}>{current.toUpperCase()}</th>
                        
                      })
                    
                    
                  }
                               
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    
                    {
                      dataTab2
                      &&

                      
                      
                        Object.values(dataTab2.resultado_categoria).map((current, index) =>{
                        
                          return <td key={index}>{current.receita_bruta.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          })}</td>
  
                        })

                      
                      

                    }
                    

                  </tr>
                                  
                </tbody>
                  </table>
                  <table id="my-table3" style={{display:'none'}}>                                       
                    <thead >               
                      <tr>
                        {
                          dataTabOperacoes &&
                            Object.keys(dataTabOperacoes[0]).map((current, index) => {
                              if (current === 'comissao_escritorio' || current === 'comissao_porcentagem_escritorio') {
                                return null;
                              } else {
                                let headerText = current.toUpperCase();

                                switch (current) {
                                  case 'classificacao':
                                    headerText = 'Classificação';
                                    break;
                                  case 'codigo_assessor':
                                    headerText = 'Código Assessor';
                                    break;
                                  case 'nivel1':
                                    headerText = 'Nível1';
                                    break;
                                  case 'nivel2':
                                    headerText = 'Nível2';
                                    break;
                                  case 'nivel3':
                                    headerText = 'Nível3';
                                    break;
                                  case 'nivel4':
                                    headerText = 'Nível4';
                                    break;
                                  case 'receita_bruta':
                                    headerText = 'Receita Bruta';
                                    break;
                                  case 'receita_liquida':
                                    headerText = 'Receita Líquida';
                                    break;
                                  default:
                                    break;
                                }

                                return <th className="header-cell" key={`${index}-operações-header`}>{headerText.toLocaleUpperCase()}</th>;
                              }
                            })
                        }
                      </tr>
                    </thead>                
                    <tbody>
                      {
                        dataTabOperacoes && dataTabOperacoes.map((obj, index) => (
                          <tr key={index}>
                            {Object.entries(obj).map(([propriedade, valor]) => (
                              propriedade === 'comissao_escritorio' || propriedade === 'comissao_porcentagem_escritorio'
                                ? null
                                : ['receita_bruta', 'receita_liquida'].includes(propriedade)
                                  ? <td key={`${propriedade}-operações`} >{parseFloat(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                  : <td key={`${propriedade}-operações`} >{valor}</td>
                            ))}
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>

                  <div className="AssessorConfig__BtnEnviar">
                    {
                      loading2
                      ?
                      <div style={{marginBottom:'20px'}}>
                        <Loading
                          text={'Aguarde...'}
                        />

                      </div>    
                      :
                      <Button 
                        type="primary" 
                        key="Buscar"
                        onClick={sendRelatorio}
                        ghost={false}
                      >
                        Enviar relatório
                      </Button> 
                    }

                  </div>
                </>
                
              }
              
              
              
            </div>     

          </div>
      );
}