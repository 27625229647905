export default async function getAll() {
    let token = localStorage.getItem('token');
    token = JSON.parse(token);
    try {
        const response = await fetch('https://dashboard.atlantainvest.com.br/api/corretores/getAllCorretores.php',{
            method:'post',
            body:JSON.stringify({
                token:token
            })
        });
        
        const res = await response.json();
       // console.log(res)
        return res;

        
    } catch (error) {
        return {
            error:true,
            mensage:error
        }
        
    };
    
};