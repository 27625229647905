import { useEffect, useState } from 'react';
import { 
    Button, 
    Select, 
    notification,
    Form, 
    Input,
    Tooltip 
} from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import 'antd/dist/reset.css';
import TableUsers from '../tableUsers/TableUsers';
import './userConfig.css';
import MyModal from '../myModal/MyModal';
import CreateCorretor from '../../actions/corretores/create-corretor';
import verifyForm from '../../lib/verifyForm';
import Loading from '../loading/Loading';
import { MyResult } from '../MyResult/MyResult';
import checkEmail from '../../actions/corretores/check-email';
import checkCdgAssessor from '../../actions/corretores/check-cdgAssessor';
import getAll from '../../actions/corretores/getAll-corretores';


export default function UserConfig({ dataUser,clickDelet, clickSuspend }) {
    const [modal, setModal] = useState(false);
    const [result, setResult] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorForm, setErrorForm] = useState(false);
    const [resultState, setResultState] = useState(false);
    const [tableAssessores, setTableAssessores] = useState(dataUser);
    const [api, contextHolder] = notification.useNotification();
    const [form, setForm] = useState({
        cdgAcessor: '',
        nome: '',
        email: '',
        tipo: '',
        status: 'ativo'
    });

    useEffect(()=>{
        setTableAssessores(dataUser)
    },[dataUser]);

    const openNotificationWithIcon = (type, msg, text) => {
      api[type]({
        message: msg,
        description: text
         
      });
    };

    async function verifyTipo () {
        if(form.tipo === '') {
            setErrorForm(true);
            openNotificationWithIcon('error','Atenção!','Você precisa preencher todos os campos para prosseguir!');

            return;
        }else if(errorForm){
            setErrorForm(false); 
        };
        
        const contadorError = verifyForm(form);
        if(contadorError >0){
            openNotificationWithIcon('error','Atenção!','Você precisa preencher todos os campos para prosseguir!');
            return;
        }

        //Verifica se o Código do Assessor já está cadastrado
        const verifyCdgAcessor = await checkCdgAssessor(form.cdgAcessor);
        if(verifyCdgAcessor.error) {
            openNotificationWithIcon('error','Atenção!',`${verifyCdgAcessor.mensage}`);
            return;
            
        };

        //Verifica se o Email já está cadastrado
        const verifyEmail = await checkEmail(form.email)
        if(verifyEmail.error){
            openNotificationWithIcon('error','Atenção!',`${verifyEmail.mensage}`);
            return;

        };

        // Se o email e o cdg do acessor não estiverem cadastrados, cadastra novo acessor
        setLoading(true);
        const res = await CreateCorretor(form);
        if(res?.error){
            setLoading(false);
            openNotificationWithIcon('error','Atenção!', 'Não foi possível realizar o cadastro, tente mais tarde!');
            setResult(true);
            setResultState(false);
            
            return;
        };
        

        setResult(true);
        setResultState(true)
        setLoading(false);   
         await setForm({
            cdgAcessor: '',
            nome: '',
            email: '',
            tipo: '',
            status: 'ativo'
        });
       
        await getAllCorretores()

      
        
    };

    async function getAllCorretores() {
        const res = await getAll();
        if(Object.values(res).length > 0) {
            const filterName = [];
            const filterEmail = [];
            const newData =  Object.values(res).map(callback => {
                
                if(callback.tipo === 'socio') {
                    callback.tipo = 'Sócio';
                }else if(callback.tipo === 'associado') {
                    callback.tipo = 'Associado';

                };

                filterName.push({
                    text:callback.nome,
                    value:callback.nome,
        
                })
                filterEmail.push({
                    text:callback.email,
                    value:callback.email,
        
                })

                return {
                    
                    key:callback.id,
                    codigo_assessor:callback.codigo_assessor,
                    nome:callback.nome,
                    email:callback.email,
                    status:[callback.status],
                    tipo:[callback.tipo]


                }
               
            })
            setTableAssessores({
                columns:newData,
                filterName:filterName,
                filterEmail:filterEmail
            });

        }
        
        
    }

    function setModalCadastrarFalse () {
        setForm({
            cdgAcessor: '',
            nome: '',
            email: '',
            tipo: '',
            status: 'ativo'
        });
        setModal(false);
        setResult(false);
    };

   
    return(
        
        <div className='UserConfig__container'>
            {contextHolder}
            <div className='UserConfig__firstContent'>
                <div className='UserConfig__BrokerTable'>
                    <div className='UserConfig_BntContent'>
                        <Tooltip 
                            title='Cadastrar Assessor'
                            placement="bottom"
                        
                        >
                            <PlusCircleFilled
                                onClick={()=> setModal(true)}
                                className='UserConfig__bntRegister'     
                
                            />  
                        </Tooltip >

                    </div>
                   
                    <TableUsers 
                        data={tableAssessores}
                        clickDelete={clickDelet}
                        clickSuspend={clickSuspend}
                    
                    
                    />
                    <MyModal 
                        modalState={modal}
                        setModalState={setModal}
                        title={"Cadastrar novo Assessor"}
                        key={'2'}
                        content={
                            <>
                                <div className={loading ||  result ? '--Disable' :''} >
                                    <Form
                                        name="basic"
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}
                                        initialValues={{ remember: true }}
                                        onFinish={''}
                                        onFinishFailed={''}
                                        autoComplete="off"
                                        style={
                                            {
                                                width:'100%',
                                                display:'flex',
                                                flexDirection:'column', 
                                                justifyContent:"center", 
                                                alignItems:"center",
                                                gap:'20px',
                                                margin:'40px 0px 0px 0px'
                                            }
                                        }
                                        
                                    >
                                        <label
                                            className='UserConfig__label'
                                        > 
                                            <p>Nome</p>
                                            
                                            <Input 
                                                onChange={(event) => setForm({ ...form, nome: event.target.value })}
                                                value={form.nome}
                                                className='UserConfig__inputCadastrar'
                                                placeholder='Digite o nome'
                                            />
                                        </label>
                                        <label htmlFor="" className='UserConfig__label'>
                                            <p>Tipo</p>
                                            <Select
                                                aria-required
                                                status={errorForm ? 'error' :''}
                                                onChange={(event) => setForm({ ...form, tipo: event })}
                                                value={form.tipo}
                                                className='UserConfig__inputCadastrar'
                                                
                                            >
                                                <Select.Option value="socio">Sócio</Select.Option>
                                                <Select.Option value="associado">Associado</Select.Option>
                                                    
                                            </Select>
                                        </label>                                                                                                            
                                       <label 
                                           
                                           className='UserConfig__label'
                                        >
                                            
                                            <p>Código Assessor</p>
                                            <Input 
                                                onChange={(event) => setForm({ ...form, cdgAcessor: event.target.value })}
                                                value={form.cdgAcessor}
                                                className='UserConfig__inputCadastrar'
                                                placeholder='Digite o código'
                                        
                                            />
                                        </label> 
                                        <label
                                           className='UserConfig__label'
                                            style={{marginLeft:'2%'}}
                                        >
                                            <p>E-mail</p>
                                            <Input 
                                                onChange={(event) => setForm({ ...form, email: event.target.value })}
                                                value={form.email}
                                                className='UserConfig__inputCadastrar'
                                                placeholder="📧 E-mail"
                                        
                                        
                                            />                                   
                                        </label>         
                                        <div className='UserConfig__modalBnt'>                             
                                            <Button 
                                                type="primary" 
                                                key="sim"
                                                ghost
                                                onClick={()=> setModal(false)}
                                                
                                            >
                                                Não
                                            </Button>
                                            <Button 
                                                type="primary" 
                                                key="nao" 
                                                htmlType="submit"
                                                onClick={verifyTipo}
                                                
                                                
                                            >
                                                Cadastrar
                                            </Button>
                                        </div>

                                    </Form>

                                </div>
                                <div className={!loading ? '--Disable' :''}>
                                    <Loading 
                                        text={'Aguarde...'}
                                    
                                    />

                                </div>
                                <div className={!result ? '--Disable' :''}>
                                    <MyResult 
                                    resultState={resultState}                                          
                                    titleSuccess={'Assessor cadastrado com sucesso!'}
                                    textSuccess={'Clique em "Voltar" para retornar ao menu.'}
                                    titleError={'Erro ao cadastrar o Assessor!'}
                                    textError={'Não foi possível cadastrar esse Assessor, tente mais tarde!'}
                                    click={setModalCadastrarFalse}

                                />
                                </div>                          
                                
                            </>                           
                        }                  
                    />                                      
                </div>
            </div>
        </div>
    );
}