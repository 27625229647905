import { Button, Result } from 'antd';

export function MyResult ({
    resultState, 
    titleSuccess,
    textSuccess,
    titleError,
    textError,
    click,
 
}) {
    return (
        <>
            <div className={resultState ? '': '--Disable'}>
                <Result
                    status="success"
                    title={titleSuccess}
                    subTitle={textSuccess}
                    extra={[
                    <Button 
                        type="primary" 
                        onClick={click}
                        key='console2'
                    >
                        Voltar
                    </Button>,
                    
                    ]}
                />
                            
            </div>
            <div className={resultState ? '--Disable': ''}>
                <Result
                    status="error"
                    title={titleError}
                    subTitle={textError}
                    extra={[
                    <Button 
                        type="primary" 
                        key="console"
                        onClick={click}
                        
                    >
                        Voltar
                    </Button>,
                    
                    ]}
                >
                </Result>

            </div>

        </>
    )
}