import { useEffect, useState } from 'react';
import MyModal from '../myModal/MyModal';
import { Select, notification, Table,Space, Tag } from 'antd';
import { Button, Result,Tooltip } from 'antd';
import { FormOutlined, 
  DeleteOutlined, 
  WarningOutlined, 
  CheckSquareOutlined,  
} from '@ant-design/icons'
import Loading from '../loading/Loading';
import { MyResult } from '../MyResult/MyResult';
import suspendCorretor from '../../actions/corretores/suspend-corretor';
import reactivateCorretor from '../../actions/corretores/reactivate-corretor';
import UpdateCorretor from '../../actions/corretores/update-corretor';
import './tableUsers.css';
import getAll from '../../actions/corretores/getAll-corretores';



export default function TableUsers ( { data, clickDelete }) {
  const [edit, setEdit] = useState(false);
  const [delet, setDelet] = useState(false);
  const [corretor, setCorretor] = useState({
    nome:'',
    email:'',
    tipo:'',
    codigo_assessor:''
  });
  const [suspend, setSuspend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(false);
  const [resultState, setResultState] = useState(false);
  const [status, setStatus] = useState(false); // false = suspender //  true = reativar
  const [tableAssessores, setTableAssessores] = useState(data);
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type, msg, text) => {
    api[type]({
      message: msg,
      description: text
       
    });
  };

  useEffect(()=>{
    setTableAssessores(data)
    

  },[data]);

  async function getAllCorretores() {
    const res = await getAll();

    if(Object.values(res).length > 0) {
        const filterName = [];
        const filterEmail = [];
        const newData =  Object.values(res).map(callback => {
            
            if(callback.tipo === 'socio') {
              callback.tipo = 'Sócio';
            }else if(callback.tipo === 'associado') {
              callback.tipo = 'Associado';
            };

            filterName.push({
              text:callback.nome,
              value:callback.nome,
    
            })
            filterEmail.push({
              text:callback.email,
              value:callback.email,
    
            })

            return {              
              key:callback.id,
              codigo_assessor:callback.codigo_assessor,
              nome:callback.nome,
              email:callback.email,
              status:[callback.status],
              tipo:[callback.tipo]

            }
           
        })
        setTableAssessores({
            columns:newData,
            filterName:filterName,
            filterEmail:filterEmail
        });
    } 
  };

  function isChangeStatus (event) {
    const name = event.nome.toUpperCase();
    if(event.status[0] === 'suspenso'){ 
      setCorretor({
        id:event.key,
        nome:name
      });
      setSuspend(true);
      setStatus(true);
        
    }else if(event.status[0] === 'ativo'){
      setCorretor({
        id:event.key,
        nome:name
      });
      setSuspend(true);
      setStatus(false);
        
    };
    
  };

  async function changeStatus () {
    setLoading(true);

    if(status) {
      const data = await reactivateCorretor(corretor.id);
      if(data.error) {
        setResult(true);
        setResultState(false);
        setLoading(false);
          
        return;

      }else if(data.success){
        setResult(true);
        setResultState(true);
        setLoading(false);
        await getAllCorretores();
          
        return;
          
      };

    }else if(!status){
      const data = await suspendCorretor(corretor.id);
      if(data.error) {
        setResult(true);
        setResultState(false);
        setLoading(false);
          
        return;

      }else if(data.success){
        setResult(true);
        setResultState(true);
        setLoading(false);
        await getAllCorretores();
          
        return;
          
      };

    };
      
  };

  async function deleteCurrent () {
      setLoading(true)
      const data = await clickDelete(corretor.id)
      if(data.error) {
        setResult(true)
        setResultState(false);
        setLoading(false)
        
        return;

      }else if(data.success){
        setResult(true)
        setResultState(true);
        setLoading(false)
        await getAllCorretores();
        
        return
        
      }

  };

  function isDelete (event) {
    setDelet(true)
    const name = event.nome.toUpperCase();
    setCorretor({
      id:event.key,
      nome:name
    });

  };

  async function updateCurrent () {

    if(!corretor.nome.trim() || !corretor.email.trim() ||  !corretor.codigo_assessor.trim() || !corretor.tipo.trim()){
      openNotificationWithIcon('error','Atenção!','Você precisa preencher todos os campos!')
      return;
    };

    setLoading(true);
    const data = await UpdateCorretor(corretor);

    if(data.error) {
      setResult(true);
      setResultState(false);
      setLoading(false)
      setCorretor({...corretor, nome:'',email:'', tipo:'', codigo_assessor:''})
      return;

    }else if(data.success){
      setResult(true);
      setResultState(true);
      setLoading(false);
      await getAllCorretores();
      setCorretor({...corretor, nome:'',email:'', tipo:'', codigo_assessor:''})
      return;  
    }

  };

  async function isUpdate (event) {
    setCorretor({
      id:event.key,
      nome:event.nome,
      email:event.email,
      codigo_assessor:event.codigo_assessor,
      tipo:event.tipo[0]
    }); 
    setEdit(true);

  };

  function setResultFalse (){
    setSuspend(false);
    setDelet(false)
    setEdit(false)
    setResult(false)
    
  };

  const columns = [
    {
      title: 'id',
      dataIndex: 'key',
      width: '5%',
    },
    {
      title: 'Código do Assessor',
      dataIndex: 'codigo_assessor',
      width: '13%'
     
    },
    {
      title: 'Nome',
      dataIndex: 'nome',
      filters: data.filterName,
      filterSearch: true,
      onFilter: (value, record) => record.nome.startsWith(value),
      width: '19%',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      filters: data.filterEmail,
      filterSearch: true,
      onFilter: (value, record) => record.email.startsWith(value),
      width: '19%',
    },
    {
      title: 'Tipo',
      key: 'tipo',
      dataIndex: 'tipo',
      filters: [
        {
          text: 'Sócio',
          value: 'Sócio',
        },
        {
          text: 'Associado',
          value: 'Associado',
        },
        
      ],
      render: (_, { tipo }) => (
        <>
          {tipo.map((tipo) => {
            let color;
            if (tipo === 'Associado') {
              color = 'blue';
            }
            if (tipo === 'Sócio') {
              color = '#002766';
            }
            return (
              <Tag color={color} key={tipo}>
                {tipo ? tipo.toUpperCase() : ''}
              </Tag>
            );
          })}
        </>
      ),
      onFilter: (value, record) => record.tipo.includes(value),
      width: '11%'
      
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      filters: [
        {
          text: 'ativo',
          value: 'ativo',
        },
        {
          text: 'suspenso',
          value: 'suspenso',
        },
        
      ],
      render: (_, { status }) => (
        <>
          {status.map((status) => {
            let color;
            if (status === 'suspenso') {
              color = 'red';
            }
            if (status === 'ativo') {
              color = 'green';
            }
            return (
              <Tag color={color} key={status}>
                {status.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
      onFilter: (value, record) => record.status.includes(value),
      width: '11%'
      
    },
    {
      title: 'Ações',
      key: 'action',
      render: (_, record) => (
        <>
          {record.status.map((status) => {
        
            let buttonName;
            if (status === 'suspenso') {
              
              buttonName = <Tooltip placement="bottom" title={"Ativar Assessor"}>
                            <CheckSquareOutlined 
                              className='TableAdm__iconsAtivar'
                            
                            />
                          </Tooltip>
            }
            if (status === 'ativo') {
              buttonName = <Tooltip placement="bottom" title={"Suspender Assessor"}>
                            <WarningOutlined 
                              className='TableAdm__iconsSuspend' 
                            
                            
                            />
                          </Tooltip>
            }
            return (
              <Space size="middle" key={record.key}>
          <p 
            onClick={()=>isUpdate(record)} 
            className='TableUsers__actions'
          >
            <Tooltip placement="bottom" title={"Editar Assessor"}>
              <FormOutlined
                className='TableAdm__iconsEdit' 
              
              />
            </Tooltip>
          </p>
          <p 
            onClick={()=>isChangeStatus(record)}
            className='TableUsers__actions'
          >
            {buttonName}
          </p>
          <p 
            onClick={()=>isDelete(record)} 
            className='TableUsers__actions'
          >
            <Tooltip placement="bottom" title={"Deletar Assessor"}>
              <DeleteOutlined 
                className='TableAdm__iconsDelete'
              
              />
            </Tooltip>
          </p>
        </Space>
            );
          })}
        </>
      ),

    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
  };

  function modalEditFalse () {
    setEdit(false)
    setCorretor({
      nome:'',
      tipo:'',
      codigo_assessor:'',
      email:''
    })
    
  }



  return (
        <>
          {contextHolder}
            <Table 
              columns={columns} 
              dataSource={tableAssessores.columns} 
              onChange={onChange} 
              pagination='bottomLeft'
                    
            />

            < MyModal 
                modalState={edit}
                setModalState={setEdit}
                title={'Editar informações'}
                content={
                  <div>
                    <div className={result ? '--Disable':'TableUser_editContainer'}>
                      <form
                        name="basic"
                        className='TableUser__editForm'
                      >
                        <label htmlFor="" className='TableUser__label'>
                          <p>Nome</p>
                          <input 
                            placeholder={`Digite o nome`}
                            className='TableUser__input'
                            onChange={event=>setCorretor({...corretor, nome:event.target.value})}
                            value={corretor.nome}

                          />
                        </label>
                        
                        <label htmlFor="" className='TableUser__label'>
                          <p>Tipo</p>
                          <Select  
                            className='' 
                            onChange={event=>setCorretor({...corretor, tipo: event})}
                            value={corretor.tipo}
                            style={{width:'65%', borderRadius:'3px !important',height:'35px'}}
                          >
                            <Select.Option value="socio">Sócio</Select.Option>
                            <Select.Option value="associado">Associado</Select.Option>
                          
                          </Select>
                        </label>
                         <label htmlFor="" className='TableUser__label'>
                          <p>Código</p>  
                          <input 
                            placeholder={'Digite o Código'}
                            className='TableUser__input'
                            onChange={event=>setCorretor({...corretor, codigo_assessor: event.target.value})}
                            value={corretor.codigo_assessor}
                                        
                          />
                        </label>  
                        <label htmlFor="" className='TableUser__label'>
                          <p>E-mail</p>
                          <input 
                            placeholder="📧 E-mail"
                            className='TableUser__input'
                            onChange={event=>setCorretor({...corretor, email: event.target.value})}
                            value={corretor.email}
                                      
                          />
                        </label>                 
                        <div className={result ? '--Disable':'TableUsers__btnModal'} style={{marginBottom:'0px'}}>
                          <Button 
                            type="primary" 
                            key="não"
                            onClick={modalEditFalse}
                            ghost
                          >
                            Não
                          </Button>
                          <Button 
                            type="primary" 
                            key="sim"                           
                            onClick={updateCurrent}
                          >
                            Editar
                          </Button>
                              
                        </div>
                      </form>
                      <div className='TableUsers__modalSpin'>
                        {
                          suspend === true 
                          && 
                          loading ===true  
                          && 
                          <Loading 
                            text={'Carregando...'}
                            
                          />
                        }
                      </div>  
                    </div>
                    <div className={result ? 'TableUsers__modalContent':'--Disable'}>
                      <MyResult 
                        resultState={resultState}                                          
                        titleSuccess={'Cadastro atualizado com sucesso!'}
                        textSuccess={'Clique em "Voltar" para retornar ao menu.'}
                        titleError={'Erro ao atualizar o cadastro!'}
                        textError={'Não foi possível atualizar o cadastro, tente mais tarde!'}
                        click={setResultFalse}
                      
                      />
                    </div>                                   
                  </div>
              }  

            />

            < MyModal 
                modalState={delet}
                setModalState={setDelet}
                title={'Excluir Assessor'}
                content={
                  <div className='TableUsers__deleteContainer'>
                    <div className={result ? '--Disable':'TableUsers__modalContent'}>
                      <Result
                        status="error"
                        title={``}
                          
                      />
                      <h2> Tem certeza que você deseja 
                        <span 
                          className='TableUsers__spanName'
                        >
                          
                        &nbsp;excluir
                        </span> Assessor &nbsp; 
                        <span 
                          className='TableUsers__spanName'
                        >
                          {corretor.nome}
                        </span> ? 
                        
                      </h2>
                      <div 
                        className={loading ? '--Disable' : 'TableUsers__btnModal'}

                      >
                        <Button 
                            type="primary" 
                            onClick={()=>setDelet(false)}
                            ghost
                          >
                            Não
                        </Button>
                        <Button 
                            type="primary"  
                            
                            onClick={deleteCurrent}
                            
                          >
                            Excluir
                        </Button>
                         
 
                      </div>
                      <div className={loading ? 'TableUsers__modalSpin' : '--Disable'}>
                        {
                          delet === true 
                          && 
                          loading ===true  
                          && 
                          <Loading 
                            text={'Carregando...'}
                            
                          />
                        }

                      </div>

                    </div>
                    <div className={result ? 'TableUsers__modalContent':'--Disable'}>
                      <MyResult 
                        resultState={resultState}                                          
                        titleSuccess={'Assessor deletado com sucesso!'}
                        textSuccess={'Clique em "Voltar" para retornar ao menu.'}
                        titleError={'Erro ao deletar o Assessor!'}
                        textError={'Não foi possível deletar esse Assessor, tente mais tarde!'}
                        click={setResultFalse}

                      />

                    </div>

                  </div>

              }
            
            />

            < MyModal
                modalState={suspend}
                setModalState={setSuspend}
                title={status ? 'Reativar Assessor' : 'Suspender Assessor'}
                content={
                  <div className='TableUsers__suspendContainer'>
                    <div className={result ? '--Disable':'TableUsers__suspendContent'}>
                      <Result
                        status="warning"
                        title={''}
                            
                      />
                      <h2>
                        Você está &nbsp;
                        <span 
                          className='TableUsers__spanName'
                        >
                          {status ? 'reativando' : 'suspendendo'}
                        </span> o Assessor <br/>
                        <span 
                          className='TableUsers__spanName'
                        >
                          {corretor.nome}
                        </span> ? 
                        
                      </h2> 
                      <div className={loading ? '--Disable' : 'TableUsers__btnModalSuspend'}>
                        <Button 
                            type="primary"                            
                            onClick={()=>setSuspend(false)}
                            ghost
                          >
                            Não
                        </Button>
                        <Button 
                            type="primary"                            
                            
                            onClick={changeStatus}
                          >
                            {status ? 'Reativar' : 'Suspender'}
                        </Button>
                          
 
                      </div>
                      <div className={loading && suspend ? 'TableUsers__modalSpin' : '--Disable'}>
                        {
                          suspend === true 
                          && 
                          loading ===true  
                          && 
                          <Loading 
                            text={'Carregando...'}
                            
                          />
                        }

                      </div>                   
                    </div>   
                    <div className={result ? 'TableUsers__modalContent':'--Disable'}>
                    <MyResult 
                        resultState={resultState}                                          
                        titleSuccess={status ? `Assessor reativado com sucesso!` : `Assessor suspenso com sucesso!`}
                        textSuccess={'Clique em "Voltar" para retornar ao menu.'}
                        titleError={status? 'Erro ao reativar o Assessor!' : 'Erro ao suspender o Assessor!'}
                        textError={status? 'Não foi possível reativar esse Assessor, tente mais tarde!': 'Não foi possível suspender esse Assessor, tente mais tarde!'}
                        click={setResultFalse}
                      />

                    </div>                 
                  </div>
              }

            />
        </>
        

  );
}




