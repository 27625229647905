export default async function changePassword(senha, id) { 
    let token = localStorage.getItem('token');
    token = JSON.parse(token);
    try {
        const response = await fetch(`https://dashboard.atlantainvest.com.br/api/adms/password-adm.php`,{
            
            method:'post',
            body: JSON.stringify ({
                id:id,
                senha:senha,
                token:token
    
                              
            })
        });

        const res = await response.json();
        return res;
        
        
    } catch (error) {
        return {
            error:true,
            mensage:error
        };
        
        
    };
       

};