import React, { useEffect, useRef } from 'react';
import { Input } from 'antd';
import Inputmask from 'inputmask';


export default function InputReais({id, name, value, onChange, placeholder}) {
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      const mascara = new Inputmask('R$ 999.999.999,99', {
        numericInput: true,
        placeholder: '0',
        prefix: '',
        radixPoint: ',',
        groupSeparator: '.',
        digits: 2,
        autoGroup: true,
        rightAlign: false,
      });

      mascara.mask(inputRef.current.input);
    }
  }, []);

  return (
    <Input
      ref={inputRef}
      id={id}
      name={name}
      type="text"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
}

