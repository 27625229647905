import { useEffect} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';


export function PrivateRoute ({ children }) {
    const navigate = useNavigate();
    const location = useLocation();
    
    useEffect(() => {
      const token = localStorage.getItem('token');
      async function getTokenStorage () {

        if (!token) {
          navigate(
            '/', 
            {replace:true}
    
          );
          return;

        }

        await AuthToken();

      };

      async function AuthToken () {
        try {
          let token = localStorage.getItem('token');
          token = JSON.parse(token);
            
          const response = await fetch(`https://dashboard.atlantainvest.com.br/api/public/verify-token.php`,{
            method:'post', 
            body:JSON.stringify({
              token
            }),
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
                            
            }   
          })

          const res = await response.json();
      
          if(res?.error) {
            navigate(
              '/', 
              {replace:true}
            ); 

            return;

          }else if(res?.success){
                  
            return children;

          }
            
            
        } catch (error) {
          console.log(error);
            
          navigate(
            '/', 
            {replace:true}
          ); 
            
        }

      };

      getTokenStorage();
    },[location.pathname, children, navigate]);
    
  return children;
}