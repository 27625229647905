

export default async function CreateCorretor(form) {
    let token = localStorage.getItem('token');
    token = JSON.parse(token);
    try {
        const response = await fetch(`https://dashboard.atlantainvest.com.br/api/corretores/create-corretor.php`,{
            method:'post',
            body: JSON.stringify ({
                nome:form.nome,
                codigo_assessor:form.cdgAcessor,
                email:form.email,
                tipo:form.tipo,
                status:'ativo',
                token:token
    
           
                            
            })
        });

        const res = await response.json();
        return res;
        
    } catch (error) {
        return {
            error:true,
            mensage:error
        };
        
        
    };
    
       

};