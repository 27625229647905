
export default async function deleteExpense (id) {
    let token = localStorage.getItem('token');
    token = JSON.parse(token);

    try {
        const response = await fetch(`https://dashboard.atlantainvest.com.br/api/expenses/delete-expense.php`,{
            method:'post',
            body: JSON.stringify ({
                id:id,
                token:token                           
            })
        });
        const res = await response.json();
        
        return res;
        
    } catch (error) {
        return {
            error:true,
            mensage:error
        }
        
    }

   
    
}