import { useEffect, useState } from "react";
import getOneAdm from "../../actions/adms/getOne-adm";
import AdmConfig from "../../components/admConfig/AdmConfig";
import MyLayout from "../../components/myLayout/MyLayout";
import './myAccount.css';




export default function MyAccount () {
    const [data, setData] = useState([]);
    useEffect(()=> {
        async function getAdms() {
            
            const res = await getOneAdm();
            if(Object.values(res).length > 0) {
                const filterName = [];
                const filterEmail = [];

                const newData =  Object.values(res).map(callback => {

                    filterName.push({
                        text:callback.nome,
                        value:callback.nome,
            
                    })
                    filterEmail.push({
                        text:callback.email,
                        value:callback.email,
            
                    })

                    return {
                        key:callback.id,
                        nome:callback.nome,
                        email:callback.email,
                        status:[callback.status],
                       


                    };
                })

                setData({
                    columns:newData,
                    filterName:filterName,
                    filterEmail:filterEmail
                });

            }
            
            
        }
        getAdms();

    },[])
    



    return (
        <div className="MyAccount__conteiner">
        <MyLayout 
            component={ 
                <AdmConfig
                    dataUser={data} 
                    

                /> 
            }
            userLocation={'Administrador / Minha Conta'}
            indexNavSide={['7']}
        
        />
       
    </div>

    );
}