export default async function registerOperations(data) {
  let token = localStorage.getItem('token');
  token = JSON.parse(token);


  try {
      const response = await fetch(`https://dashboard.atlantainvest.com.br/api/operation/register.php`,{
          method:'post',
          body: JSON.stringify ({
              data:data,
              token:token
         
          })
      });

    const res = await response.json();
    //console.log(res)
      return res;
      
      
      
  } catch (error) {
      return {
          error:true,
          mensage:error
      };
      
      
  };
     
  
};