

export default async function login(user) {

    try {
        const response = await fetch(`https://dashboard.atlantainvest.com.br/api/public/login.php`,{
            method:'post',
            body: JSON.stringify ({
                email: user.email,
                senha: user.senha            
            }),
            headers: {

                'Content-Type': 'application/json',
                Accept: 'application/json',
                            
            }   
        })

        const res = await response.json();
        
        return res;
        
        
    } catch (error) {
        return {
            error:true,
            mensages:error
        }
        
    }

};