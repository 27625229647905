export default async function exportData (arr) {

    
    var headers = arr[0];
    var result = {};
      
    for (var i = 1; i < arr.length; i++) {
        var obj = {};
        for (var j = 0; j < headers.length; j++) {
            if( typeof headers[j] !== 'object'){
                obj[headers[j].toLowerCase()] = arr[i][j];

            }
            
        }
        result[i-1] = obj;
    }
      
    return result;
    
      

}