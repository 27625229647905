export default async function formatData (objects) {
    const newData = objects.flatMap((obj) =>
        obj.map((item) => {
            const newItem = {};
            for (let prop in item) {
            let newProp = prop.toLowerCase();
            newProp = newProp.replace('r$', '');
            if (newProp === 'produtocategoria' || newProp === 'produto/categoria') {
                newProp = 'categoria';
            }
            if (newProp === '') {
                delete item[prop];
            } else {
                let propSemAcentos = removerAcentos(newProp);
                if (propSemAcentos.includes('(%')) {
                    let propSemEspacos = propSemAcentos.replace(/\s/g, '');
                    newItem[propSemEspacos] = item[prop];
                } else {
                    let propSemEspacos = propSemAcentos.replace(/\s/g, '');
                    let propSemEspeciais = propSemEspacos.replace(/[^\w\s]/gi, '');
                    newItem[propSemEspeciais] = item[prop];
                }
            }
            }
            return newItem;
        })
    );

    // função auxiliar para remover acentos
    function removerAcentos(texto) {
    return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/([^\p{ASCII}])/gu, '');
}

    return newData;

};

