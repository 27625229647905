export default async function converteDre (data) {


    const formataData = Object.values(data).reverse();
    const dreFormatado = [];

    for (let i = 0; i < formataData.length; i++) {

      

        formataData[i].comissão_escritório = formataData[i].comissão_escritório.replace(/[^\d,]/g, '').replace(',', '.') 
        formataData[i].impostos_pj1 = formataData[i].impostos_pj1.replace(/[^\d,]/g, '').replace(',', '.');  
        formataData[i].impostos_pj2 = formataData[i].impostos_pj2.replace(/[^\d,]/g, '').replace(',', '.'); 
        formataData[i].receita_bruta_pj1 = formataData[i].receita_bruta_pj1.replace(/[^\d,]/g, '').replace(',', '.');
        formataData[i].receita_bruta_pj2= formataData[i].receita_bruta_pj2.replace(/[^\d,]/g, '').replace(',', '.');
        formataData[i].custos = formataData[i].custos.replace(/[^\d,]/g, '').replace(',', '.');
        formataData[i].despesas = formataData[i].despesas.replace(/[^\d,]/g, '').replace(',', '.');
        formataData[i].comissão_paga = formataData[i].comissão_paga.replace(/[^\d,]/g, '').replace(',', '.');
        
        const comissao_pg = parseFloat(formataData[i].comissão_paga);
        const impostospj1 = parseFloat(formataData[i].impostos_pj1);
        const impostospj2 = parseFloat(formataData[i].impostos_pj2);
        const impostos = (impostospj1 + impostospj2);
        const receita = parseFloat(formataData[i].comissão_escritório);
        const margem = (receita - impostos)- comissao_pg;
        const receitaBrutaPj1 = parseFloat(formataData[i].receita_bruta_pj1);
        const receitaBrutaPj2 = parseFloat(formataData[i].receita_bruta_pj2);
        const despesas = parseFloat(formataData[i].despesas);
        const custos = parseFloat(formataData[i].custos);

        const lucroLiquido = margem - (custos + despesas);
        
      

        dreFormatado.push(
            {
                key: i,
                data: formataData[i].data,
                receita:receita.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
                impostos: impostos.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
                margem:margem.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
                custos:custos.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
                despesas:despesas.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
                lucroliquido: lucroLiquido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
                caixa:lucroLiquido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
                comissao_pg:parseFloat(formataData[i].comissão_paga).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
                description: <div>
                    <table id="table-assessoresroaanual" >                                       
                        <thead >               
                            <tr>
                                <th className="header-cell" >Receita Bruta Pj1</th>
                                <th className="header-cell" >Impostos Pj1</th>
                                <th className="header-cell" >Receita Bruta Pj2</th>
                                <th className="header-cell" >Impostos Pj2</th>                                                                            
                                                                                        
                            </tr>
                        </thead>                
                        <tbody>                                                                             
                          <tr > 

                            <td className="body-cell">{receitaBrutaPj1.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                            <td className="body-cell">{impostospj1.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                            <td className="body-cell">{receitaBrutaPj2.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                            <td className="body-cell">{impostospj2.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                                                                                                                                                         
                          </tr>
                                                                                       
                        </tbody>
                    </table>
                
                </div>
                
              }
        )
        
        
    }

     return dreFormatado  ;
        
    

}