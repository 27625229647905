import { Modal } from 'antd';
import './myModal.css';




export default function MyModal ({ 
    modalState, 
    setModalState, 
    title, 
    content  
}) {

    const handleOk = () => {
        setModalState(false);
    };

    const handleCancel = () => {
        setModalState(false);
    };

    return (
        <Modal 
            title={title} 
            open={modalState} 
            onOk={handleOk} 
            onCancel={handleCancel}
            maskClosable={false}
            closable={false}
          
            className='MyModal__container'
            
        >
            {content}
           
        </Modal>

    )
}