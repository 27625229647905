export default function assessoresOperacoes(assessores, operacoes) {
    const resultado = {};

    operacoes.forEach(operacao => {
      if (!operacao.classificacao || !operacao.classificacao.toUpperCase().includes('RECEITAS')) {
        return;
      }
  
      const assessor = assessores.find(a => a.codigo_assessor === operacao.codigo_assessor && a.status.toUpperCase() === 'ATIVO');
  
      if (!assessor) {
        return;
      }
  
      const categoria = operacao.categoria.toUpperCase().includes('COCORRETAGEM') ? 'VIDA' : (operacao.categoria.toUpperCase().includes('FUNDOS') ? 'FUNDOS' : (operacao.categoria.toUpperCase().includes('INDICAO DE CLIENTES') ? 'PRODUTOS ESTRUTURADOS' : operacao.categoria));


      
  
      if (!resultado[operacao.codigo_assessor]) {
        resultado[operacao.codigo_assessor] = {
          nome_assessor: assessor.nome,
          cod_assessor: assessor.codigo_assessor,
          operacoes: []
        };
      }
  
      const operacaoIndex = resultado[operacao.codigo_assessor].operacoes.findIndex(op => op.categoria === categoria);
  
      if (operacaoIndex === -1) {
        resultado[operacao.codigo_assessor].operacoes.push({
          categoria,
          receita_bruta: parseFloat(operacao.receita_bruta)
        });
      } else {
        resultado[operacao.codigo_assessor].operacoes[operacaoIndex].receita_bruta += parseFloat(operacao.receita_bruta);
      }
    });
  
    const resultadoArray = Object.values(resultado);
  
    return formatChartData(resultadoArray);
  }
  
  function formatChartData(data) {
    const categories = new Set();
    data.forEach(assessor => {
      assessor.operacoes.forEach(operacao => categories.add(formatCategory(operacao.categoria)));
    });
  
    const chartData = [
      ['Assessor', ...Array.from(categories), { role: 'annotation' }],
      ...data.map(assessor => ([
        assessor.nome_assessor,
        ...Array.from(categories).map(category => {
          const operacao = assessor.operacoes.find(op => formatCategory(op.categoria) === category);
          return operacao ? operacao.receita_bruta : 0;
        }),
        ''
      ]))
    ];
  
    return chartData;
  }
  
  function formatCategory(category) {
    switch (category.toLowerCase()) {
      case 'previdencia':
        return 'PREVIDENCIA';
      case 'credito colateralizado':
        return 'CREDITO COLATERALIZADO';
      default:
        return category.toUpperCase();
    }
  }
  