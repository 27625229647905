import calculosAssessores from "./assessor_calc";

export default async function calcularReceitaCorretores(operacoes, netAssessores) {
  const corretores = {};
  
  for (let i = 0; i < operacoes.length; i++) {
    const operacao = operacoes[i];
  
    if (!corretores[operacao.codigoassessor]) {
      corretores[operacao.codigoassessor] = {
        codigo_assessor: operacao.codigoassessor,
        ajuste:0,
        renda_fixa: {
           receita_bruta: 0,
          receita_liquida: 0,
          comissao_escritorio: 0,
        },
        renda_variavel: {
          receita_bruta: 0,
          receita_liquida: 0,
          comissao_escritorio: 0,
        },
        impostos:0
      };
    }
  
    const corretor = corretores[operacao.codigoassessor];
  
    if (
      operacao.categoria.toUpperCase() === "BM&F" ||
      operacao.categoria.toUpperCase() === "BMF MINI" ||
      operacao.categoria.toUpperCase() === "BMF SELF SERVICE" ||
      operacao.categoria.toUpperCase() === "BOVESPA" ||
      operacao.categoria.toUpperCase() === "BOVESPA SELF SERVICE" ||
      operacao.categoria.toUpperCase() === "BOVESPA SELF"
      ) {
        corretor.renda_variavel.receita_bruta += parseFloat(operacao.receitabruta.replace(".", "").replace(",", "."));
        corretor.renda_variavel.receita_liquida += parseFloat(operacao.receitaliquida.replace(".", "").replace(",", "."));
        corretor.renda_variavel.comissao_escritorio += parseFloat(operacao.comissaoescritorio.replace(".", "").replace(",", "."));
    } else {
      corretor.renda_fixa.receita_bruta += parseFloat(operacao.receitabruta.replace(".", "").replace(",", "."));
      corretor.renda_fixa.receita_liquida += parseFloat(operacao.receitaliquida.replace(".", "").replace(",", "."));
      corretor.renda_fixa.comissao_escritorio += parseFloat(operacao.comissaoescritorio.replace(".", "").replace(",", "."));
    };

    if(operacao.classificacao.toLowerCase() === "ajustes"){
      corretor.ajuste += parseFloat(operacao.comissaoescritorio.replace(".", "").replace(",", "."));
    }



    // Calcula o Imposto
        
    const impostoCnpj1 = 16.33 / 100;
    const impostoCnpj2 = 6 / 100;


    

    if (
      operacao.categoria.toUpperCase() !== "CO-CORRETAGEM" &&
      operacao.categoria.toUpperCase() !== "PREVIDÊNCIA" &&
      operacao.categoria.toUpperCase() !== "MERCADO INTERNACIONAL" &&
      operacao.categoria.toUpperCase() !== "CRÉDITO COLATERALIZADO" &&
      operacao.categoria.toUpperCase() !== "MOEDAS DIGITAIS"
  
    ) {
  
      // É PJ1
      const comissao = parseFloat(operacao.comissaoescritorio.replace(".", "").replace(",", "."));
      corretor.impostos  += (impostoCnpj1 * comissao) ;
     
  
    } else {
      // É PJ2
      const comissao = parseFloat(operacao.comissaoescritorio.replace(".", "").replace(",", "."));
      corretor.impostos  += parseFloat((impostoCnpj2 * comissao).toFixed(2));
    }
    

  };
  
  const corretoresArray = Object.values(corretores);

  const result = await calculosAssessores(corretoresArray, netAssessores);

  // Obter a data da primeira operação
  const data_obj = operacoes[0].data.split('/');
  const data = `${data_obj[2]}-${data_obj[1]}-01`

  // Retornar o resultado com a data
  return {
    data: data,
    result
  };
}
  