
import React, { useEffect, useState } from 'react';
import { createFromIconfontCN } from '@ant-design/icons';
import { Link, useNavigate } from "react-router-dom";
import { format } from 'date-fns';
import {
  DesktopOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
  ContainerOutlined
} from '@ant-design/icons';

import { Breadcrumb, Layout, Menu, Tooltip } from 'antd';
import  logo  from "../../assets/logo.png";
import simbolo from '../../assets/simbolo-atlanta.png'
import tokenDecode from '../../lib/token-decode';
import { getGreetings } from '../../lib/helpers/get-greetings';
import './myLayout.css';

const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js',
});



const { Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  getItem((<Link to="/dashboard">Dashboard</Link>), '1', <PieChartOutlined />),
  getItem( (<Link to="/relatorio">Enviar Relatório</Link>), '2', <DesktopOutlined />),
  getItem('Assessores', 'sub1', <TeamOutlined />, [
    getItem(<Link to="/assessores/cadastrar">Cadastrar</Link>, '3'),
    getItem(<Link to="/assessores/relatorio">Enviar relatório</Link>, '4'),
    
  ]),
  getItem('Administrador', 'sub2', <UserOutlined />, [
    getItem(<Link to="/administrador/cadastrar">Cadastrar</Link>, '6'),
    getItem(<Link to="/administrador/minha_conta">Minha conta</Link>, '7'),
    
  ]),
  getItem((<Link to="/despesas">Despesas</Link>), '8', <ContainerOutlined />),
  
  
];



export default function MyLayout ({ component, userLocation, indexNavSide }) {
    const [collapsed, setCollapsed] = useState(false);
    const [userName, setUserName] = useState('');
    const navigate = useNavigate();
    

    function logout () {
      localStorage.clear();
      navigate("/",{replace:true});

    };

    useEffect(()=> {
      const payload = tokenDecode();
      if(payload?.error){
        setUserName('')

      }
      setUserName(payload.nome)

    },[]);



    return (
      <Layout
        style={{
          minHeight: '100vh',
        }}
      >
        <Sider 
          collapsible 
          collapsed={collapsed} 
          onCollapse={(value) => setCollapsed(value)}
          onChange={event=> console.log(event)}
          
        >
          <div className={collapsed ? '--Disable': 'logo'}  >
            <img 
              src={logo} 
              alt="Logo_Atlanta_Invest.png" 
              className='MyLayout__logo'
            />
          </div>
          <div className={collapsed ? 'logo' :'--Disable'} >
            <img 
                src={simbolo} 
                alt="Logo_Atlanta_Invest.png" 
                className='MyLayout__simboloLogo'
              />

          </div>
          <Menu theme="dark" defaultSelectedKeys={indexNavSide} mode="inline" items={items} />
        </Sider>
        <Layout className="site-layout">

          <div className='MyLayout__header'>
            <h2>{getGreetings(userName.toLocaleUpperCase())}</h2>
            <Tooltip placement="bottom" title={'Sair'}>
              <IconFont type="icon-tuichu" 
                className='MyLayout__logout'
                onClick={logout}
              />
          
            </Tooltip>

          </div>
          <Content
          
            style={{
              margin: '0 16px',
            }}
          >
            <Breadcrumb
              style={{
                margin: '16px 0',
              }}
            >
              <Breadcrumb.Item>Atlanta Invest</Breadcrumb.Item>
              <Breadcrumb.Item>{userLocation}</Breadcrumb.Item>
            </Breadcrumb>
            <div
              className="site-layout-background"
              style={{
                padding: 15,
                minHeight: 360,
              }}
            >
              {component}
            </div>
          </Content>
          <Footer
            style={{
              textAlign: 'center',
            }}
          >
            Atlanta Invest {format(new Date(), 'yyyy')} - Todos os direitos reservados
          </Footer>
        </Layout>
      </Layout>
    );
}