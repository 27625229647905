import { Routes, Route} from "react-router-dom";
import Login from "./pages/login/Login";
import InvestmentBroke from "./pages/investmentBroke/InvestmentBroke";
import AdmAccount from "./pages/admAccount/AdmAccount";
import { PrivateRoute } from "./pages/privateRoute";
import MyAccount from "./pages/myAccount/MyAccount";
import Record from "./pages/record/Record";
import DashBoard from "./pages/dashboard/DashBoard";
import Expenses from "./pages/expenses/Expenses";
import AssessorReport from "./pages/assessorReport/AssessorReport";
import NotFound from "./pages/notFound/NotFound";


function App() {
  return (
    <Routes>
        
        <Route path="/" 
          element={
            
            <Login />
            
          } 
        />

        <Route path="/assessores/cadastrar" 
          element={
            <PrivateRoute>
              <InvestmentBroke />
            </PrivateRoute>
            
          } 
        />

          <Route path="/assessores/relatorio" 
          element={
            <PrivateRoute>
              <AssessorReport />
            </PrivateRoute>
            
          } 
        />

        <Route path="/administrador/cadastrar" 
          element={
            <PrivateRoute>
              <AdmAccount />
            </PrivateRoute>
            
          } 
        />

        <Route path="/administrador/minha_conta" 
          element={
            <PrivateRoute>
              <MyAccount />
            </PrivateRoute>
            
          } 
        />
        <Route path="/relatorio" 
          element={
            <PrivateRoute>
              <Record />
            </PrivateRoute>
            
          } 
        />
         <Route path="/dashboard" 
          element={
            <PrivateRoute>
              <DashBoard />
            </PrivateRoute>
            
          } 
        />

        <Route path="/despesas" 
          element={
            <PrivateRoute>
              <Expenses />
            </PrivateRoute>
            
          } 
        />


        <Route path="/*" 
          element={
            <PrivateRoute>
              <NotFound />
            </PrivateRoute>
            
          } 
        />
        
      

    
     
      </Routes>
  );
}

export default App;
