import { Button, Result } from 'antd';
import './notFound.css';
import { useNavigate } from 'react-router-dom';



export default function NotFound () {
    const navigate = useNavigate()
    return (
        
        <div className='NotFound__container'>
            <Result
                status="404"
                title="404"
                subTitle="Desculpe, mas essa página não existe."
                extra={
                    <Button 
                        type="primary"
                        onClick={()=> navigate('/dashboard',{repalce:true})}
                    >
                        Voltar para Dashboard
                    </Button>
                }
            />
        
                
        </div>
           

    );
}