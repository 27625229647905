export default function tableFormat (data) {
    
    for (let i = 0; i < data.length; i++) {
        data[i].key = data[i].id
        
        // Formata a data para dd/mm/yyyy
        const date = new Date(data[i].data);
        
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        data[i].data = `${day}/${month}/${year}`;
        data[i].data = date.toLocaleDateString('pt-BR', { timeZone: 'UTC' });

        if(data[i].data_pagamento){
            const date2 = new Date(data[i].data_pagamento);
        
            const day2 = date2.getDate().toString().padStart(2, '0');
            const month2 = (date2.getMonth() + 1).toString().padStart(2, '0');
            const year2 = date2.getFullYear().toString();
            data[i].data_pagamento = `${day2}/${month2}/${year2}`;
            data[i].data_pagamento = date2.toLocaleDateString('pt-BR', { timeZone: 'UTC' });
        }

        // Converte para número
        data[i].valor = parseFloat(data[i].valor);
         
        // Converte para número
        data[i].valor_pago = parseFloat(data[i].juros_multa_pagamento) + data[i].valor;


        data[i].valor_pago = data[i].valor_pago.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        data[i].valor = data[i].valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        

    };

   

    return data
}