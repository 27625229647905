export default async function calcularAreaMaisProdutiva(arr) {
  const resultado_categoria = {};

  arr.forEach((item) => {

    if (parseFloat(item.receitabruta) === 0) {
      return; // ignora a categoria se a receita bruta for zero
    }

    // Verifica se a categoria contém "CO-CORRETAGEM"
    let categoria = item.categoria.toUpperCase();
    if (categoria.includes("CO-CORRETAGEM")) {
      categoria = "CO-CORRETAGEM";
      
    }

    // Verifica se a categoria já existe no objeto resultado_categoria
    if (!resultado_categoria[categoria]) {
      resultado_categoria[categoria] = { receitabruta: 0 };
    }

    // Soma a receita bruta para a categoria
    resultado_categoria[categoria].receitabruta += parseFloat(item.receitabruta);
  });

  return {
    resultado_categoria,
  };
}
