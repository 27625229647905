import './record.css';
import MyLayout from "../../components/myLayout/MyLayout";
import PageRecord from './pageRecord/PageRecord';



export default function Record (){
    return (
        <>
            < MyLayout
                component={
                    <PageRecord />

                }
                userLocation={'Enviar Relatório'}
                indexNavSide={['2']}

            
            />
        </>

    );
}