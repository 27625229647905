export default async function getExpenses(data) {
    
    let token = localStorage.getItem('token');
    token = JSON.parse(token);
    try {
        const response = await fetch(`https://dashboard.atlantainvest.com.br/api/expenses/get-expenses.php`,{
            method:'post',
            body: JSON.stringify ({
               token,
               data:data

            })
        });
        

        const res = await response.json();
        return res;
        
    } catch (error) {
        return {
            error:true,
            mensage:error
        };
        
        
    };
    
       

};