export default async function verificarMesEAnoIguais(array) {
  if (array.length === 0) {
    return true; // se o array estiver vazio, consideramos que todos os objetos têm a mesma data
  };
  const [mes, ano] = array[0].data.split('/').slice(1); // extrai mês e ano do primeiro objeto
 
  for (let i = 1; i < array.length; i++) {
    const [objetoMes, objetoAno] = array[i].data.split('/').slice(1); // extrai mês e ano do objeto atual
    
    if (objetoMes !== mes || objetoAno !== ano) {
      return false; // se a data e o mês de um objeto forem diferentes do primeiro objeto, retornamos falso imediatamente
    };
  };
  return {
    success:true,
    data:`${ano}-${mes}`
    
  }; // se todos os objetos tiverem a mesma data e mês, retornamos verdadeiro
};