export default async function checkCdgAssessor(data) {
    let token = localStorage.getItem('token');
    token = JSON.parse(token);

    try {
        const response = await fetch(`https://dashboard.atlantainvest.com.br/api/corretores/check-cdgAssessor.php`,{
            method:'post',
            body: JSON.stringify ({
                codigo_assessor:data,
                token:token
           
            })
        });

        const res = await response.json();
        return res;
        
        
    } catch (error) {
        return {
            error:true,
            mensage:error
        };
        
        
    };
       

};