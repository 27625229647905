import calcularAreaMaisProdutiva from "./area_result";

export default async function corretoraResult(arrayOperacoes, net, comissao_total_assessores, ajuste_assessores, despesas) { 
    comissao_total_assessores = parseFloat(comissao_total_assessores);
    
    if(!arrayOperacoes || !net ){
        throw new Error ('Error, Você precisa informar todos os dados para calcular o resultado!')
    }

    if (typeof net === 'string') {
        net = net.replace(/[^\d,]/g, ''); 
        net = net.replace('.', '').replace(',', '.'); 
        net = parseFloat(net); 
    }

    //Calcula a Receita Liquida
    const receitaLiquida = arrayOperacoes.reduce((accumulator, currentValue) => {
        currentValue.receitaliquida = currentValue.receitaliquida.replace(/[^\d,]/g, '');  
        currentValue.receitaliquida= currentValue.receitaliquida.replace('.', '').replace(',', '.'); 
        return accumulator + parseFloat(currentValue.receitaliquida);
    }, 0);

    //Calcula a Receita Bruta
    const receitaBruta = arrayOperacoes.reduce((accumulator, currentValue) => {
        currentValue.receitabruta = currentValue.receitabruta.replace(/[^\d,]/g, '');  
        currentValue.receitabruta= currentValue.receitabruta.replace('.', '').replace(',', '.'); 
        return accumulator + parseFloat(currentValue.receitabruta);
    }, 0);

    //Calcula a comissão total do escritório que é Receita + Ajustes
    let comissaoEscritorio = arrayOperacoes.reduce((accumulator, currentValue) => {         
        const comissaoNumerica = parseFloat(currentValue.comissaoescritorio.replace('.', '').replace(',', '.')); 
        return accumulator + comissaoNumerica
    }, 0);
    
    const ajusteTotal = arrayOperacoes.reduce((accumulator, currentValue) => { 
        if (currentValue.classificacao.toLowerCase() === "ajustes") { 
            const ajusteNumerica = parseFloat(currentValue.comissaoescritorio.replace('.', '').replace(',', '.'));
            return accumulator + ajusteNumerica;
        } else {
            return accumulator;
        }
    }, 0);

    //Calcula o ajuste da corretora (após deduzir os ajustes dos assessores) BRUTO ANTES DO IMPOSTO
    let ajuste_corretora = ajusteTotal - ajuste_assessores;

    //Calcula o ROA
    const roa = (receitaBruta*12) / net;

    // Calcula o Imposto
        
    const impostoCnpj1 = 16.33 / 100;
    const impostoCnpj2 = 6 / 100;

    let totalCnpj1 = 0;
    let totalCnpj2 = 0;

    for (let i = 0; i < arrayOperacoes.length; i++) {
        let categoria = arrayOperacoes[i].categoria
       
    
        if (
            !categoria.toUpperCase().includes("CO-CORRETAGEM") &&
            !categoria.toUpperCase().includes("PREVIDÊNCIA") &&
            !categoria.toUpperCase().includes("MERCADO INTERNACIONAL") &&
            !categoria.toUpperCase().includes("CRÉDITO COLATERALIZADO") &&
            !categoria.toUpperCase().includes("MOEDAS DIGITAIS")
        ) {
        
            // É PJ1
            const comissao = parseFloat(arrayOperacoes[i].comissaoescritorio.replace('.', '').replace(',', '.'));
            totalCnpj1 += (comissao * impostoCnpj1);
        
        } else {
            // É PJ2
            const comissao = parseFloat(arrayOperacoes[i].comissaoescritorio.replace(".", "").replace(',', '.'));
            totalCnpj2 += (comissao * impostoCnpj2) ;
            
        }
    }

    const resultado = {
        totalCnpj1: parseFloat(totalCnpj1.toFixed(2)),
        totalCnpj2: parseFloat(totalCnpj2.toFixed(2)),

    };



    // 

    // Calcula Lucro após o impostos
    const lucroAp = comissaoEscritorio - (resultado.totalCnpj1 + resultado.totalCnpj2);
   

    //Calcula o Resultado por aréa
    const resultado_area = await calcularAreaMaisProdutiva(arrayOperacoes);
    
    //Calcula o lucro , é o lucro após os impostos - as comissoes pagas + o ajuste da corretora
    let LucroLiquido = lucroAp - comissao_total_assessores;
    LucroLiquido = (LucroLiquido + ajuste_corretora) - (despesas.custo + despesas.despesa);
    

    let receitaCnpj1 = 0;
    let receitaCnpj2 = 0;

    for (let i = 0; i < arrayOperacoes.length; i++) {
        let categoria = arrayOperacoes[i].categoria

        if (
            !categoria.toUpperCase().includes("CO-CORRETAGEM") &&
            !categoria.toUpperCase().includes("PREVIDÊNCIA") &&
            !categoria.toUpperCase().includes("MERCADO INTERNACIONAL") &&
            !categoria.toUpperCase().includes("CRÉDITO COLATERALIZADO") &&
            !categoria.toUpperCase().includes("MOEDAS DIGITAIS")
          ) {
        
            // É PJ1
            const comissao = parseFloat(arrayOperacoes[i].comissaoescritorio.replace('.', '').replace(',', '.'));
            receitaCnpj1 += comissao ;
        
        } else {
            // É PJ2
            const comissao = parseFloat(arrayOperacoes[i].comissaoescritorio.replace('.', '').replace(',', '.'));
            receitaCnpj2 += comissao;
        }
    }

    const resultadoREceita = {
        receitaCnpj1: parseFloat(receitaCnpj1.toFixed(2)),
        receitaCnpj2: parseFloat(receitaCnpj2.toFixed(2)),

    };
 
    // Converte a data para YYYY/MM/01
    const data_obj = arrayOperacoes[0].data.split('/');
    const data = `${data_obj[2]}-${data_obj[1]}-01`

    return { 
        categoria:resultado_area.resultado_categoria,
        resultado_corretora: {
            receita_liquida:receitaLiquida,
            receita_bruta:receitaBruta,
            comissao_escritorio:comissaoEscritorio,
            net:net,
            lucro_apos_ir:lucroAp,
            comissao_pg:comissao_total_assessores,
            lucro_liquido:LucroLiquido,
            roa_mensal:roa,
            ajustes_corretora:ajuste_corretora,
            data:data,
            resultado_bruto_pj1:resultadoREceita.receitaCnpj1,
            resultado_bruto_pj2:resultadoREceita.receitaCnpj2,
            despesas:despesas.despesa,
            custos:despesas.custo,
            imposto_pj1:resultado.totalCnpj1,
            imposto_pj2:resultado.totalCnpj2,
         

        },
        
    };
};
