export default async function getOperations(data) {
    let token = localStorage.getItem('token');
    token = JSON.parse(token);
  
    try {
        const response = await fetch(`https://dashboard.atlantainvest.com.br/api/operation/get-operations.php`,{
            method:'post',
            body: JSON.stringify ({
                data:data.data,
                codigo_assessor:data.assessor,
                token:token
           
            })
        });
  
      const res = await response.json();
    
        return res;
   
    } catch (error) {
        return {
            error:true,
            mensage:error
        };
           
    };      
    
};