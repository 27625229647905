
export default async function saveResultCorretora(data) {
    let token = localStorage.getItem('token');
    token = JSON.parse(token);
    
    try {
        const response = await fetch(`https://dashboard.atlantainvest.com.br/api/result/result-corretora.php`,{
            method:'post',
            body: JSON.stringify ({
                resultado_corretora:data.resultado_corretora,
                categoria:data.categoria,
                token:token
           
            })
        });

        const res = await response.json();
        console.log(res)
        return res;
        
    } catch (error) {
        return {
            error:true,
            mensage:error
        };
    };
};