import { useEffect, useState } from "react";
import deleteCorretor from "../../actions/corretores/delete-corretores";
import getAll from "../../actions/corretores/getAll-corretores";
import suspendCorretor from "../../actions/corretores/suspend-corretor";
import MyLayout from "../../components/myLayout/MyLayout";
import UserConfig from "../../components/userConfig/UserConfig";



export default function InvestmentBroke () {
    const [data, setData] = useState([]);

    useEffect(()=> {
        async function getAllCorretores() {
            const res = await getAll();
            if(Object.values(res).length > 0) {
                const filterName = [];
                const filterEmail = [];
                const newData =  Object.values(res).map(callback => {
                    
                    if(callback.tipo === 'socio') {
                        callback.tipo = 'Sócio';
                    }else if(callback.tipo === 'associado') {
                        callback.tipo = 'Associado';

                    };

                    filterName.push({
                        text:callback.nome,
                        value:callback.nome,
            
                    })
                    filterEmail.push({
                        text:callback.email,
                        value:callback.email,
            
                    })

                    return {
                        
                        key:callback.id,
                        codigo_assessor:callback.codigo_assessor,
                        nome:callback.nome,
                        email:callback.email,
                        status:[callback.status],
                        tipo:[callback.tipo]
    

                    }
                   
                })
                setData({
                    columns:newData,
                    filterName:filterName,
                    filterEmail:filterEmail
                });

            }
            
            
        }

        getAllCorretores();

    },[])

    
    return (
        <div>
            <MyLayout 
                component={ 
                    <UserConfig
                        dataUser={data}
                        clickDelet={deleteCorretor}
                        clickSuspend={suspendCorretor}
                        

                    /> 
                }
                userLocation={'Assessores / Cadastrar'}
                indexNavSide={['3']}
            
            />
        </div>
    )

}