import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';
import './loading.css';
const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  )

export default function Loading ({text}) {
    return (
        <div className='Loading__container'>
            <Spin 
              indicator={antIcon} 
              tip={text}
              className='Loading__content'
            />

        </div>
        
        
    );
};