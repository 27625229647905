

export default async function UpdateCorretor(data) {
    let token = localStorage.getItem('token');
    token = JSON.parse(token);   
    try {
        const response = await fetch(`https://dashboard.atlantainvest.com.br/api/corretores/update-corretor.php`,{
            method:'post',
            body: JSON.stringify ({
                id:data.id,
                nome:data.nome,
                codigo_assessor:data.codigo_assessor,
                email:data.email,
                tipo:data.tipo,
                token:token              
            })
        })

        const res = await response.json();
        return res;
        
    } catch (error) {
        return {
            error:true,
            mensage:error
        }
        
        
    }
    
       

}