
export default async function saveResultAssessores(data) {
    let token = localStorage.getItem('token');
    token = JSON.parse(token);  
    
    try {
        const response = await fetch(`https://dashboard.atlantainvest.com.br/api/result/resultado-assessores.php`,{
            method:'post',
            body: JSON.stringify ({
                resultado_assessores:data.resultado_assessores,
                data:data.data,
                token:token
           
            })
        });

        const res = await response.json();
        return res;
        
    } catch (error) {
        return {
            error:true,
            mensage:error
        };
    };
};