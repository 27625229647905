export default async function resetPassWord(data) {

    try {
        const response = await fetch(`https://dashboard.atlantainvest.com.br/api/public/verify-token-password.php`,{
            method:'post',
            body: JSON.stringify ({
                codepass:data.codepass,
                senha:data.senha
                     
            })
        });
  
      const res = await response.json(); 
        return res;
          
    } catch (error) {
        return {
            error:true,
            mensage:error
        };
        
        
    };
       
    
  };