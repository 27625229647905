import MyLayout from "../../components/myLayout/MyLayout";
import ExpensesConfig from "./expensesConfig/ExpensesConfig";

export default function Expenses () {
    return (
        <>
        <MyLayout
            userLocation={'Despesas'}
            indexNavSide={['8']}
            component={
                <ExpensesConfig />
                
            }
        />
        </>

    );
}