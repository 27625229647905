import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import getResultCorretora from "../../actions/result/getAll-corretora";
import MyLayout from "../../components/myLayout/MyLayout";
import "./dashboard.css"
import DashConfig from "./dashConfig/DashCofig";




export default function DashBoard(){
    const[resultCorretora, setResultCorretora] = useState([]);
    const[dataCorretora, setDataCorretora] = useState([]);
    const[anoAtual, setAnoAtual] = useState([]);
    const[mesAtual, setMesAtual] = useState([]);
    const location = useLocation();


    useEffect(()=>{
 
        async function getAllCorretoraResult(){
            
            try {
                const res = await getResultCorretora();
              
            
                if(res?.error){
                    return;
                }
                
                if(Object.keys(res).length > 0){
                    // percorre o objeto de objetos para encontrar a data mais recente
                    let ano = 0;
                    let mes = 0;
                    for (const [key, value] of Object.entries(res)) {
                        const dateParts = value.data.split('-');
                        const currentAno = dateParts[0]
                        const currentMes = dateParts[1]
                        

                        if (currentAno > ano || (currentAno === ano && currentMes > mes)) {
                            ano = currentAno;
                            mes = currentMes;
                        }
                    }
                    setAnoAtual(ano);
                    setMesAtual(mes);
                    setResultCorretora(res);
                                       
                    // Separa os meses e ano para cada resultado
                    const result = {};
                    for (const key of Object.keys(res)) {
                      const date = res[key].data;
                      const year = date.slice(0, 4);
                      const month = date.slice(5, 7);
                    
                      if (!result[year]) {
                        result[year] = [];
                      }
                    
                      if (!result[year].includes(month)) {
                        result[year].push(month);
                      }
                    }
                    
                    // Ordena as chaves do objeto pelo ano mais recente
                    const orderedKeys = Object.keys(result).sort((a, b) => Number(b) - Number(a));
                    
                    // Cria um novo objeto com as propriedades e valores organizados da forma desejada
                    const orderedResult = {};
                    for (const key of orderedKeys) {
                      orderedResult[key] = result[key];
                    }
                    setDataCorretora(orderedResult)              

                }else{

                    

                }
                
            } catch (error) {
                console.log(error)   
            }           
        }

        if(location.pathname === '/dashboard'){
            getAllCorretoraResult();
        };
        
    },[location.pathname])

    
    return(
        
        <div>
            <MyLayout
                component={
                    <DashConfig
                        anoAtual={anoAtual}
                        setAnoAtual={setAnoAtual}
                        mesAtual={mesAtual}
                        setMesAtual={setMesAtual}
                        dataCorretoraResult={dataCorretora}
                        resultCorretora={resultCorretora}

                    />
                }
                indexNavSide={['1']}
                userLocation={'Dashboard'}
            
            
            />  

        </div>
             
    );

}