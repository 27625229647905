export default async function calcularAreaAssessor(arr) {
  const resultado_categoria = {};

  arr.forEach((item) => {
    const categoriaUpper = item.categoria.toUpperCase();

    if (parseFloat(item.receita_bruta) === 0) {
      return; // ignora a categoria se a receita bruta for zero
    }

    // Verifica se a categoria já existe no objeto resultado_categoria
    if (categoriaUpper.includes("FUNDOS TX ADM") || categoriaUpper.includes("FUNDOS TX PERF")) {
      if (!resultado_categoria["fundos"]) {
        resultado_categoria["fundos"] = { receita_bruta: 0 };
      }

      // Soma a receita bruta para a categoria
      resultado_categoria["fundos"].receita_bruta += parseFloat(item.receita_bruta);
    }

    if (categoriaUpper.includes("INDICAO DE CLIENTES")) {
      if (!resultado_categoria["produtos estruturados"]) {
        resultado_categoria["produtos estruturados"] = { receita_bruta: 0 };
      }
      // Soma a receita bruta para a categoria
      resultado_categoria["produtos estruturados"].receita_bruta += parseFloat(item.receita_bruta);
    }

    if (!resultado_categoria[item.categoria] && categoriaUpper.includes("OFERTA FII")) {
      if (!resultado_categoria["ipo fii"]) {
        resultado_categoria["ipo fii"] = { receita_bruta: 0 };
      }
      // Soma a receita bruta para a categoria
      resultado_categoria["ipo fii"].receita_bruta += parseFloat(item.receita_bruta);
    }

    if (!resultado_categoria[item.categoria] && categoriaUpper.includes("OFERTA AÇÕES")) {
      if (!resultado_categoria["ipo acoes"]) {
        resultado_categoria["ipo acoes"] = { receita_bruta: 0 };
      }
      // Soma a receita bruta para a categoria
      resultado_categoria["ipo acoes"].receita_bruta += parseFloat(item.receita_bruta);
    }

    if (
      !categoriaUpper.includes("FUNDOS TX ADM") &&
      !categoriaUpper.includes("FUNDOS TX PERF") &&
      !categoriaUpper.includes("OFERTA AÇÕES") &&
      !categoriaUpper.includes("OFERTA FII") &&
      !categoriaUpper.includes("INDICAO DE CLIENTES")
    ) {
      if (!resultado_categoria[item.categoria]) {
        resultado_categoria[item.categoria] = { receita_bruta: 0 };
      }
      resultado_categoria[item.categoria].receita_bruta += parseFloat(item.receita_bruta);
    }
  });

  return {
    resultado_categoria,
  };
}
