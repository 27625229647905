export default function verifyForm (form) {

    for (const property in form) {
        if (form[property] === '' && property !== 'jurosMulta' && property !== 'valor_pago') {
          return {
            error:true,
            msg:'Preencha todos os campos para prosseguir!'
          }
        }; 
    };

    if(typeof form?.valor !== 'number' && form?.valor){
      form.valor = parseFloat(form.valor.replace(/[^\d,]/g, '').replace(',', '.'));  
      form.valor_pago = form.valor    
    };

    if(form?.data){
      const partesData = form.data.split('/');
      const dia = parseInt(partesData[0], 10);
      const mes = parseInt(partesData[1], 10);
      const ano = parseInt(partesData[2], 10);
  
      if (dia < 1 || dia > 31) {
        return {
          error:true,
          msg:'Digite uma data válida para prosseguir!'
        }
      }
      if (mes < 1 || mes > 12) {  
        return {
          error:true,
          msg:'Digite uma data válida para prosseguir!'
        }
      }
      if (ano < 1900 || ano > 2100) {
        return {
          error:true,
          msg:'Digite uma data válida para prosseguir!'
        }
      }
  
      if (form.data.indexOf('-') === -1) {
          form.data = form.data.split('/');
          form.data = `${form.data[2]}-${form.data[1]}-${form.data[0]}`;
      };
    }

    if(form?.dataPagamento){
      const partesData = form.dataPagamento.split('/');
      const dia = parseInt(partesData[0], 10);
      const mes = parseInt(partesData[1], 10);
      const ano = parseInt(partesData[2], 10);

      if (dia < 1 || dia > 31) {
        return {
          error:true,
          msg:'Digite uma data válida para prosseguir!'
        }
      }
      if (mes < 1 || mes > 12) {  
        return {
          error:true,
          msg:'Digite uma data válida para prosseguir!'
        }
      }
      if (ano < 1900 || ano > 2100) {
        return {
          error:true,
          msg:'Digite uma data válida para prosseguir!'
        }
      }
  
      if (form?.dataPagamento) {
        form.dataPagamento = form.dataPagamento.split('/');
        form.dataPagamento = `${form.dataPagamento[2]}-${form.dataPagamento[1]}-${form.dataPagamento[0]}`;
      };
  

    }

  if(form?.jurosMulta){
      form.jurosMulta = parseFloat(form.jurosMulta.replace(/[^\d,]/g, '').replace(',', '.'));      
    };

    if(form?.valor && form?.jurosMulta){
      form.valor_pago = form.valor_pago + form.jurosMulta 
        
    };

};