import { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { Card, Col, Row, Statistic, Select, Tabs ,Collapse, Divider, Tooltip, Table, Button, Empty  } from 'antd';
import { FileDoneOutlined } from '@ant-design/icons';
import getArea from "../../../actions/result/get-area";
import Loading from "../../../components/loading/Loading";
import getAssessoresResult from "../../../actions/result/getAll-assessores";
import getAll from "../../../actions/corretores/getAll-corretores";
import exportData from "../../../use-cases/corretora/exportPlanilha/converteData";
import converteDre from "../../../use-cases/financeiro/converteDre";
import { useNavigate } from "react-router-dom";
import converteCustos from "../../../use-cases/financeiro/converteGraficoCustos";
import './dashconfig.css';
import getByDataOperations from "../../../actions/operations/get-bydata-operations";
import assessoresOperacoes from "../../../use-cases/assessor/area_chartpie";

const { Panel } = Collapse;

const onChange = () => {
    // Adicione este trecho para forçar a atualização dos gráficos
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 0);
};
  
export const optionspie = {
    legend: "true",
    chartArea: { left: 15, top: 15, right: 0, bottom: 0 },
    pieSliceText: "value: %.2f",
    is3D: true,
    tooltip: {isHtml: true}

};

export const options2 = {
    title: 'ROA ANUAL',
    curveType: 'function',
    legend: { position: 'bottom' },
    hAxis: {title: 'Período'},
    chartArea: {width: '80%', height: '70%'},
    tooltip: {format: '#.#\\%', isHtml: true},
    pointSize: 10,
    colors: ['#3366cc'],
    baselineColor: '#ddd'

};

export const optionsBar = {
    // Outras opções aqui...
    tooltip: {
        isHtml: true,
        text: 'value',
        trigger: 'both', 
    },
    vAxis: {
        title: 'ROA',
    }
};

export const optionsPJ = {
    allowHtml: true,
    showRowNumber: true,
    width: '100%',
    height: '100%',
    fontSize: 18,
    chartArea: {
      width: '80%',
      height: '80%'
    },
    legend: {
        position: "left",
    },
    chart: {
        title: "Gráfico Anual PJ",
        subtitle: "Resultado PJ1 e PJ2",
    },
    titleTextStyle: {
        fontSize: 18,
        bold: true,
        italic: false,
    },
    tooltip: {
    isHtml: true,
    text: 'value',
    },

    
};

export const optionsDespsas = {
    allowHtml: true,
    showRowNumber: true,
    width: '100%',
    height: '100%',
    fontSize: 18,
    chartArea: {
      width: '80%',
      height: '80%'
    },
    legend: {
        position: "left",
    },
    chart: {
        title: "Gráfico Anual",
        subtitle: "Custos x Despesas",
    },
    titleTextStyle: {
        fontSize: 18,
        bold: true,
        italic: false,
    },
    tooltip: {
    isHtml: true,
    text: 'value',
    },

    
};

export const optionsAssessoresArea = {
    title: "Resultado por Produto",
    subtitle: "Resultado por área",
    width: "100%",
    height: "100%",
    fontSize: 18,
    chartArea: {
      width: "70%",
      height: "80%", // Deixe algum espaço para o título e legenda
    },
    legend: {
      position: "rigth",
      maxLines: 3,
      textStyle: {
        fontSize: 12.5, // Ajuste o tamanho da fonte da legenda
      },
    },
    isStacked: true,
    bar: { groupWidth: "75%" }, // Ajuste a largura das barras
    hAxis: {
      minValue: 0,
    },
    vAxis: {
      title: "Assessores",
      
    },
    titleTextStyle: {
      fontSize: 16,
      bold: true,
      italic: false,
    },
    tooltip: {
      isHtml: true,
      text: "value",
    },
};


  

export default function DashConfig({anoAtual, setAnoAtual, mesAtual, setMesAtual, dataCorretoraResult, resultCorretora}){
    const [resultAreaTotal, setResultAreaTotal] = useState([]);
    const [resultAreaMensal, setResultAreaMensal] = useState([]);
    const [roaAnual, setRoaAnual] = useState([]);
    const [resultadoPj, setResultadoPj] = useState([]);
    const [indicadoresCorretoraMensal, setIndicadoresCorretoraMensal] = useState([]);
    const [corretoraTableAnual, setCorretoraTablAnual] = useState([]);
    const [loading, setLoading] = useState(true);
    const [resultAssessores, setResultAssessores] = useState([]);
    const [resultAssessores2, setResultAssessores2] = useState([]);
    const [resultAssessoresTable, setResultAssessoresTable] = useState([]);
    const [roaAssessores, setRoaAssessores] = useState([]);
    const [chartKey, setChartKey] = useState(0);
    const [exportPieMes, setExportPieMes] = useState(false);
    const [exportPieAno, setExportPieAno] = useState(false);
    const [exportTable, setExportTable] = useState(false);
    const [exportPj, setExportPj] = useState(false);
    const [exportRoa, setExportRoa] = useState(false);
    const [exportAssessorPiesMes, setExportAssessorPiesMes] = useState(false);
    const [exportAssessorPiesAnual, setExportAssessorPiesAnual] = useState(false);
    const [exportAssessorRoa, setExportAssessorRoa] = useState(false);
    const [exportAssessorTable, setExportAssessorTable] = useState(false);
    const [resultDre, setResultDre] = useState(false);
    const [graficoCustos, setGraficoCustos] = useState([]);
    const [exportCustos, setExportCustos] = useState(false);
    const [assessoresArea, setAssessoresArea] = useState(false);
    const [exportAssessoresArea, setExportAssessoresArea] = useState(false);
    const navigate = useNavigate();
   


    const filterByYear = (data, year) => {
        // Converte o objeto em um array de objetos
        const dataArray = Object.values(data);

        // Filtra as datas que possuem o mesmo ano que o ano fornecido
        const filteredData = dataArray.filter(obj => obj.data.slice(0, 4) === year.toString());

        // Ordena os objetos filtrados por ordem crescente de data
        filteredData.sort((a, b) => new Date(a.data) - new Date(b.data));

        // Cria um novo array com os valores filtrados
        const result = filteredData.map(obj => {
        // Obtém o ano e o mês da data
        const year = obj.data.slice(0, 4);
        const month = obj.data.slice(5, 7);

        // Formata a data no formato "mm/yyyy"
        const date = new Date(year, month - 1).toLocaleDateString('pt-BR', { month: '2-digit', year: 'numeric' });

        // Retorna um array com a data formatada e o valor de roa_mensal
        return [date, parseFloat(obj.roa_mensal)];
  });

  // Insere o cabeçalho do array
  result.unshift(['Período', 'ROA']);

  return result;
    };

    function sumRevenueByYearMonth(data, yearMonth) {
    // Cria um objeto para armazenar as receitas
    const categoryMap = {};
  
    // Pecorre o objeto de entrada e procura os objetos que têm a mesma data
    Object.values(data).forEach((item) => {
      const itemYearMonth = item.data.substring(0, 7);
      if (itemYearMonth === yearMonth) {
        const category = item.categoria;
        const revenue = parseFloat(item.receita_bruta);
  
        if (!categoryMap[category]) {
          categoryMap[category] = revenue;
        } else {
          categoryMap[category] += revenue;
        }
      }
    });
  
    // Cria um array a partir do categoryMap
    const resultAreaTotalSum = Object.entries(categoryMap).map(([category, revenue]) => {
        if(['INDICAÇÃO DE CLIENTES'].includes(category.toUpperCase())){
            category = 'PRODUTOS ESTRUTURADOS'
        }
        if(['OFERTA FII'].includes(category.toUpperCase())){
            category = 'IPO - OFERTA FII'
        }
        if(['OFERTA AÇÕES'].includes(category.toUpperCase())){
            category = 'IPO - OFERTA AÇÕES'
        }
        let area;
        if(['BM&F', 'BTC', 'CLUBES','PRODUTOS ESTRUTURADOS' ,'IPO - OFERTA FII','IPO - OFERTA AÇÕES', 'BMF MINI', 'BMF SELF SERVICE', 'BOVESPA', 'BOVESPA SELF SERVICE', 'BOVESPA SELF'].includes(category)){
            area = 'Renda Variável';
        } else if(['FUNDOS - TX ADM', 'FUNDOS - TX PERF'].includes(category.toUpperCase())){
            area = 'Fundos';
        } else {
            area = 'Renda Fixa';
        }
      return [category, area, revenue];
    });
  
    // Insere o cabeçalho do array
    resultAreaTotalSum.unshift(['Categoria', 'Área', 'Faturamento']);
  
    return resultAreaTotalSum;
    };

    function filterDataByYearMonth(data, yearMonth) {
        
        // Percorre o objeto de entrada e retorna o objeto com a mesma data
        for (const key in data) {
          if (data[key].data.substring(0, 7) === yearMonth) {
            const newData = { ...data[key] };
            newData.receita_bruta = parseFloat(newData.receita_bruta.replace(',', '.')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
            newData.receita_liquida = parseFloat(newData.receita_liquida.replace(',', '.')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
            newData.comissao_escritorio = parseFloat(newData.comissao_escritorio.replace(',', '.')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
            newData.lucro_liquido = parseFloat(newData.lucro_liquido.replace(',', '.')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
            newData.comissao_pg = parseFloat(newData.comissao_pg.replace(',', '.')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
            
            return newData;
          }
        }
        // Caso não encontre a data, retorna null
        return null;
    };

    function verifyCorretorCadastrado(resultadoCorretores, corretoresCadastrados) {
        const corretoresArray = Object.values(corretoresCadastrados);
        
        return resultadoCorretores
        .filter((resultadoCorretor) => {
            const corretor = corretoresArray.find(
            (c) => c.codigo_assessor === resultadoCorretor.codigo_assessor
            );
            return corretor && corretor.status === "ativo";
        })
        .map((resultadoCorretor) => {
            const corretor = corretoresArray.find(
            (c) => c.codigo_assessor === resultadoCorretor.codigo_assessor
            );
            resultadoCorretor.nome = corretor ? corretor.nome : "";
            resultadoCorretor.tipo = corretor
            ? corretor.tipo === "associado"
                ? "Associado"
                : "Sócio"
            : "";
        return resultadoCorretor;
  });

    };

    function convertAssesores(data) {
        const result = [['Nome/Cód Assessor', 'Tipo', 'Faturamento']];
        data.map(obj => {
          result.push([
            `${obj.codigo_assessor}-${obj.nome}`,
            obj.tipo,
            parseFloat(obj.receita_bruta)
          ]);
          return null
        });
        return result;
    };

    function assesoresLucroCorretora(data) {
        const result = [['Nome/Cód Assessor', 'Tipo', 'Lucro Corretora']];
        data.map(obj => {
          result.push([
            `${obj.codigo_assessor}-${obj.nome}`,
            obj.tipo,
            parseFloat(obj.lucro_liquido_corretora)
          ]);
          return null
        });
        return result;
    };

    function convertAssesoresTable(data) {
        const result = [[
            'Data',
            'Cód Assessor - Nome',
            'Tipo',
            'Receita Bruta', 
            'Receita Líquida',
            'Comissão Escritório',
            'Comissão do Assessor',
            'Impostos pago pelo Assessor',
            'Lucro líquido para Corretora',
            'Net',
            'Roa'
        ]
        ];
        data.map(obj => {
           
            const data = obj.data.split('-');
            const dataFormatada = `${data[2]}/${data[1]}/${data[0]}`
           
            const roa = parseFloat(obj.roa_mensal);
            //const formattedRoa = roa.toFixed(2) + '%';
          result.push([
            dataFormatada,
            `${obj.codigo_assessor} - ${obj.nome}`,
            obj.tipo,
            parseFloat(obj.receita_bruta).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
            parseFloat(obj.receita_liquida).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
            parseFloat(obj.comissao_escritorio).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
            parseFloat(obj.comissao_assessor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
            parseFloat(obj.imposto).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
            parseFloat(obj.lucro_liquido_corretora).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
            parseFloat(obj.net).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
            obj.roa_mensal
          ]);
          return null
        });
        return result;
    };

    function assessoresRoa(data) {
        const result = [['Nome/Cód Assessor', 'ROA']];
      
        // Ordena os dados com base no valor do ROA (do menor para o maior)
        data.sort((a, b) => parseFloat(b.roa_mensal) - parseFloat(a.roa_mensal));
      
        data.map(obj => {
          result.push([
            obj.nome,
            parseFloat(obj.roa_mensal)
          ]);
          return null
        });
        
        return result;
    };

    function getResultAreaMesCorretore(data){
        const categoryMap = {};

        // percorre o array original e soma as receitas brutas de categorias iguais
        data.forEach((item) => {
            const category = item.categoria;
            const revenue = parseFloat(item.receita_bruta);
        
            if (!categoryMap[category]) {
                categoryMap[category] = revenue;
            } else {
                categoryMap[category] += revenue;
            }
        });
        
        // cria um novo array a partir do categoryMap
        const resultAreaTotalSum = Object.entries(categoryMap).map(([category, revenue]) => {
            if(['INDICAÇÃO DE CLIENTES'].includes(category.toUpperCase())){
                category = 'PRODUTOS ESTRUTURADOS'
            }
            if(['OFERTA FII'].includes(category.toUpperCase())){
                category = 'IPO - OFERTA FII'
            }
            if(['OFERTA AÇÕES'].includes(category.toUpperCase())){
                category = 'IPO - OFERTA AÇÕES'
            }
            let area;
            if(['BM&F', 'BTC', 'CLUBES','IPO - OFERTA FII','IPO - OFERTA AÇÕES','PRODUTOS ESTRUTURADOS' , 'BMF MINI', 'BMF SELF SERVICE', 'BOVESPA', 'BOVESPA SELF SERVICE', 'BOVESPA SELF'].includes(category)){
                area = 'Renda Variável';
            } else if(['FUNDOS - TX ADM', 'FUNDOS - TX PERF'].includes(category.toUpperCase())){
                area = 'Fundos';
            } else {
                area = 'Renda Fixa';
            }
        
            return [category, area, revenue];
        });
        

        // insere o cabeçalho do array
        resultAreaTotalSum.unshift(['Categoria', 'Área', 'Faturamento']);

        return resultAreaTotalSum
    };

    useEffect(()=>{
        if(resultCorretora.length === 0)  {
            return;
        } 
        setChartKey((prevKey) => prevKey + 1); // Incrementa a chave do gráfico
        
        async function getAreaResult(){
            setLoading(true);
            const res = await getArea(`${anoAtual}-${mesAtual}`)
                        
            if(res.length === 0){
                return
            };
            
            // Resultado Corretora Área Anual
            const resultAreaTotalSum = getResultAreaMesCorretore(res);           
            const exportPieAnual = await exportData(resultAreaTotalSum)
            
                    
            // Salva o resultado das operações total por área anual
            setResultAreaTotal(resultAreaTotalSum);
            // Salva dados para exportar anual
            setExportPieAno(exportPieAnual);

            //Salva o resultado das operações por mês Corretora
            const resultMes = sumRevenueByYearMonth(res,`${anoAtual}-${mesAtual}`);
            const exportPieMes = await exportData(resultMes)
            
            
           // Salva dados para exportar anual
            setExportPieMes(exportPieMes)
            // Salva o resultado das operações total por área mensal
            setResultAreaMensal(resultMes);


            //Estrutura os dados para o gráfico Roa Anual Corretora
            const result = filterByYear(resultCorretora, anoAtual);
            const exportRoa = await exportData(result)
            setRoaAnual(result);
            setExportRoa(exportRoa)

            //Resultado da corretora mensal para os indicadores 
            const ResultadoMensalCorretora = filterDataByYearMonth(resultCorretora,`${anoAtual}-${mesAtual}`);
            setIndicadoresCorretoraMensal(ResultadoMensalCorretora);

            // Salva resultado para table Anual corretora
            const tableCorretora = getDataByYear(resultCorretora, anoAtual);
            const exportTable = await exportData(tableCorretora);
            setCorretoraTablAnual(tableCorretora);  
            setExportTable(exportTable);
            
            
            // Dashboard Financeiro
           // Converte dados para o DRE
            const dreFormatado =  getDataByYearDre(resultCorretora, anoAtual);        
            const exportDre = await exportData(dreFormatado);        
            const dreData = await converteDre(exportDre);          
            setResultDre(dreData);
            
            // Converte Dados para Custos x Despesas
            const graficoCustosDespesas = converteCustos(dreData);
            setGraficoCustos(graficoCustosDespesas);
            
            //Converte os dados para planilha custos x despesas
            const exportCustos = await exportData(graficoCustosDespesas);
            setExportCustos(exportCustos);       
          
                  
            // Gráfico de barras comparando pj1 e pj2
            const graficoPJ = convertData(resultCorretora);
            const exportPj = await exportData(graficoPJ);
            setResultadoPj(graficoPJ);
            setExportPj(exportPj);
           
          
            // Resultado dos Assessores
           const allAssessoresResult = await getAssessoresResult(`${anoAtual}-${mesAtual}`);        
           const assessoresCadastrados = await getAll();
           
           //Verifica e salva o nome do corretor, faz referência pelo cod_assessor
           const assessoresResultado = await verifyCorretorCadastrado(allAssessoresResult, assessoresCadastrados);


           // Gráfico Assessores por área
           const operations = await getByDataOperations(`${anoAtual}-${mesAtual}`);
           const assessoresArea =  assessoresOperacoes(Object.values(assessoresCadastrados),operations);
           const exportAssessoresArea = await exportData(assessoresArea);
           setExportAssessoresArea(exportAssessoresArea);
           setAssessoresArea(assessoresArea);

           // Resultado Faturamento Assessores
           const resultAssessores = convertAssesores(assessoresResultado);
           const exportAssessoresMes = await exportData(resultAssessores);
           setResultAssessores(resultAssessores);
           setExportAssessorPiesMes(exportAssessoresMes);

           //Converte e salva resultado para o gráfico Lucro L. Corretora ( Na aba Assessores )
           const lucroAssessoresChartpie2 = assesoresLucroCorretora(assessoresResultado);
           const exportAssessoresAnual = await exportData(lucroAssessoresChartpie2);
           setResultAssessores2(lucroAssessoresChartpie2);
           setExportAssessorPiesAnual(exportAssessoresAnual);

           // Converte e salva o resultado para o gráfico da tabela
           const resultadoTable = convertAssesoresTable(assessoresResultado);
           const exportAssessoresTable = await exportData(resultadoTable);
           setExportAssessorTable(exportAssessoresTable);
         
           
           setResultAssessoresTable(resultadoTable);

           //Converte e salva o ROA dos Assessores
           const roaAssessores = assessoresRoa(assessoresResultado);
           const exportAssessoresRoaAnual = await exportData(roaAssessores);
           setRoaAssessores(roaAssessores)
           setExportAssessorRoa(exportAssessoresRoaAnual);
           

           setLoading(false);
               
        };
        
        if(anoAtual.length === 0 || mesAtual.length === 0){
            return;
        };
        
        getAreaResult();

    },[anoAtual, mesAtual, dataCorretoraResult,resultCorretora]);
    
    useEffect(()=>{
        setTimeout(() => {
            setLoading(false)
            
        }, 3000)
        
    },[])

    const formatters = [
        {
          type: "NumberFormat" ,
          column: 1,
          options: {
            prefix: "R$",
            negativeColor: "red",
            negativeParens: false,
          },
        },
    ];

    const options = {
        allowHtml: true,
        showRowNumber: true,
        width: '100%',
        height: '100%',
        fontSize: 18,
        chartArea: {
          width: '80%',
          height: '80%'
        },
        cssClassNames: {
          tableCell: 'center-text'
        },
       
    };
      
    function getDataByYear(data, year) {
        const result = [["Data", "Receita Bruta", "Receita Líquida", "Comissão Escritório", "Receita Bruta PJ1", "Impostos PJ1", "Receita Bruta PJ2", "Impostos PJ2","Lucro após impostos", "Comissão Paga", "Lucro Líquido","Net", "Roa", "Ajustes Líquido"]];
        const filteredData = Object.values(data).filter(item => item.data.startsWith(year));
      
        for (const item of filteredData) {
          let date = item.data.split('-')
          date = `${date[1]}/${date[0]}`
          const receitaBruta = parseFloat(item.receita_bruta.replace(',', '.')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
          const receitaLiquida = parseFloat(item.receita_liquida.replace(',', '.')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
          const comissaoEscritorio = parseFloat(item.comissao_escritorio.replace(',', '.')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
          const net = parseFloat(item.net.replace(',', '.')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
          const receita_bruta_pj1 = parseFloat(item.resultado_bruto_pj1.replace(',', '.')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
          const imposto_pj1 = parseFloat(item.imposto_pj1.replace(',', '.')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
          const receita_bruta_pj2 = parseFloat(item.resultado_bruto_pj2.replace(',', '.')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
          const imposto_pj2 = parseFloat(item.imposto_pj2.replace(',', '.')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
          const lucroAposImposto = parseFloat(item.lucro_apos_ir.replace(',', '.')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
          const comissaoPg = parseFloat(item.comissao_pg.replace(',', '.')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
          const lucroLiquido = parseFloat(item.lucro_liquido.replace(',', '.')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
          const roa = parseFloat(item.roa_mensal.replace(',', '.')).toFixed(2);
          const ajusteLiquido = parseFloat(item.ajustes.replace(',', '.')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
      
          result.push([date, receitaBruta, receitaLiquida, comissaoEscritorio, receita_bruta_pj1, imposto_pj1, receita_bruta_pj2, imposto_pj2, lucroAposImposto, comissaoPg, lucroLiquido, net, roa, ajusteLiquido]);
        }
      
        return result.sort((a, b) => {
          const aDate = new Date(`${year}-${a[0]}`);
          const bDate = new Date(`${year}-${b[0]}`);
          return aDate - bDate;
        });
    };
    
    function getDataByYearDre(data, year) {
       
        const result = [["Data", "Receita_Bruta", "Receita_Liquida", "Comissão_Escritório", "Despesas", "Custos", "Net", "Receita_Bruta_PJ1", "Impostos_PJ1", "Receita_Bruta_PJ2", "Impostos_PJ2","Lucro_Após_Impostos", "Comissão_Paga", "Lucro_Líquido", "Roa", "Ajuste_Líquido"]];
        const filteredData = Object.values(data).filter(item => item.data.startsWith(year));
      
        for (const item of filteredData) {
          let date = item.data.split('-')
          date = `${date[1]}/${date[0]}`
          const receitaBruta = parseFloat(item.receita_bruta.replace(',', '.')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
          const receitaLiquida = parseFloat(item.receita_liquida.replace(',', '.')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
          const comissaoEscritorio = parseFloat(item.comissao_escritorio.replace(',', '.')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
          const despesas = parseFloat(item.despesas.replace(',', '.')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
          const custos = parseFloat(item.custos.replace(',', '.')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
          const net = parseFloat(item.net.replace(',', '.')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
          const receita_bruta_pj1 = parseFloat(item.resultado_bruto_pj1.replace(',', '.')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
          const imposto_pj1 = parseFloat(item.imposto_pj1.replace(',', '.')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
          const receita_bruta_pj2 = parseFloat(item.resultado_bruto_pj2.replace(',', '.')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
          const imposto_pj2 = parseFloat(item.imposto_pj2.replace(',', '.')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
          const lucroAposImposto = parseFloat(item.lucro_apos_ir.replace(',', '.')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
          const comissaoPg = parseFloat(item.comissao_pg.replace(',', '.')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
          const lucroLiquido = parseFloat(item.lucro_liquido.replace(',', '.')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
          const roa = parseFloat(item.roa_mensal.replace(',', '.')).toFixed(2);
          const ajusteLiquido = parseFloat(item.ajustes.replace(',', '.')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
      
          result.push([date, receitaBruta, receitaLiquida, comissaoEscritorio, despesas, custos, net, receita_bruta_pj1, imposto_pj1, receita_bruta_pj2, imposto_pj2, lucroAposImposto, comissaoPg, lucroLiquido, roa, ajusteLiquido]);
        }
      
        return result.sort((a, b) => {
          const aDate = new Date(`${year}-${a[0]}`);
          const bDate = new Date(`${year}-${b[0]}`);
          return aDate - bDate;
        });
    };

    const meses = dataCorretoraResult[anoAtual]; // array de meses correspondente ao ano selecionado
    const optionsSelectMes = meses
        ?.sort((a, b) => b - a)
        .map((mes) => {
        const mesPad = mes.toString().padStart(2, '0');
        const label = `${mesPad}`;
        return {
        value: mesPad,
        label,
        };
    });

    const optionsSelectAno = Object.keys(dataCorretoraResult).map((ano) => {
        return {
          value: ano,
          label: ano,
        };
    });

    const changeAno = (ano) => {
        setAnoAtual(ano);

        // obter meses com resultados para o ano selecionado
        const mesesComResultados = dataCorretoraResult[ano] ?? []

        // ordenar meses em ordem decrescente e selecionar o primeiro elemento como o mês mais recente
        const mesMaisRecente = mesesComResultados.sort((a, b) => b - a)[0] ?? mesAtual
        // atualizar o valor padrão do Select do mês

        setMesAtual(mesMaisRecente)
    };
     
    function changeMes(event) {
        setMesAtual(event)
    };

    function convertData(obj) {
        const result = [];
      
        // Create the header row with "Data", "PJ1" and "PJ2" properties
        const header = ["Data", "PJ1", "PJ2"];
      
        // Loop through each object and extract the "Data", "resultado_bruto_pj1" and "resultado_bruto_pj2" properties
        Object.values(obj).forEach(obj => {
          const dateParts = obj.data.split("-");
          const formattedDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`; // format date as dd/mm/yyyy
          const row = [
            formattedDate,
            parseInt(obj.resultado_bruto_pj1),
            parseInt(obj.resultado_bruto_pj2)
          ];
      
          result.push(row);
        });
      
        // Add the header row at the beginning of the result array
        result.unshift(header);
      
        return result;
    }

    function downloadExcelPieMes() {
        const table = document.getElementById('table-piechartmes');
        const headers = table.querySelectorAll('th');
        const rows = table.querySelectorAll('tbody tr');
      
        const headerStyles = 'background-color: rgb(28, 54, 88); font-weight: bold; color:white;';
      
        let data = `<table><caption>Resultado corretora mês</caption><thead><tr>`;
        for (let i = 0; i < headers.length; i++) {
          data += `<th style="${headerStyles}">${headers[i].innerText}</th>`;
        }
        data += '</tr></thead><tbody>';
      
        for (let i = 0; i < rows.length; i++) {
          const cells = rows[i].querySelectorAll('td');
          data += '<tr>';
          for (let j = 0; j < cells.length; j++) {
            data += `<td style="border: 1px solid #ccc;">${cells[j].innerText}</td>`;
          }
          data += '</tr>';
        }
        data += '</tbody></table>';
      
        const link = document.createElement('a');
        link.download = 'relatório_corretora_mensal.xls';
        link.href = 'data:application/vnd.ms-excel;base64,' + btoa(data);
        link.click();
    };

    function downloadExcelPieAno() {
        const table = document.getElementById('table-piechartano');
        const headers = table.querySelectorAll('th');
        const rows = table.querySelectorAll('tbody tr');
      
        const headerStyles = 'background-color: rgb(28, 54, 88); font-weight: bold; color:white;';
      
        let data = `<table><caption>Resultado corretora por área anual</caption><thead><tr>`;
        for (let i = 0; i < headers.length; i++) {
          data += `<th style="${headerStyles}">${headers[i].innerText}</th>`;
        }
        data += '</tr></thead><tbody>';
      
        for (let i = 0; i < rows.length; i++) {
          const cells = rows[i].querySelectorAll('td');
          data += '<tr>';
          for (let j = 0; j < cells.length; j++) {
            data += `<td style="border: 1px solid #ccc;">${cells[j].innerText}</td>`;
          }
          data += '</tr>';
        }
        data += '</tbody></table>';
      
        const link = document.createElement('a');
        link.download = 'relatório_corretora_anual.xls';
        link.href = 'data:application/vnd.ms-excel;base64,' + btoa(data);
        link.click();
    };

    function downloadExcelPj() {
        const table = document.getElementById('table-pjchart');
        const headers = table.querySelectorAll('th');
        const rows = table.querySelectorAll('tbody tr');
      
        const headerStyles = 'background-color: rgb(28, 54, 88); font-weight: bold; color:white;';
      
        let data = `<table><caption>Resultado por Pj</caption><thead><tr>`;
        for (let i = 0; i < headers.length; i++) {
          data += `<th style="${headerStyles}">${headers[i].innerText}</th>`;
        }
        data += '</tr></thead><tbody>';
      
        for (let i = 0; i < rows.length; i++) {
          const cells = rows[i].querySelectorAll('td');
          data += '<tr>';
          for (let j = 0; j < cells.length; j++) {
            data += `<td style="border: 1px solid #ccc;">${cells[j].innerText}</td>`;
          }
          data += '</tr>';
        }
        data += '</tbody></table>';
      
        const link = document.createElement('a');
        link.download = 'relatório_resultado_pj.xls';
        link.href = 'data:application/vnd.ms-excel;base64,' + btoa(data);
        link.click();
    };

    function downloadExcelRoa() {
        const table = document.getElementById('table-roachart');
        const headers = table.querySelectorAll('th');
        const rows = table.querySelectorAll('tbody tr');
      
        const headerStyles = 'background-color: rgb(28, 54, 88); font-weight: bold; color:white;';
      
        let data = `<table><caption>Resultado Roa anual</caption><thead><tr>`;
        for (let i = 0; i < headers.length; i++) {
          data += `<th style="${headerStyles}">${headers[i].innerText}</th>`;
        }
        data += '</tr></thead><tbody>';
      
        for (let i = 0; i < rows.length; i++) {
          const cells = rows[i].querySelectorAll('td');
          data += '<tr>';
          for (let j = 0; j < cells.length; j++) {
            data += `<td style="border: 1px solid #ccc;">${cells[j].innerText}</td>`;
          }
          data += '</tr>';
        }
        data += '</tbody></table>';
      
        const link = document.createElement('a');
        link.download = 'relatório_corretora_roa.xls';
        link.href = 'data:application/vnd.ms-excel;base64,' + btoa(data);
        link.click();
    };

    function downloadExcelTable() {
        const table = document.getElementById('table-tablechart');
        const headers = table.querySelectorAll('th');
        const rows = table.querySelectorAll('tbody tr');
      
        const headerStyles = 'background-color: rgb(28, 54, 88); font-weight: bold; color:white;';
      
        let data = `<table><caption>Resultado corretora </caption><thead><tr>`;
        for (let i = 0; i < headers.length; i++) {
          data += `<th style="${headerStyles}">${headers[i].innerText}</th>`;
        }
        data += '</tr></thead><tbody>';
      
        for (let i = 0; i < rows.length; i++) {
          const cells = rows[i].querySelectorAll('td');
          data += '<tr>';
          for (let j = 0; j < cells.length; j++) {
            data += `<td style="border: 1px solid #ccc;">${cells[j].innerText}</td>`;
          }
          data += '</tr>';
        }
        data += '</tbody></table>';
      
        const link = document.createElement('a');
        link.download = 'relatório_resultado_corretora.xls';
        link.href = 'data:application/vnd.ms-excel;base64,' + btoa(data);
        link.click();
    };

    function downloadExcelAssessorMes() {
        const table = document.getElementById('table-assessoreschartpiemes');
        const headers = table.querySelectorAll('th');
        const rows = table.querySelectorAll('tbody tr');
      
        const headerStyles = 'background-color: rgb(28, 54, 88); font-weight: bold; color:white;';
      
        let data = `<table><caption>Resultado mensal Assessores por sociedade </caption><thead><tr>`;
        for (let i = 0; i < headers.length; i++) {
          data += `<th style="${headerStyles}">${headers[i].innerText}</th>`;
        }
        data += '</tr></thead><tbody>';
      
        for (let i = 0; i < rows.length; i++) {
          const cells = rows[i].querySelectorAll('td');
          data += '<tr>';
          for (let j = 0; j < cells.length; j++) {
            data += `<td style="border: 1px solid #ccc;">${cells[j].innerText}</td>`;
          }
          data += '</tr>';
        }
        data += '</tbody></table>';
      
        const link = document.createElement('a');
        link.download = 'relatório_assessores_sociedade_mensal.xls';
        link.href = 'data:application/vnd.ms-excel;base64,' + btoa(data);
        link.click();
    };

    function downloadExcelAssessorAnual() {
        const table = document.getElementById('table-assessoreschartpieanual');
        const headers = table.querySelectorAll('th');
        const rows = table.querySelectorAll('tbody tr');
      
        const headerStyles = 'background-color: rgb(28, 54, 88); font-weight: bold; color:white;';
      
        let data = `<table><caption>Resultado anual Assessores por sociedade  </caption><thead><tr>`;
        for (let i = 0; i < headers.length; i++) {
          data += `<th style="${headerStyles}">${headers[i].innerText}</th>`;
        }
        data += '</tr></thead><tbody>';
      
        for (let i = 0; i < rows.length; i++) {
          const cells = rows[i].querySelectorAll('td');
          data += '<tr>';
          for (let j = 0; j < cells.length; j++) {
            data += `<td style="border: 1px solid #ccc;">${cells[j].innerText}</td>`;
          }
          data += '</tr>';
        }
        data += '</tbody></table>';
      
        const link = document.createElement('a');
        link.download = 'relatório_assessores_sociedade.xls';
        link.href = 'data:application/vnd.ms-excel;base64,' + btoa(data);
        link.click();
    };

    function downloadExcelAssessorRoaAnual() {
        const table = document.getElementById('table-assessoresroaanual');
        const headers = table.querySelectorAll('th');
        const rows = table.querySelectorAll('tbody tr');
      
        const headerStyles = 'background-color: rgb(28, 54, 88); font-weight: bold; color:white;';
      
        let data = `<table><caption>Resultado Assessores roa anual </caption><thead><tr>`;
        for (let i = 0; i < headers.length; i++) {
          data += `<th style="${headerStyles}">${headers[i].innerText}</th>`;
        }
        data += '</tr></thead><tbody>';
      
        for (let i = 0; i < rows.length; i++) {
          const cells = rows[i].querySelectorAll('td');
          data += '<tr>';
          for (let j = 0; j < cells.length; j++) {
            data += `<td style="border: 1px solid #ccc;">${cells[j].innerText}</td>`;
          }
          data += '</tr>';
        }
        data += '</tbody></table>';
      
        const link = document.createElement('a');
        link.download = 'relatório_roa_assessores.xls';
        link.href = 'data:application/vnd.ms-excel;base64,' + btoa(data);
        link.click();
    };

    function downloadExcelTableCustoXdespesas() {
        const table = document.getElementById('table-CustosDespesas');
        const headers = table.querySelectorAll('th');
        const rows = table.querySelectorAll('tbody tr');
      
        const headerStyles = 'background-color: rgb(28, 54, 88); font-weight: bold; color:white;';
      
        let data = `<table><caption>Resultados Custos x Despesas</caption><thead><tr>`;
        for (let i = 0; i < headers.length; i++) {
          data += `<th style="${headerStyles}">${headers[i].innerText}</th>`;
        }
        data += '</tr></thead><tbody>';
      
        for (let i = 0; i < rows.length; i++) {
          const cells = rows[i].querySelectorAll('td');
          data += '<tr>';
          for (let j = 0; j < cells.length; j++) {
            data += `<td style="border: 1px solid #ccc;">${cells[j].innerText}</td>`;
          }
          data += '</tr>';
        }
        data += '</tbody></table>';
      
        const link = document.createElement('a');
        link.download = 'Custos_X_Despesas_relatório.xls';
        link.href = 'data:application/vnd.ms-excel;base64,' + btoa(data);
        link.click();
    };

    function downloadExcelDreFinanceiro() {
        const table = document.getElementById('table-dre');
        const headers = table.querySelectorAll('th');
        const rows = table.querySelectorAll('tbody tr');
      
        const headerStyles = 'background-color: rgb(28, 54, 88); font-weight: bold; color:white;';
      
        let data = `<table><caption>Resultado Financeiro</caption><thead><tr>`;
        for (let i = 0; i < headers.length; i++) {
          data += `<th style="${headerStyles}">${headers[i].innerText}</th>`;
        }
        data += '</tr></thead><tbody>';
      
        for (let i = 0; i < rows.length; i++) {
          const cells = rows[i].querySelectorAll('td');
          data += '<tr>';
          for (let j = 0; j < cells.length; j++) {
            data += `<td style="border: 1px solid #ccc;">${cells[j].innerText}</td>`;
          }
          data += '</tr>';
        }
        data += '</tbody></table>';
      
        const link = document.createElement('a');
        link.download = 'Resultado_Financeiro_relatório.xls';
        link.href = 'data:application/vnd.ms-excel;base64,' + btoa(data);
        link.click();
    };

    function downloadExcelAssessorTable() {
        const table = document.getElementById('table-assessorestable');
        const headers = table.querySelectorAll('th');
        const rows = table.querySelectorAll('tbody tr');
      
        const headerStyles = 'background-color: rgb(28, 54, 88); font-weight: bold; color:white;';
      
        let data = `<table><caption>Resultado anual Assessores por sociedade  </caption><thead><tr>`;
        for (let i = 0; i < headers.length; i++) {
          data += `<th style="${headerStyles}">${headers[i].innerText}</th>`;
        }
        data += '</tr></thead><tbody>';
      
        for (let i = 0; i < rows.length; i++) {
          const cells = rows[i].querySelectorAll('td');
          data += '<tr>';
          for (let j = 0; j < cells.length; j++) {
            data += `<td style="border: 1px solid #ccc;">${cells[j].innerText}</td>`;
          }
          data += '</tr>';
        }
        data += '</tbody></table>';
      
        const link = document.createElement('a');
        link.download = 'relatório_assessores_anual.xls';
        link.href = 'data:application/vnd.ms-excel;base64,' + btoa(data);
        link.click();
    };

    function downloadExcelAssessorArea() {
        const table = document.getElementById('table-assessoresarea');
        const headers = table.querySelectorAll('th');
        const rows = table.querySelectorAll('tbody tr');
      
        const headerStyles = 'background-color: rgb(28, 54, 88); font-weight: bold; color:white;';
      
        let data = `<table><caption>Resultado Assessores por produto </caption><thead><tr>`;
        for (let i = 0; i < headers.length; i++) {
          data += `<th style="${headerStyles}">${headers[i].innerText}</th>`;
        }
        data += '</tr></thead><tbody>';
      
        for (let i = 0; i < rows.length; i++) {
          const cells = rows[i].querySelectorAll('td');
          data += '<tr>';
          for (let j = 0; j < cells.length; j++) {
            data += `<td style="border: 1px solid #ccc;">${cells[j].innerText}</td>`;
          }
          data += '</tr>';
        }
        data += '</tbody></table>';
      
        const link = document.createElement('a');
        link.download = 'relatório_assessores_produtos.xls';
        link.href = 'data:application/vnd.ms-excel;base64,' + btoa(data);
        link.click();
    };

    const columns = [
        {
          title: 'Data',
          dataIndex: 'data',
          key: 'data',
        },
        {
          title: 'Receita',
          dataIndex: 'receita',
          key: 'receita',
        },
        {
          title: 'Impostos',
          dataIndex: 'impostos',
          key: 'impostos',
        },
        {
            title: 'Comissão Paga',
            dataIndex: 'comissao_pg',
            key: 'comissao_pg',
          },
        {
            title: 'Margem Contribuição',
            dataIndex: 'margem',
            key: 'margem',
          },
          {
            title: 'Despesas',
            dataIndex: 'despesas',
            key: 'despesas',
          },
          {
            title: 'Custos',
            dataIndex: 'custos',
            key: 'custos',
          },
          {
            title: 'Lucro Líquido',
            dataIndex: 'lucroliquido',
            key: 'lucroliquido',
          },
          {
            title: 'Caixa',
            dataIndex: 'caixa',
            key: 'caixa',
          },
    ];       
     
    const items = [
        {
          key: '1',
          label: `Dashboard Corretora`,
          children: 
                <div  className={ loading || Object.keys(dataCorretoraResult).length === 0? '--Disable': "DashBoard__secondContent"}>
                    <Divider orientation="middle">
                        <p>DASHBOARD CORRETORA</p>
                    </Divider>
                    <Divider orientation="left">
                        <p>Indicadores & Gráficos</p>
                    </Divider>   
                    <Row  gutter={16}>
                        <Col span={6}>
                            <Card bordered={false}>
                                <Statistic
                                title="FATURAMENTO MENSAL"
                                value={indicadoresCorretoraMensal?.receita_bruta ? indicadoresCorretoraMensal.receita_bruta : '...'}
                                precision={2}
                                valueStyle={{
                                    color: '#3f8600',
                                }}
                                prefix={""}
                                suffix=""
                                />
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card bordered={false}>
                                <Statistic
                                title="LUCRO LÍQUIDO MENSAL"
                                value={indicadoresCorretoraMensal?.lucro_liquido ? indicadoresCorretoraMensal.lucro_liquido : '...'}
                                precision={2}
                                valueStyle={{
                                    color: '#3f8600',
                                }}
                                prefix={''}
                                suffix=""
                                />
                            </Card>
                        </Col>  
                        <Col span={6}>
                            <Card bordered={false}>
                                <Statistic
                                title="ROA"
                                value={indicadoresCorretoraMensal?.roa_mensal ? indicadoresCorretoraMensal.roa_mensal : '...'}
                                precision={2}
                                valueStyle={{
                                    color: '#3f8600',
                                }}
                                prefix={''}
                                suffix="%"
                                />
                            </Card>
                        </Col>   
                        <Col span={6}>
                            <Card bordered={false}>
                                <Statistic
                                title="COMISSÃO PAGA"
                                value={indicadoresCorretoraMensal?.comissao_pg ? indicadoresCorretoraMensal.comissao_pg : '...'}
                                precision={2}
                                valueStyle={{
                                    color: '#cf1322',
                                }}
                                prefix={''}
                                suffix=""
                                />
                            </Card>
                        </Col>
                    </Row>
                    <div  className="DashBoard__grafico">
                        <div   id="pdf-2" className="DashBoard__graficoContent" key={'div1'}>
                            <Chart
                                chartType="PieChart"
                                width="100%"
                                height="400px"
                                data={resultAreaMensal}
                                options={optionspie}
                                chartWrapperParams={{ view: { columns: [0, 2] } }}
                                chartPackages={["corechart", "controls"]}
                                chartLanguage="pt-BR"
                                
                                 key={`chart1-${chartKey}`}
                                 
                                controls={[
                                    {
                                    controlEvents: [
                                        {
                                        eventName: "statechange",
                                        callback: ({ chartWrapper, controlWrapper }) => {
                                            console.log("State changed to", controlWrapper?.getState());
                                        },
                                        },
                                    ],
                                    controlType: "CategoryFilter",
                                    options: {
                                        filterColumnIndex: 1,
                                        ui: {
                                        labelStacking: "vertical",
                                        label: "Resultado Mensal:",
                                        allowTyping: false,
                                        allowMultiple: false,
                                        },
                                    },
                                    },
                                ]}
                                className="DashConfig__pieChart"
                            /> 
                            <Tooltip 
                                placement="bottom" 
                                title={'Clique para baixar o relatório'} 
                                arrow={true}
                                className="DashBoardConfig__tooltip"
                                >
                                
                                <FileDoneOutlined 
                                    className="DashBoardConfig__fileRecord"
                                    title="Download relatório"
                                    label="Download relatório"
                                    onClick={downloadExcelPieMes}
                                
                                />
                                
                        
                            </Tooltip>
                            <table id="table-piechartmes" style={{display:'none'}}>                                       
                                <thead >               
                                    <tr>
                                        {
                                            exportPieMes 
                                            &&
                                            Object.keys( exportPieMes[0]).map((key) => {
                                                return <th className="header-cell" key={key}>{key.toLocaleUpperCase()}</th>;
                                            })
                                        }
                                    </tr>
                                </thead>                
                                <tbody>
                                    {
                                        exportPieMes 
                                        && 
                                        Object.keys(exportPieMes).map((key) => {
                                            const obj = exportPieMes[key];
                                            return (
                                              <tr key={key}>
                                                {Object.entries(obj).map(([propriedade, valor], index) => (
                                                  propriedade === 'faturamento'
                                                    ? <td key={`thead-cell-${key}--${index}`}>{parseFloat(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                    : <td key={`thead-cell-${key}--${index}`}>{valor}</td>
                                                ))}
                                              </tr>
                                            );
                                          })
                                    }
                                </tbody>
                            </table>                   
                        </div>
                        <div   id="pdf-3" className="DashBoard__graficoContent" key={'div2'}>
                            <Chart
                                chartType="PieChart"
                                width="100%"
                                height="400px"
                                data={resultAreaTotal}
                                options={optionspie}
                                chartWrapperParams={{ view: { columns: [0, 2] } }}
                                chartPackages={["corechart", "controls"]}
                                chartLanguage="pt-BR"
                                 key={`chart2-${chartKey}`}
                                controls={[
                                    {
                                    controlEvents: [
                                        {
                                        eventName: "statechange",
                                        callback: ({ chartWrapper, controlWrapper }) => {
                                            console.log("State changed to", controlWrapper?.getState());
                                        },
                                        },
                                    ],
                                    controlType: "CategoryFilter",
                                    options: {
                                        filterColumnIndex: 1,
                                        ui: {
                                        labelStacking: "vertical",
                                        label: "Resultado Anual:",
                                        allowTyping: false,
                                        allowMultiple: false,
                                        },
                                    },
                                    },
                                ]}
                                className="DashConfig__pieChart"
                            />
                            <Tooltip 
                                placement="bottom" 
                                title={'Clique para baixar o relatório'} 
                                arrow={true}
                                className="DashBoardConfig__tooltip"
                                >
                                
                                <FileDoneOutlined 
                                    className="DashBoardConfig__fileRecord"
                                    title="Download relatório"
                                    label="Download relatório"
                                    onClick={downloadExcelPieAno}
                                
                                />
                                
                        
                            </Tooltip>
                            <table id="table-piechartano" style={{display:'none'}}>                                       
                                <thead >               
                                    <tr>
                                        {
                                           exportPieAno 
                                            &&
                                           
                                            Object.keys(exportPieAno[0]).map((key) => {
                                                return <th className="header-cell" key={key}>{key.toLocaleUpperCase()}</th>;
                                            })
                                        }
                                    </tr>
                                </thead>                
                                <tbody>
                                    {
                                        exportPieAno
                                        && 
                                        Object.keys(exportPieAno).map((key) => {
                                            const obj = exportPieAno[key];
                                            return (
                                              <tr key={key}>
                                                {Object.entries(obj).map(([propriedade, valor], index) => (
                                                  propriedade === 'faturamento'
                                                    ? <td key={`thead-cell-${key}--${index}`}>{parseFloat(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                    : <td key={`thead-cell-${key}--${index}`}> {valor}</td>
                                                ))}
                                              </tr>
                                            );
                                          })
                                    }
                                </tbody>
                            </table> 
                            

                        </div>
                    </div>
                    <div className="DashBoard__graficoPj" key={'div3'}>
                        <Chart
                            chartType="Bar"
                            width="100%"
                            height="400px"
                            chartLanguage="pt-BR"
                            data={resultadoPj}
                            options={optionsPJ}
                        
                            
                        />
                        <Tooltip 
                                placement="bottom" 
                                title={'Clique para baixar o relatório'} 
                                arrow={true}
                                className="DashBoardConfig__tooltipPj"
                            >
                                
                            <FileDoneOutlined 
                                className="DashBoardConfig__fileRecord"
                                title="Download relatório"
                                label="Download relatório"
                                onClick={downloadExcelPj}
                                
                            />
                                
                        
                        </Tooltip> 
                        <table id="table-pjchart" style={{display:'none'}}>                                       
                                <thead >               
                                    <tr>
                                        {
                                            exportPj
                                            &&
                                           
                                            Object.keys(exportPj[0]).map((key) => {
                                                return <th className="header-cell" key={key}>{key.toLocaleUpperCase()}</th>;
                                            })
                                        }
                                    </tr>
                                </thead>                
                                <tbody>
                                    {
                                        exportPj 
                                        && 
                                        Object.keys(exportPj).map((key) => {
                                            const obj = exportPj[key];
                                            return (
                                              <tr key={key}>
                                                {Object.entries(obj).map(([propriedade, valor], index) => (
                                                  propriedade === 'pj1' || propriedade === 'pj2'
                                                    ? <td key={`thead-cell-${key}--${index}`}>{parseFloat(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                    : <td key={`thead-cell-${key}--${index}`}>{valor}</td>
                                                ))}
                                              </tr>
                                            );
                                          })
                                    }
                                </tbody>
                        </table> 

                    </div>
                    <div    className="DashBoard__graficoRoa" key={'div4'}>
                        <Chart
                            chartType="LineChart"
                            width="100%"
                            height="600px"
                            data={roaAnual}
                            options={options2}
                            chartLanguage="pt-BR"
                            key={`chart3-${chartKey}`}
                            DashConfig__roaChart 
                                
                        />
                         <Tooltip 
                                placement="bottom" 
                                title={'Clique para baixar o relatório'} 
                                arrow={true}
                                className="DashBoardConfig__tooltipPj"
                            >
                                
                            <FileDoneOutlined 
                                className="DashBoardConfig__fileRecord"
                                title="Download relatório"
                                label="Download relatório"
                                onClick={downloadExcelRoa}
                                
                            />
                                
                        
                        </Tooltip>
                        <table id="table-roachart" style={{display:'none'}}>                                       
                                <thead >               
                                    <tr>
                                        {
                                            exportRoa
                                            &&
                                            Object.keys(exportRoa[0]).map((key) => {
                                                return <th className="header-cell" key={key}>{key.toLocaleUpperCase()}</th>;
                                            })
                                        }
                                    </tr>
                                </thead>                
                                <tbody>
                                    {
                                        exportRoa 
                                        && 
                                        Object.keys(exportRoa).map((key) => {
                                            const obj = exportRoa[key];
                                            return (
                                              <tr key={key}>
                                                {Object.entries(obj).map(([propriedade, valor],index ) => (
                                                  propriedade === 'faturamento'
                                                    ? <td key={`thead-cell-${key}--${index}`}>{parseFloat(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                    : <td key={`thead-cell-${key}--${index}`}>{valor}</td>
                                                ))}
                                              </tr>
                                            );
                                          })
                                    }
                                </tbody>
                        </table>

                    </div>
                    <div    className="DashBoard__tableChart" key={'div5'}>
                        <Chart
                            chartType="Table"
                            width="100%"
                            height="400px"
                            data={corretoraTableAnual}
                            options={options}
                            formatters={formatters}
                            className='DashBoard__table'
                            key={`chart4-${chartKey}`}
                                                                                                          
                        />
                        <Tooltip 
                            placement="bottom" 
                            title={'Clique para baixar o relatório'} 
                            arrow={true}
                            className="DashBoardConfig__tooltipPj"
                        >
                                
                            <FileDoneOutlined 
                                className="DashBoardConfig__fileRecord"
                                title="Download relatório"
                                label="Download relatório"
                                onClick={downloadExcelTable}
                                
                            />
                                
                        
                        </Tooltip>
                        <table id="table-tablechart" style={{display:'none'}}>                                       
                                <thead >               
                                    <tr>
                                        {
                                            exportTable
                                            &&
                                            Object.keys(exportTable[0]).map((key) => {
                                                return <th className="header-cell" key={key}>{key.toLocaleUpperCase()}</th>;
                                            })
                                        }
                                    </tr>
                                </thead>                
                                <tbody>
                                    {
                                        exportTable 
                                        && 
                                        Object.keys(exportTable).map((key, index) => {
                                           
                                            const obj = exportTable[key];
                                            return (
                                              <tr key={key}>
                                                {Object.entries(obj).map(([propriedade, valor], index) => (
                                                  propriedade === 'faturamento'
                                                    ? <td key={`thead-cell-${key}--${index}`}>{parseFloat(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                    : <td key={`thead-cell-${key}--${index}`}>{valor}</td>
                                                ))}
                                              </tr>
                                            );
                                          })
                                    }
                                </tbody>
                        </table>
                        

                    </div>
                </div>
            ,
        },
        {
          key: '2',
          label: `Dashboard Assessores`,
          children: 
                <div className={ loading || Object.keys(dataCorretoraResult).length === 0? '--Disable': "DashBoard__secondContent"}>

                        <Divider orientation="left">
                            <p>Indicadores Corretora</p>
                        </Divider>
                        <Row gutter={16}>
                            <Col span={6}>
                                <Card bordered={false}>
                                    <Statistic
                                        title="FATURAMENTO MENSAL"
                                        value={indicadoresCorretoraMensal?.receita_bruta ? indicadoresCorretoraMensal.receita_bruta : '...'}
                                        precision={2}
                                        valueStyle={{
                                            color: '#3f8600',
                                        }}
                                        prefix={""}
                                        suffix=""
                                        
                                    />
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card bordered={false}>
                                    <Statistic
                                    title="LUCRO LÍQUIDO MENSAL"
                                    value={indicadoresCorretoraMensal?.lucro_liquido ? indicadoresCorretoraMensal.lucro_liquido : '...'}
                                    precision={2}
                                    valueStyle={{
                                        color: '#3f8600',
                                    }}
                                    prefix={''}
                                    suffix=""
                                    />
                                </Card>
                            </Col>  
                            <Col span={6}>
                                <Card bordered={false}>
                                    <Statistic
                                    title="ROA"
                                    value={indicadoresCorretoraMensal?.roa_mensal ? indicadoresCorretoraMensal.roa_mensal : '...'}
                                    precision={2}
                                    valueStyle={{
                                        color: '#3f8600',
                                    }}
                                    prefix={''}
                                    suffix="%"
                                    />
                                </Card>
                            </Col>   
                            <Col span={6}>
                                <Card bordered={false}>
                                    <Statistic
                                    title="COMISSÃO PAGA"
                                    value={indicadoresCorretoraMensal?.comissao_pg ? indicadoresCorretoraMensal.comissao_pg : '...'}
                                    precision={2}
                                    valueStyle={{
                                        color: '#cf1322',
                                    }}
                                    prefix={''}
                                    suffix=""
                                    />
                                </Card>
                            </Col>
                        </Row>
                        <Divider orientation="middle">
                            <p>DASHBOARD ASSESSORES</p>
                        </Divider>
                        <Divider orientation="left">
                            <p>Indicadores & Gráficos</p>
                        </Divider>
                        <div className="DashBoard__grafico">
                            <div className="DashBoard__graficoContent" key={"assessorPieChatMes"}>
                                <Chart
                                    chartType="PieChart"
                                    width="100%"
                                    height="400px"
                                    data={resultAssessores}
                                    options={optionspie}
                                    chartWrapperParams={{ view: { columns: [0, 2] } }}
                                    chartPackages={["corechart", "controls"]}
                                    chartLanguage="pt-BR"
                                     key={`chart5-${chartKey}`}
                                    controls={[
                                        {
                                        controlEvents: [
                                            {
                                            eventName: "statechange",
                                            callback: ({ chartWrapper, controlWrapper }) => {
                                                console.log("State changed to", controlWrapper?.getState());
                                            },
                                            },
                                        ],
                                        controlType: "CategoryFilter",
                                        options: {
                                            filterColumnIndex: 1,
                                            ui: {
                                            labelStacking: "vertical",
                                            label: "Faturamento Mensal Assessor:",
                                            allowTyping: false,
                                            allowMultiple: false,
                                            },
                                        },
                                        },
                                    ]}
                                    className="DashConfig__pieChart "
                                />
                                <Tooltip 
                                    placement="bottom" 
                                    title={'Clique para baixar o relatório'} 
                                    arrow={true}
                                    className="DashBoardConfig__tooltip"
                                    key={'AssessorMes'}
                                >
                                
                                <FileDoneOutlined 
                                    className="DashBoardConfig__fileRecord"
                                    title="Download relatório"
                                    label="Download relatório"
                                    onClick={downloadExcelAssessorMes}
                                    
                                />
                                
                        
                            </Tooltip>   
                            
                            <table id="table-assessoreschartpiemes" style={{display:'none'}}>                                       
                                    <thead >               
                                        <tr>
                                            {
                                                exportAssessorPiesMes
                                                &&
                                            
                                                Object.keys(exportAssessorPiesMes[0]).map((key) => {
                                                    return <th className="header-cell" key={`tbody-row1-${key}`}>{key.toLocaleUpperCase()}</th>;
                                                })
                                            }
                                        </tr>
                                    </thead>                
                                    <tbody>
                                        {
                                            exportAssessorPiesMes 
                                            && 
                                            Object.keys(exportAssessorPiesMes).map((key) => {
                                                const obj = exportAssessorPiesMes[key];
                                                return (
                                                <tr key={`tbody-cell1-${key}`}>
                                                    {Object.entries(obj).map(([propriedade, valor],index ) => (
                                                    propriedade === "faturamento"
                                                        ? <td key={`thead-cell1-${key}-${index}`} >{parseFloat(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                        : <td key={`thead-cell11-${key}-${index}`}>{valor}</td>
                                                    ))}
                                                </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                            </table>                    
                            </div>
                            <div className="DashBoard__graficoContent">
                                <Chart
                                    chartType="PieChart"
                                    width="100%"
                                    height="400px"
                                    data={resultAssessores2}
                                    options={optionspie}
                                    chartWrapperParams={{ view: { columns: [0, 2] } }}
                                    chartPackages={["corechart", "controls"]}
                                    chartLanguage="pt-BR"
                                     key={`chart6-${chartKey}`}
                                    controls={[
                                        {
                                        controlEvents: [
                                            {
                                            eventName: "statechange",
                                            callback: ({ chartWrapper, controlWrapper }) => {
                                                console.log("State changed to", controlWrapper?.getState());
                                            },
                                            },
                                        ],
                                        controlType: "CategoryFilter",
                                        options: {
                                            filterColumnIndex: 1,
                                            ui: {
                                            labelStacking: "vertical",
                                            label: "Lucro Líquido gerado para Corretora:",
                                            allowTyping: false,
                                            allowMultiple: false,
                                            },
                                        },
                                        },
                                    ]}
                                    className="DashConfig__pieChart "
                                />
                                 <Tooltip 
                                    placement="bottom" 
                                    title={'Clique para baixar o relatório'} 
                                    arrow={true}
                                    className="DashBoardConfig__tooltip"
                                >
                                        
                                    <FileDoneOutlined 
                                        className="DashBoardConfig__fileRecord"
                                        title="Download relatório"
                                        label="Download relatório"
                                        onClick={downloadExcelAssessorAnual}
                                        
                                    />
                                
                        
                                </Tooltip>
                                <table id="table-assessoreschartpieanual" style={{display:'none'}}>                                       
                                    <thead >               
                                        <tr>
                                            {
                                                exportAssessorPiesAnual
                                                &&
                                            
                                                Object.keys(exportAssessorPiesAnual[0]).map((key) => {
                                                    return <th className="header-cell"  key={`tbody-row--${key}`}>{key.toLocaleUpperCase()}</th>;
                                                })
                                            }
                                        </tr>
                                    </thead>                
                                    <tbody>
                                        {
                                            exportAssessorPiesAnual 
                                            && 
                                            Object.keys(exportAssessorPiesAnual).map((key) => {
                                                const obj = exportAssessorPiesAnual[key];
                                                return (
                                                <tr key={`tbody-cell-${key}`}>
                                                    {Object.entries(obj).map(([propriedade, valor], index) => (
                                                    propriedade === "lucro corretora"
                                                        ? <td key={`thead-cell2-${key}-${index}`}>{parseFloat(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                        : <td key={`thead-cell22-${key}-${index}`}>{valor}</td>
                                                    ))}
                                                </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                            </table> 
                                

                            </div>
                        </div>
                        <div className="DashConfig_financeiroSecondContent">
                            <Chart
                                chartType="BarChart"
                                width="100%"
                                height="400px"
                                chartLanguage="pt-BR"
                                data={assessoresArea}
                                options={optionsAssessoresArea}
                                                       
                            />
                            <Tooltip 
                                placement="bottom" 
                                title={'Clique para baixar o relatório'} 
                                arrow={true}
                                className="DashBoardConfig__tooltipFinanceiro"
                            >
                                
                                <FileDoneOutlined 
                                    className="DashBoardConfig__fileRecord"
                                    title="Download relatório"
                                    label="Download relatório"
                                    onClick={downloadExcelAssessorArea}
                                    
                                />
                                
                        
                            </Tooltip>
                            <table id="table-assessoresarea" style={{display:'none'}}>                                       
                                    <thead >               
                                        <tr>
                                            {
                                                exportAssessoresArea
                                                &&
                                                Object.keys(exportAssessoresArea[0]).map((key, index) => {
                                                    return <th className="header-cell" key={`${index}-header-cell`}>{key.toLocaleUpperCase()}</th>;
                                                })
                                            }
                                        </tr>
                                    </thead>                
                                    <tbody>
                                        {
                                            exportAssessoresArea 
                                            && 
                                            Object.keys(exportAssessoresArea).map((key, index) => {
                                                const obj = exportAssessoresArea[key];
                                                return (
                                                  <tr key={`row-${key}`}>
                                                    {Object.entries(obj).map(([propriedade, valor]) => (
                                                        propriedade ===  'assessor'
                                                        ?
                                                        <td key={`cell-${key}-${propriedade}`}>{valor.toUpperCase()}</td>
                                                        :
                                                        <td key={`cell-${key}-${propriedade}`}>
                                                        {parseFloat(valor)
                                                          .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                                          .replace(/[^\d.,]/g, '')}
                                                      </td>
                                                    ))}
                                                  </tr>
                                                );
                                              })
                                        }
                                    </tbody>
                            </table>                           
                        </div>
                        <div className="DashBoard__graficoRoa">
                            <Chart
                                chartType="ColumnChart"
                                width="100%"
                                height="600px"
                                data={roaAssessores}
                                options={optionsBar}
                                chartLanguage="pt-BR"
                                key={`chart7-${chartKey}`}
                                className="DashConfig__roaChart"

                            />
                            <Tooltip 
                                placement="bottom" 
                                title={'Clique para baixar o relatório'} 
                                arrow={true}
                                className="DashBoardConfig__tooltipPj"
                            >
                                
                                <FileDoneOutlined 
                                    className="DashBoardConfig__fileRecord"
                                    title="Download relatório"
                                    label="Download relatório"
                                    onClick={downloadExcelAssessorRoaAnual}
                                    
                                />
                                
                        
                            </Tooltip>
                            <table id="table-assessoresroaanual" style={{display:'none'}}>                                       
                                    <thead >               
                                        <tr>
                                            {
                                                exportAssessorRoa
                                                &&
                                            
                                                Object.keys(exportAssessorRoa[0]).map((key) => {
                                                    return <th className="header-cell"  key={`tbody-row--${key}`}>{key.toLocaleUpperCase()}</th>;
                                                })
                                            }
                                        </tr>
                                    </thead>                
                                    <tbody>
                                        {
                                            exportAssessorRoa 
                                            && 
                                            Object.keys(exportAssessorRoa).map((key) => {
                                                const obj = exportAssessorRoa[key];
                                                return (
                                                    <tr key={`tbody-cell-${key}`}>
                                                        {Object.entries(obj).map(([propriedade, valor], index) => (
                                                            propriedade === "faturamento"
                                                                ? <td key={`thead-cell33-${key}-${index}`}>{parseFloat(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                : <td key={`thead-cell3-${key}-${index}`}>{valor}</td>
                                                        ))}
                                                    </tr>
                                                );
                                            })
                                            
                                        }
                                    </tbody>
                            </table> 

                        </div>
                        <div className="DashBoard__tableChart">
                            <Chart
                                chartType="Table"
                                width="100%"
                                height="400px"
                                data={resultAssessoresTable}
                                options={options}
                                formatters={formatters}
                                className='DashBoard__table'
                                key={`chart8-${chartKey}`}
                                
                                
                                
                            />
                            <Tooltip 
                                placement="bottom" 
                                title={'Clique para baixar o relatório'} 
                                arrow={true}
                                className="DashBoardConfig__tooltipPj"
                            >
                                
                                <FileDoneOutlined 
                                    className="DashBoardConfig__fileRecord"
                                    title="Download relatório"
                                    label="Download relatório"
                                    onClick={downloadExcelAssessorTable}
                                    
                                />
                                
                                    
                            </Tooltip>
                            <table id="table-assessorestable" style={{display:'none'}}>                                       
                                    <thead >               
                                        <tr>
                                            {
                                                exportAssessorTable
                                                &&
                                            
                                                Object.keys(exportAssessorTable[0]).map((key) => {
                                                   
                                                    return <th className="header-cell" key={`tbody-row--${key}`}>{key.toLocaleUpperCase()}</th>;
                                                })
                                            }
                                        </tr>
                                    </thead>                
                                    <tbody>
                                        {
                                            exportAssessorTable 
                                            && 
                                            
                                            Object.keys(exportAssessorTable).map((key, index) => {
                                                const obj = exportAssessorTable[key];
                                                return (
                                                  <tr key={`tbody-cell-${key}`}>
                                                    {Object.entries(obj).map(([propriedade, valor], propIndex) => (
                                                      propriedade === "CÓD ASSESSOR - NOME" ||
                                                      propriedade === "DATA" ||
                                                      propriedade === "TIPO" ||
                                                      propriedade === "ROA" ? (
                                                        <td key={`thead-cell-${key}-${propIndex}`}>{valor}</td>
                                                      ) : (
                                                        <td key={`thead-cell-value-${key}-${propIndex}`}>
                                                          {valor.replace("R$", "")}
                                                        </td>
                                                      )
                                                    ))}
                                                  </tr>
                                                );
                                            })
                                              
                                                
                                                
                                               
                                                
                                            
                                        }
                                    </tbody>
                            </table> 

                        </div>
                </div>,
        },
        {
            key:'3',
            label:'Dashboard Financeiro',
            children:
                <div>
                    <div className="DashConfig_DashFinanceiro">
                        <div className="DashConfig_financeiroFirstContent">
                            <Divider orientation="left">
                                <p>Resultado Corretora</p>
                                
                            </Divider>
                            
                            <Table
                                columns={columns}
                                expandable={{
                                expandedRowRender: (record) => (
                                    <p
                                    style={{
                                        margin: 0,
                                    }}
                                    >
                                    {record.description}
                                    </p>
                                ),
                                rowExpandable: (record) => record.name !== 'Not Expandable',
                                }}
                                dataSource={resultDre}
                                pagination={false}
                            />
                            
                            <FileDoneOutlined 
                                className="DashBoardConfig__fileRecord"
                                title="Download relatório"
                                label="Download relatório"
                                onClick={downloadExcelDreFinanceiro}
                                style={{marginTop:'10px'}}
                                
                            />
                           
                            <table id="table-dre" style={{display:'none'}}>                                       
                                <thead>               
                                    <tr>
                                    {resultDre && Object.keys(resultDre[0]).map((key, index) => {
                                    if (key === 'description' || key === 'key') {
                                        return null;
                                    } else if (key.toLocaleUpperCase() === 'COMISSAO_PG') {
                                        return <th className="header-cell" key={`thead-cell-${index}`}>COMISSÃO PAGA</th>;
                                    } else if (key.toLocaleUpperCase() === 'LUCROLIQUIDO') {
                                        return <th className="header-cell" key={`thead-cell-${index}`}>LUCRO LÍQUIDO</th>;
                                    } else {
                                        return <th className="header-cell" key={`thead-cell-${index}`}>{key.toLocaleUpperCase()}</th>;
                                    }
                                    })}

                                    </tr>
                                </thead>                
                                <tbody>
                                    {resultDre && Object.values(resultDre).map((key, index) => {
                                      
                                        return (
                                        <tr key={`tbody-row-${index}`}>
                                            {Object.entries(key).map(([propriedade, valor]) => (
                                                propriedade === 'description' || propriedade === 'key'
                                                ?
                                                null
                                                :
                                                <td key={`tbody-cell-${index}-${propriedade}`}>{valor}</td>
                                            ))}
                                        </tr>
                                        );
                                    })}
                                </tbody>

                            </table> 

                        </div>
                        <div className="DashConfig_financeiroSecondContent">
                            <Chart
                                chartType="Bar"
                                width="100%"
                                height="400px"
                                chartLanguage="pt-BR"
                                data={graficoCustos}
                                options={optionsDespsas}
                                                       
                            />
                            <Tooltip 
                                placement="bottom" 
                                title={'Clique para baixar o relatório'} 
                                arrow={true}
                                className="DashBoardConfig__tooltipPj"
                            >
                                
                            <FileDoneOutlined 
                                className="DashBoardConfig__fileRecord"
                                title="Download relatório"
                                label="Download relatório"
                                onClick={downloadExcelTableCustoXdespesas}
                                
                            />
                                
                        
                            </Tooltip>
                            <table id="table-CustosDespesas" style={{display:'none'}}>                                       
                                    <thead >               
                                        <tr>
                                            {
                                                exportCustos
                                                &&
                                                Object.keys(exportCustos[0]).map((key, index) => {
                                                    return <th className="header-cell" key={`${index}-header-cell`}>{key.toLocaleUpperCase()}</th>;
                                                })
                                            }
                                        </tr>
                                    </thead>                
                                    <tbody>
                                        {
                                            exportCustos 
                                            && 
                                            Object.keys(exportCustos).map((key, index) => {
                                                const obj = exportCustos[key];
                                                return (
                                                  <tr key={`row-${key}`}>
                                                    {Object.entries(obj).map(([propriedade, valor]) => (
                                                      <td key={`cell-${key}-${propriedade}`}>{valor}</td>
                                                    ))}
                                                  </tr>
                                                );
                                              })
                                        }
                                    </tbody>
                            </table>                           
                        </div>

                    </div>
                    
                </div>

        }
        
    ];
      

    
  if(resultAreaMensal.length === 0 || resultAssessores.length === 0 || resultCorretora.length === 0 ){
        return (
            <div>
               
                <div className="DashBoard__empty">
                    <Empty 
                        description={
                            <div className="DashBoard__emptyContent">
                                <span>
                                Você não possui relatório salvo, clique no botão abaixo para criar um novo.
                                
                                </span>
                                {
                                    loading
                                    ?
                                    <Loading 
                                        text={'Aguarde...'}
                                    
                                    />
                                    :
                                    <Button
                                    type="primary"
                                    onClick={()=>navigate('/relatorio',{replace:true})}
                                    style={{fontSize:'15px'}}
                                    >

                                        Criar relatório
                                    </Button>
                                }
                            </div>
                            
                        }
                    
                    />
                    
                

                </div>

            </div>

        );
    };



    return(
        <div className="DashBoard__container"  > 
         
            <div className={loading || Object.keys(dataCorretoraResult).length === 0? 'DashBoard__loading':'--Disable'}>
                <Loading
                    text={'Aguarde, estamos carregando suas informações...'}
                />
            </div>
            <div  className={loading || Object.keys(dataCorretoraResult).length === 0? '--Disable' :"DashBoard__firstContent" }>
                <Divider orientation="left">
                   <p>Configurações do Dashboard</p> 
                </Divider>
                <Collapse>
                    <Panel header={<p>Selecione&nbsp;&nbsp;Mês e Ano</p>} key="1">
                        <label>Mês</label>
                        &nbsp;&nbsp;&nbsp;
                        <Select
                            defaultValue={mesAtual > 9? mesAtual : `0${mesAtual}`}//mes atual
                            style={{
                                width: 120,
                            }}
                            onChange={changeMes}
                            options={optionsSelectMes}
                            value={mesAtual}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <label>Ano</label>
                        &nbsp;&nbsp;&nbsp;
                        <Select
                            defaultValue={anoAtual}//ano atual
                            style={{
                                width: 120,
                            }}
                            onChange={changeAno}
                            options={optionsSelectAno}
                        />
                    </Panel>
                </Collapse>  
            </div>
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} id='secondsectionpdf'/>

        </div>

    );
}