
export default async function suspendAdm(data) {
    let token = localStorage.getItem('token');
    token = JSON.parse(token);
   
    try {
        const response = await fetch(`https://dashboard.atlantainvest.com.br/api/adms/suspend-adm.php`,{
            method:'post',
            body: JSON.stringify ({
                id:data,
                token:token
    
           
                            
            })
        });

        const res = await response.json();
        return res;
        
    } catch (error) {
        return {
            error:true,
            mensage:error
        };
        
        
    };
       

};