
export default async function verifyResult(data) {
    let token = localStorage.getItem('token');
    token = JSON.parse(token);
   
    try {
        const response = await fetch(`https://dashboard.atlantainvest.com.br/api/result/verify-corretora.php`,{
            method:'post',
            body: JSON.stringify ({
                data:data,
                token:token
              
            })
        });

        const res = await response.json();
        return res;
        
    } catch (error) {
        return {
            error:true,
            mensage:error
        };

    };
       
};