export default async function getAllAdms() {
    let token = localStorage.getItem('token');
    token = JSON.parse(token);
    try {
        const response = await fetch('https://dashboard.atlantainvest.com.br/api/adms/getAll-adm.php',{
            method:'post',
            body:JSON.stringify({
                token:token
            })

            
        })
        const res = await response.json()
        return res;
        
    } catch (error) {
        return {
            error:true,
            mensage:error
        }
        
    }
    
}