import jwt_decode from "jwt-decode";

export default function tokenDecode () {
    let token = localStorage.getItem('token');
    if(!token) {
        return {
            error:true,
            mensagem:'Token invalido'
        }
    }

    const decoded = jwt_decode(token);
    return decoded;


}