export default async function UpdateAdm(data) {
    let token = localStorage.getItem('token');
    token = JSON.parse(token);

    try {
        const response = await fetch(`https://dashboard.atlantainvest.com.br/api/adms/update-adm.php`,{
            method:'post',
            body: JSON.stringify ({
                id:data.id,
                nome:data.nome,
                email:data.email,
                token:token
    
                          
            })
        })

        const res = await response.json();
        return res;
        
    } catch (error) {
        return {
            error:true,
            mensage:error
        }
        
        
    }
    
       

}