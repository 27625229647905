import { useEffect, useState } from "react";
import getAllAdms from "../../actions/adms/getAll-adms";
import AdmConfig from "../../components/admConfig/AdmConfig";
import MyLayout from "../../components/myLayout/MyLayout";




export default function AdmAccount () {
    const [data, setData] = useState([]);
    useEffect(()=> {
        async function getAdms() {
            const filterName = [];
            const filterEmail = [];
            const res = await getAllAdms();
            if(Object.values(res).length > 0) {

                const newData =  Object.values(res).map(callback => {
                    if(callback.tipo === 'socio') {
                        callback.tipo = 'Sócio';
                    }else if(callback.tipo === 'associado') {
                        callback.tipo = 'Associado';

                    };

                    filterName.push({
                        text:callback.nome,
                        value:callback.nome,
            
                    })
                    filterEmail.push({
                        text:callback.email,
                        value:callback.email,
            
                    })

                    return {
                        key:callback.id,
                        cdgAcessor:callback.cdgAcessor,
                        nome:callback.nome,
                        email:callback.email,
                        status:[callback.status],
                        tipo:[callback.tipo]


                    };
                })
                setData({
                    columns:newData,
                    filterName:filterName,
                    filterEmail:filterEmail
                });

            }
            
            
        }
        getAdms();

    },[])
    



    return (
        <div>
        <MyLayout 
            component={ 
                <AdmConfig 
                    dataUser={data}
                
                
                /> 
            }
            userLocation={'Administrador / Cadastrar'}
            indexNavSide={['6']}
        
        />
       
    </div>

    );
}