export default async function isValidDate(inputValue) {
    // Remove todos os caracteres não numéricos da entrada do usuário
    let dateParts = inputValue.replace(/\D/g, '');
  
    // Verifica se a entrada tem tamanho 6 e se os valores dos campos "mês" e "ano" são válidos
    if (dateParts.length !== 6 || parseInt(dateParts.substr(0, 2)) > 12 || parseInt(dateParts.substr(2)) < 1000) {
      return false;
    }
  
    // Verifica se o valor do campo "mês" é um número entre 1 e 12
    let month = parseInt(dateParts.substr(0, 2));
    if (month < 1 || month > 12) {
      return false;
    }
  
    // Cria uma nova instância da classe Date com a data digitada pelo usuário
    let date = new Date(parseInt(dateParts.substr(2)), month - 1);
  
    // Verifica se o valor retornado por getMonth() é igual ao valor do campo "mês" - 1
    // e se o valor retornado por getFullYear() é maior que 0
    return date.getMonth() === month - 1 && date.getFullYear() > 0;
};
