import { useEffect, useState } from 'react';
import { 
  Table, 
  Space, 
  Tag, 
  Form, 
  Input, 
  Button, 
  Result,
  notification,
  Modal
} from 'antd';
import { FormOutlined, 
  DeleteOutlined, 
  WarningOutlined, 
  CheckSquareOutlined, 
  KeyOutlined  
} from '@ant-design/icons'
import { Tooltip } from 'antd';
import MyModal from '../myModal/MyModal';
import Loading from '../loading/Loading';
import { MyResult } from '../MyResult/MyResult';
import deleteAdm from '../../actions/adms/delete-adm';
import suspendAdm from '../../actions/adms/suspend-adm';
import reactivateAdm from '../../actions/adms/reactivate-adm';
import UpdateAdm from '../../actions/adms/update-adm';
import { useLocation, useNavigate } from 'react-router-dom';
import changePassword from '../../actions/adms/password-adm';
import getAllAdms from '../../actions/adms/getAll-adms';
import getOneAdm from "../../actions/adms/getOne-adm";
import './tableAdm.css';
import verifyFormEditAdm from '../../lib/verifyForm-adm';




export default function TableAdm ( { data }) {
  const [edit, setEdit] = useState(false);
  const [delet, setDelet] = useState(false);
  const [corretor, setCorretor] = useState({nome:'',email:'',senha1:'',senha2:'',id:''});
  const [password, setPassword] = useState(false);
  const [suspend, setSuspend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(false);
  const [resultState, setResultState] = useState(false);
  const [status, setStatus] = useState(false); // false = suspender //  true = reativar
  const [tableData, setTableData] = useState(data);
  const location = useLocation();
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();

  const openNotificationWithIcon = (type, msg, text) => {
    api[type]({
      message: msg,
      description: text  
    });
  };

  useEffect(()=>{
    setTableData(data);
  },[data])

  async function getAdms () {

    const filterName = [];
    const filterEmail = [];
    const res = await getAllAdms();
    if(Object.values(res).length > 0) {

        const newData =  Object.values(res).map(callback => {
            if(callback.tipo === 'socio') {
              callback.tipo = 'Sócio';
            }else if(callback.tipo === 'associado') {
              callback.tipo = 'Associado';

            };

            filterName.push({
              text:callback.nome,
              value:callback.nome,
    
            })
            filterEmail.push({
              text:callback.email,
              value:callback.email,
    
            })

            return {
              key:callback.id,
              cdgAcessor:callback.cdgAcessor,
              nome:callback.nome,
              email:callback.email,
              status:[callback.status],
              tipo:[callback.tipo]


            };
        })
        setTableData( {
          columns:newData,
          filterName:filterName,
          filterEmail:filterEmail
        });
    }  
  }

  async function getOneADm() {
            
    const res = await getOneAdm();
    if(Object.values(res).length > 0) {
        const filterName = [];
        const filterEmail = [];

        const newData =  Object.values(res).map(callback => {

            filterName.push({
                text:callback.nome,
                value:callback.nome,
    
            })
            filterEmail.push({
                text:callback.email,
                value:callback.email,
    
            })

            return {
              key:callback.id,
              nome:callback.nome,
              email:callback.email,
              status:[callback.status],
            };
        })
        setTableData({
            columns:newData,
            filterName:filterName,
            filterEmail:filterEmail
        });

    }
    
    
}

  function setResultFalse (){
    setSuspend(false);
    setDelet(false)
    setEdit(false)
    setPassword(false)
    setResult(false)
    
  }

  function isChangeStatus (event) {
      const nome = event.nome.toUpperCase();
      if(event.status[0] === 'suspenso'){
        
        setCorretor({
          id:event.key,
          nome:nome,
        });
        setSuspend(true);
        setStatus(true);
        
      }else if(event.status[0] === 'ativo'){
        setCorretor({
          id:event.key,
          nome:nome
        });
        setSuspend(true);
        setStatus(false);
        
      };
    

  };

  async function changeStatus () {
      setLoading(true);

      if(status) {
        const data = await reactivateAdm(corretor.id);
        if(data.error) {
          setResult(true);
          setResultState(false);
          setLoading(false);
          
          return;

        }else if(data.success){
          setResult(true);
          setResultState(true);
          setLoading(false);
          if(location.pathname.split('/')[2] === 'minha_conta'){
            await getOneADm();

          }else {
            await getAdms ();
            
          };
          
          return;
          
        };

      }else if(!status){
        const data = await suspendAdm(corretor.id);
        if(data.error) {
          setResult(true);
          setResultState(false);
          setLoading(false);
          
          return;

        }else if(data.success){
          setResult(true);
          setResultState(true);
          setLoading(false);
          
          if(location.pathname.split('/')[2] === 'minha_conta'){
            await getOneADm();

          }else {
            await getAdms ();
            
          };
          
          
          
          return;
          
        };

      };
      
  };

  async function deleteCurrent () {
      setLoading(true)
      const data = await deleteAdm(corretor.id)
      if(data.error) {
        setResult(true)
        setResultState(false);
        setLoading(false)
        
        return;

      }else if(data.success){
        setResult(true)
        setResultState(true);
        setLoading(false)
        if(location.pathname.split('/')[2] === 'minha_conta'){
          localStorage.clear();
          navigate('/',{replace:true});

        }else {
          await getAdms (); 
        };
        
        return
        
      }

  }

  function isDelete (event) {
      setDelet(true)
      const nome = event.nome.toUpperCase();
      setCorretor({
        id:event.key,
        nome:nome
      });

  }

  async function newPassword () {
    if(corretor.senha1 !== corretor.senha2){
      openNotificationWithIcon('error', 'Atenção!', "As senhas digitadas não coincidem. Por favor, verifique e digite as senhas novamente.")
      return;

    }else if(!corretor.senha1 || !corretor.senha2){
      openNotificationWithIcon('error', 'Atenção!', "Preencha todos os campos e tente novamente!");
      return;
    };

    setLoading(true);
    const res = await changePassword(corretor.senha1, corretor.id);
 
    if(res.error) {
      setResult(true);
      setResultState(false);
      setLoading(false);
      setCorretor({...corretor, senha1:'',senha2:''});
      openNotificationWithIcon('error', 'Atenção!', "Não foi possível atualizar a senha, tente mais tarde!");
      
      return;

    }else if(res.success){
      setResult(true);
      setResultState(true);
      setLoading(false);
      setCorretor({...corretor, senha1:'',senha2:''});

      if(location.pathname.split('/')[2] === 'minha_conta'){
        await getOneADm();
      }else {
        await getAdms ();     
      };
      return;
    };

  };

  async function updateCurrent () {

    const verify = verifyFormEditAdm(corretor)
      if(verify?.error){
        openNotificationWithIcon ('error','Atenção!','Você precisa preencher todos os campos!');
        return
      }

      setLoading(true)
      const data = await UpdateAdm(corretor);
      if(data.error) {
        setResult(true)
        setResultState(false);
        setLoading(false)
        
        return;

      }else if(data.success){
        setResult(true)
        setResultState(true);
        setLoading(false);
        if(location.pathname.split('/')[2] === 'minha_conta'){
          await getOneADm();

        }else {
          await getAdms ();
          
        };

        return;
        
      };

  };

  function isUpdate (event) {
      setCorretor({
        id:event.key,
        nome:event.nome,
        email:event.email,
      });
      setEdit(true);

  };

  const columns = [
    {
      title: 'id',
      dataIndex: 'key',
      width: '5%',
    },
    {
      title: 'Nome',
      dataIndex: 'nome',
      filters: tableData.filterName,
      filterSearch: true,
      onFilter: (value, record) => record.nome.startsWith(value),
      width: '20%',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      filters: tableData.filterEmail,
      filterSearch: true,
      onFilter: (value, record) => record.email.startsWith(value),
      width: '20%',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      filters: [
        {
          text: 'ativo',
          value: 'ativo',
        },
        {
          text: 'suspenso',
          value: 'suspenso',
        },
        
      ],
      render: (_, { status }) => (
        <>
          {status.map((status) => {
            let color;
            if (status === 'suspenso') {
              color = 'red';
            }
            if (status === 'ativo') {
              color = 'green';
            }
            return (
              <Tag color={color} key={status}>
                {status && status.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
      onFilter: (value, record) => record.status.includes(value),
      width: '12%'
      
    },
    {
      title: 'Ações',
      key: 'action',
      render: (_, record) => (
        <>
          {record.status.map((status) => {
            let buttonName;
            if (status === 'suspenso') {
              
              buttonName = <Tooltip placement="bottom" title={"Ativar Admin"}>
                            <CheckSquareOutlined 
                              className='TableAdm__iconsAtivar'
                            
                            />
                          </Tooltip>
            }
            if (status === 'ativo') {
              buttonName = <Tooltip placement="bottom" title={"Suspender Admin"}>
                            <WarningOutlined 
                              className='TableAdm__iconsSuspend' 
                            
                            
                            />
                          </Tooltip>
            }
            return (
              <Space size="middle" key={record.key}>
          <p 
            onClick={()=>isUpdate(record)} 
            className='TableUsers__actions'
          >
            <Tooltip placement="bottom" title={"Editar Admin"}>
              <FormOutlined
                className='TableAdm__iconsEdit' 
              
              />
            </Tooltip>
            
          </p>
          {

            location.pathname.split('/')[2] === 'minha_conta'
            &&
            <p 
            onClick={()=>{
              setPassword(true)
              setCorretor({...corretor,id:record.key})
            }} 
            className='TableUsers__actions'
          >
            <Tooltip placement="bottom" title={"Mudar Senha"}>
              <KeyOutlined 
                className='TableAdm__iconsPassword'
              
              />
            </Tooltip>
          </p>

          }
          <p 
            onClick={()=>isChangeStatus(record)}
            className='TableUsers__actions'
          >
            {buttonName}
          </p>
          <p 
            onClick={()=>isDelete(record)} 
            className='TableUsers__actions'
          >
            <Tooltip placement="bottom" title={"Deletar Admin"}>
              <DeleteOutlined 
                className='TableAdm__iconsDelete'
              
              />
            </Tooltip>
          </p>
        </Space>
            );
          })}
        </>
      ),
      width: '20%'

    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };


  return (
        <>
          {contextHolder}
            <Table 
              columns={columns} 
              dataSource={tableData.columns} 
              onChange={onChange} 
              pagination='bottomLeft'
                    
            />
            
            <Modal 
                title="Editar Administrador" 
                open={edit} 
                closable={false}
                maskClosable={false}
                className='TableAdm__editAdm'
              >
              <div>
                      <div className={result ? '--Disable':'TableUser_editContainer'}>
                        <form className='TableAdmin__editForm'>
                          <label 
                            htmlFor=""
                            className='TableAdm__label'

                          
                          >
                            <p>Nome</p>
                            <input 
                              placeholder={'Digite o nome'}
                              className='TableUser__input'
                              onChange={event=>setCorretor({...corretor, nome:event.target.value})}
                              value={corretor.nome}

                            />

                          </label>
                          <label 
                            htmlFor=""
                            className='TableAdm__label '
                          >
                            <p>E-mail</p>
                            <input 
                              placeholder="📧 E-mail"
                              className='TableUser__input'
                              onChange={event=>setCorretor({...corretor, email:event.target.value})}
                              value={corretor.email}
                                            
                            /> 

                          </label>
            
                          
                        </form>
                        <div className={result ? '--Disable':'TableUsers__btnModal'} style={{margin:'50px 0px 30px 0px', padding:'20px 0px'}}>                      
                          <Button 
                            type="primary" 
                            key="não"
                            onClick={()=>setEdit(false)}
                            ghost
                          >
                            Não
                          </Button>
                          <Button 
                            type="primary" 
                            key="sim"                    
                            htmlType='submit'
                            onClick={updateCurrent}
                          >
                            Editar
                          </Button>
                        </div>
                  
                        <div className='TableUsers__modalSpin'>
                          {
                            suspend === true 
                            && 
                            loading ===true  
                            && 
                            <Loading 
                              text={'Carregando...'}
                              
                            />
                          }

                        </div>  
                      </div>
                      <div className={result ? 'TableUsers__modalContent':'--Disable'}>
                        <MyResult 
                          resultState={resultState}                                          
                          titleSuccess={'Cadastro atualizado com sucesso!'}
                          textSuccess={'Clique em "Voltar" para retornar ao menu.'}
                          titleError={'Erro ao atualizar o cadastro!'}
                          textError={'Não foi possível atualizar o cadastro, tente mais tarde!'}
                          click={setResultFalse}
                        
                        />

                      </div>                 
                    
              </div>
                
            </Modal>
      
            < MyModal 
                modalState={delet}
                setModalState={setDelet}
                title={'Excluir Administrador'}
                content={
                  <div className='TableUsers__deleteContainer'>
                    <div className={result ? '--Disable':'TableUsers__modalContent'}>
                      <Result
                        status="error"
                        title={
                          <h2 style={{fontSize:'1.4rem'}}>
                            Você está <span className='TableUsers__spanName'>excluindo</span>  o <br/>Administrador <span className='TableUsers__spanName'>{corretor.nome}</span>
                          </h2>
                        }
                          
                      />
                      <div className={loading ? '--Disable' : 'TableUsers__btnModal'}
                        style={{margin:'30px 0px'}}
                      
                      >
                      <Button 
                            type="primary" 
                            onClick={()=>setDelet(false)}
                            ghost
                          >
                            Não
                        </Button>
                        <Button 
                            type="primary"  
                            
                            onClick={deleteCurrent}
                          >
                            Excluir
                        </Button>
                         
 
                      </div>
                      <div className={loading ? 'TableUsers__modalSpin' : '--Disable'}>
                        {
                          delet === true 
                          && 
                          loading ===true  
                          && 
                          <Loading 
                            text={'Carregando...'}
                            
                          />
                        }

                      </div>

                    </div>
                    <div className={result ? 'TableUsers__modalContent':'--Disable'}>
                      <MyResult 
                        resultState={resultState}                                          
                        titleSuccess={'Administrador deletado com sucesso!'}
                        textSuccess={'Clique em "Voltar" para retornar ao menu.'}
                        titleError={'Erro ao deletar o Administrador!'}
                        textError={'Não foi possível deletar esse Administrador , tente mais tarde!'}
                        click={setResultFalse}


                      />

                    </div>

                  </div>

              }
            
            />
            <Modal 
              title="Atualizar senha" 
              open={password} 
              closable={false}
              maskClosable={false}
              className='TableAdm__editAdm'
            >
              <div>
                    <div className={result ? '--Disable':''}>
                      <form className='TableAdm__form'>
                        <label htmlFor="" className='TableAdm__label'>
                          <p>Nova senha</p>
                          <input
                            placeholder="🔑 Senha"
                            className='TableUser__input'
                            onChange={event=>setCorretor({...corretor, senha1:event.target.value})}
                            value={corretor.senha1}
                          />

                        </label>
                        <label htmlFor="" className='TableAdm__label'>
                          <p>Repita sua nova senha</p>
                          <input
                            placeholder="🔑 Repita sua Senha"
                            className='TableUser__input'
                            onChange={event=>setCorretor({...corretor, senha2:event.target.value})}  
                            value={corretor.senha2}                   
                          />

                        </label>

                          
                      </form>
                      <div className={result ? '--Disable':'TableUsers__btnModal'}>
                       <Button 
                          type="primary" 
                          key="não"
                          onClick={()=>setPassword(false)}
                          ghost
                        >
                          Não
                        </Button>
                        <Button 
                          type="primary" 
                          key="sim"                          
                          onClick={newPassword}
                        >
                          Atualizar
                        </Button>
                       
                      </div>
                    
                      <div className='TableUsers__modalSpin'>
                        {
                          suspend === true 
                          && 
                          loading ===true  
                          && 
                          <Loading 
                            text={'Carregando...'}
                            
                          />
                        }

                      </div>  
                    </div>
                    <div className={result ? 'TableUsers__modalContent':'--Disable'}>
                      <MyResult 
                        resultState={resultState}                                          
                        titleSuccess={'Senha atualizada com sucesso!'}
                        textSuccess={'Clique em "Voltar" para retornar ao menu.'}
                        titleError={'Erro ao atualizar sua senha!'}
                        textError={'Não foi possível atualizar sua senha, tente mais tarde!'}
                        click={setResultFalse}
                      
                      />

                    </div>                 

              </div>
              
            </Modal>
            < MyModal
              modalState={suspend}
                setModalState={setSuspend}
                title={status ? 'Reativar Administrador' : 'Suspender Administrador'}
                content={
                  <div className='TableUsers__suspendContaine'>
                    <div className={result ? '--Disable':'TableUsers__suspendContent'}>
                      <Result
                        status="warning"
                        title={''}
                            
                      />
                      <h2>
                        Você está <span 
                          className='TableUsers__spanName'
                        >
                          {status ? 'reativando' : 'suspendendo'}
                        </span> o Administrador&nbsp; 
                        <span 
                          className='TableUsers__spanName'
                        >
                          { corretor.nome}
                        </span> ? 
                        
                      </h2> 
                      <div className={loading ? '--Disable' : 'TableUsers__btnModalSuspend'}>
                        <Button 
                            type="primary"                            
                            onClick={()=>setSuspend(false)}
                            ghost
                          >
                            Não
                        </Button>
                        <Button 
                            type="primary"                            
                            
                            onClick={changeStatus}
                          >
                            {status ? 'Reativar' : 'Suspender'}
                          </Button>
                          
 
                      </div>
                      <div className={loading && suspend ? 'TableUsers__modalSpin' : '--Disable'}>
                        {
                          suspend === true 
                          && 
                          loading ===true  
                          && 
                          <Loading 
                            text={'Carregando...'}
                            
                          />
                        }

                      </div>                   
                    </div>   
                    <div className={result ? 'TableUsers__modalContent':'--Disable'}>
                      <MyResult 
                        resultState={resultState}                                          
                        titleSuccess={status ? `Administrador reativado com sucesso!` : `Administrador suspenso com sucesso!`}
                        textSuccess={'Clique em "Voltar" para retornar ao menu.'}
                        titleError={status? 'Erro ao reativar o Administrador!' : 'Erro ao suspender o Administrador!'}
                        textError={status? 'Não foi possível reativar esse Administrador, tente mais tarde!' : 'Não foi possível suspender esse Administrador, tente mais tarde!'}
                        click={setResultFalse}
                      />

                    </div>                 
                   
                        

                  </div>

            }
            
            />
        </>
        
    );
}




