import MyLayout from '../../components/myLayout/MyLayout';
import AssessorReportConfig from './AssessorReportConfig/AssessorReporConfig';

import './assessorReport.css'



export default function AssessorReport () {
    return (
        <>
            <MyLayout
                userLocation={'Assessores / Relatório'}
                indexNavSide={['4']}
                component={
                    <AssessorReportConfig />
                }
            />

        </>

    );
}