export default async function sendRelatorioAssessor(data) {
    let token = localStorage.getItem('token');
    token = JSON.parse(token);

        // Cria um objeto FormData
        const formData = new FormData();
        formData.append("codigo_assessor", data.codigo_assessor);
        formData.append("nome", data.nome); // Adicione o campo "nome" ao FormData
        formData.append("email", data.email); // Adicione o campo "email" ao FormData
        formData.append("planilha", data.planilha); // Adicione o arquivo da planilha ao FormData
        formData.append("data", data.data); // Adicione o arquivo da planilha ao FormData
        formData.append("token", token);
  
    try {
        const response = await fetch(`https://dashboard.atlantainvest.com.br/api/sendEmail/email/email.php`,{
            method:'post',
            body: formData,
        });
  
      const res = await response.json();
      console.log(res)
    
        return res;
        
        
        
    } catch (error) {
        return {
            error:true,
            mensage:error
        };
        
        
    };
       
    
  };