export default async function CreateAdm(data) {
    let token = localStorage.getItem('token');
    token = JSON.parse(token);
    try {
        const response = await fetch(`https://dashboard.atlantainvest.com.br/api/adms/create-adm.php`,{
            method:'post',
            body: JSON.stringify ({
                nome:data.nome,
                email:data.email,
                senha:data.password,
                status:data.status,
                token:token

            })
        });
        

        const res = await response.json();
        return res;
        
    } catch (error) {
        return {
            error:true,
            mensage:error
        };
        
        
    };
    
       

};