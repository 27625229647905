// De 0h a 11:59 - Bom dia
// De 12:00 a 18:00 - Boa tarde
// De 18:00 a 23:59 - Boa Noite

import { isWithinInterval } from 'date-fns';

function isMorning(datetime) {
  const start = new Date();
  const end = new Date();

  start.setHours(0);
  start.setMinutes(0);

  end.setHours(11);
  end.setMinutes(59);

  return isWithinInterval(datetime, {
    start,
    end,
  });
}

function isAfternoon(datetime) {
  const start = new Date();
  const end = new Date();

  start.setHours(12);
  start.setMinutes(0);

  end.setHours(17);
  end.setMinutes(59);
  return isWithinInterval(datetime, {
    start,
    end,
  });
}

export function getGreetings(name) {
  const datetime = new Date();
  
  if (isMorning(datetime)) {
    return `☀️ Bom dia, ${name} !`;
  };

  if (isAfternoon(datetime)) {
    return `⛅ Boa tarde, ${name}! `;
  };

  return `🌙 Boa noite, ${name}!`;
}