export default function converteCustos (data) {

    const custosDespesas = [];
    const header = ['Data', 'Custos','Despesas']
    
    custosDespesas[0] = header;
    
    const valuesData = Object.values(data)
    for (let i = 0; i < data.length; i++) {

        custosDespesas.push([
            valuesData[i].data,
            parseFloat(valuesData[i].custos.replace('R$','').replace('.','').replace(',',',')),
            parseFloat(valuesData[i].despesas.replace('R$','').replace('.','').replace(',',','))
        ]);
        
        
    }

    return custosDespesas;

}