
export default async function getResultCorretora() {
    let token = localStorage.getItem('token');
    token = JSON.parse(token);
   
    
    
    try {
        const response = await fetch(`https://dashboard.atlantainvest.com.br/api/result/getAll-corretora.php`,{
            method:'post',
            body: JSON.stringify ({
            
                token:token
           
            })
        });

        const res = await response.json();
        return res;
        
    } catch (error) {
        return {
            error:true,
            mensage:error
        };
    };
};