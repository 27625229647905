import { useEffect, useState } from 'react';
import { 
    Button, 
    notification,
    Input,
    Tooltip
} from 'antd';
import 'antd/dist/reset.css';
import { PlusCircleFilled } from '@ant-design/icons';
import MyModal from '../myModal/MyModal';
import verifyForm from '../../lib/verifyForm';
import Loading from '../loading/Loading';
import { MyResult } from '../MyResult/MyResult';
import TableAdm from '../tableAdm/TableAdm';
import CreateAdm from '../../actions/adms/create-adm';
import verifyEmail from '../../actions/adms/verifyEmail-adm';
import getAllAdms from '../../actions/adms/getAll-adms';
import './admConfig.css';
import { useLocation } from 'react-router-dom';


export default function AdmConfig({ dataUser,clickDelet, clickSuspend }) {
    const [modal, setModal] = useState(false);
    const [result, setResult] = useState(false);
    const [loading, setLoading] = useState(false);
    const [resultState, setResultState] = useState(false);
    const [nome, setNome] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [api, contextHolder] = notification.useNotification();
    const [bntRegister, setBntRegister] = useState(true);
    const [tableData, setTableData] = useState(dataUser);
    const location = useLocation();

    useEffect(()=> {
        
        if(location.pathname.split('/')[2] === 'minha_conta'){
            setBntRegister(false)

        };

    },[location.pathname])

    useEffect(()=>{
        setTableData(dataUser);
        
    
    },[dataUser])
    

    const form = {
        nome:nome,
        email:email,
        status:'ativo',
        password:password

    }

    const openNotificationWithIcon = (type, msg, text) => {
      api[type]({
        message: msg,
        description: text
         
      });
    };

    async function getAdms () {

        const filterName = [];
        const filterEmail = [];
        const res = await getAllAdms();
        if(Object.values(res).length > 0) {
    
            const newData =  Object.values(res).map(callback => {
                if(callback.tipo === 'socio') {
                  callback.tipo = 'Sócio';
                }else if(callback.tipo === 'associado') {
                  callback.tipo = 'Associado';
    
                };
    
                filterName.push({
                  text:callback.nome,
                  value:callback.nome,
        
                })
                filterEmail.push({
                  text:callback.email,
                  value:callback.email,
        
                })
    
                return {
                  key:callback.id,
                  cdgAcessor:callback.cdgAcessor,
                  nome:callback.nome,
                  email:callback.email,
                  status:[callback.status],
                  tipo:[callback.tipo]
    
    
                };
            })
            setTableData( {
              columns:newData,
              filterName:filterName,
              filterEmail:filterEmail
            });
         
        }
  
    }

    async function registerAdm () {

        const contadorError = verifyForm(form);
        if(contadorError > 0){
            openNotificationWithIcon('error','Atenção!','Você precisa preencher todos os campos para prosseguir!');
            return;
        };

        const emailVerify = await verifyEmail(form.email)
        if(emailVerify.error){
            openNotificationWithIcon('error','Atenção!',`${emailVerify.mensage}`);       
            return; 
        };

        setLoading(true);
        const res = await CreateAdm(form);

        if(res?.error){
            setLoading(false);
            openNotificationWithIcon('error','Atenção!', 'Não foi possível realizar o cadastro, tente mais tarde!');
            setResult(true);
            setResultState(false);
            setNome('');
            setEmail('');
            setPassword('');
            return;
        };
        setResult(true);
        setResultState(true)
        setLoading(false);
        await getAdms(); 
        setNome('');
        setEmail('');
        setPassword('');
    };

    function setModalFalse(){
        setResult(false);
        setModal(false);
        setNome('');
        setEmail('')
        setPassword('')
    };
   
    return(
        
        <div className='UserConfig__container'>
            {contextHolder}
            <div className='UserConfig__firstContent'>
                <div className='UserConfig__BrokerTable'>
                <div className={ bntRegister ? 'UserConfig_BntContent' : '--Disable'}>
                        <Tooltip 
                            title='Cadastrar Administrador'
                            placement="bottom"
                        
                        >
                            <PlusCircleFilled
                                onClick={()=> setModal(true)}
                                className='UserConfig__bntRegister'     
                
                            />  
                        </Tooltip >

                    </div>
                    <TableAdm
                        data={tableData}
                                       
                    />
                    <MyModal 
                        modalState={modal}
                        setModalState={setModal}
                        title={"Cadastrar Administrador"}
                        content={
                            <>
                                <div className={loading ||  result ? '--Disable' :''}>
                                    <form className='AdmConfig__form'>
                                        <label htmlFor="" className='AdmConfig__label'>
                                            <p>Nome</p>
                                            <Input 
                                                onChange={event=>setNome(event.target.value)}
                                                value={nome}
                                                className='TableUser__input'
                                                placeholder='Digite o nome'
                                                    
                                            />  
                                                                                                                                                                     
                                        </label> 
                                        <label htmlFor="" className='AdmConfig__label'>
                                            <p>E-mail</p>
                                            <Input 
                                                placeholder="📧 E-mail"
                                                onChange={event=>setEmail(event.target.value)}
                                                value={email}
                                                className='TableUser__input'
            
                                            />  

                                        </label>
                                        <label htmlFor="" className='AdmConfig__label'>
                                            <p>Senha</p>
                                            <input
                                                placeholder="🔑 Senha"
                                                onChange={event=>setPassword(event.target.value)}
                                                className='TableUser__input'
                                                value={password}
                                                        
                                            />

                                        </label>
                                       
                                    </form>
                                    <div className='TableUsers__btnModal'>                             
                                        <Button 
                                            type="primary" 
                                            key="sim"
                                            ghost
                                            onClick={()=> setModal(false)}
                                        >
                                            Não
                                        </Button>
                                        <Button 
                                            type="primary" 
                                            key="nao" 
                                            htmlType="submit"
                                            onClick={registerAdm}
                                                
                                        >
                                            Cadastrar
                                        </Button>
                                    </div>
                                </div>
                                <div className={!loading ? '--Disable' :''}>
                                    <Loading 
                                        text={'Aguarde...'}
                                    
                                    />

                                </div>
                                <div className={!result ? '--Disable' :''}>
                                    <MyResult 
                                    resultState={resultState}                                          
                                    titleSuccess={'Administrador cadastrado com sucesso!'}
                                    textSuccess={'Clique em "Voltar" para retornar ao menu.'}
                                    titleError={'Erro ao realizar o cadastro!'}
                                    textError={'Não foi possível cadastrar o Administrador, tente mais tarde!'}
                                    click={setModalFalse}

                                />
                                </div>                          
                                
                            </>                           
                        }                  
                    />                                      
                </div>
            </div>
        </div>
    );
}